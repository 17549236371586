import { useQuery } from '@apollo/client';
import {
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { useMemo } from 'react';
import { useAppContext } from '~/contexts';
import { DataSourceSelectListDocument } from './DataSourceSelect.generated';

export interface DataSourceSelectProps {
  'aria-label': string;
  disabled: boolean;
  name: string;
  onChange: (event: SelectChangeEvent) => void;
  error: boolean;
  helperText: string;
  value: string;
}

export const DataSourceSelect = ({
  error,
  helperText,
  value,
  ...inputProps
}: DataSourceSelectProps) => {
  const { currentNetwork } = useAppContext();

  const { data: sourcesQuery } = useQuery(DataSourceSelectListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const dataSources = useMemo(() => sourcesQuery?.network?.dataSources.nodes ?? [], [sourcesQuery]);

  return (
    <FormControl fullWidth>
      <Select {...inputProps} value={String(value)} fullWidth>
        {dataSources.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
