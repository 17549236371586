import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdateSoftwareDialogVariables = Types.Exact<{
  deviceIds: ReadonlyArray<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  networkId: Types.Scalars['Int']['input'];
}>;


export type UpdateSoftwareDialog = { readonly __typename: 'Query', readonly network?: { readonly __typename: 'Network', readonly id: number, readonly softwareUpdateInfo: { readonly __typename: 'SoftwareUpdateInfo', readonly currentVersion?: string | null, readonly reason?: string | null, readonly versions: ReadonlyArray<{ readonly __typename: 'SoftwareVersion', readonly approved: boolean, readonly current: boolean, readonly eoledAt?: string | null, readonly id: number, readonly version: string }> } } | null };


export const UpdateSoftwareDialogDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UpdateSoftwareDialog"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"network"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"networkId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"softwareUpdateInfo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"deviceIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentVersion"}},{"kind":"Field","name":{"kind":"Name","value":"reason"}},{"kind":"Field","name":{"kind":"Name","value":"versions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"approved"}},{"kind":"Field","name":{"kind":"Name","value":"current"}},{"kind":"Field","name":{"kind":"Name","value":"eoledAt"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"version"}}]}}]}}]}}]}}]} as unknown as DocumentNode<UpdateSoftwareDialog, UpdateSoftwareDialogVariables>;