import {
  Apps,
  AutoFixHigh,
  Folder as FolderIcon,
  Image,
  Layers,
  Movie,
  PictureAsPdf,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  type CardMediaProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { forwardRef, useMemo, useState, type ReactNode } from 'react';
import { Link, type LinkProps } from '~/components/link';
import { TagList } from '~/components/tag';
import { formatInterval } from '~/lib/datetime';
import { mediaDimensions, mediaExtension, mediaSize, videoDuration } from '~/lib/media';
import { pluralize } from '~/lib/string';
import { formatBytes } from '~/lib/units';
import { CardActionsTitleText } from '../cards';
import { OverlaySettingsDialog } from '../overlays/settings-dialog';
import {
  type ContentCard__ContentFolder,
  type ContentCard__ContentItem,
  type ContentCard__MediaItem,
  type ContentThumbnail__MediaItem,
} from './card.generated';

type OptionalTags = { tags?: ReadonlyArray<string> };
type PartialContentItem = Omit<ContentCard__ContentItem, 'tags'> & OptionalTags;
type PartialMediaItem = Omit<ContentCard__MediaItem, 'tags'> & OptionalTags;
export type PartialContent = PartialContentItem | PartialMediaItem;
type ContentOrFolder = PartialContent | ContentCard__ContentFolder;

export const mediaUpdatedAt = (updatedAt: string) => {
  return new Date(updatedAt).toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};

export const ContentIcon = ({ item }: { item: ContentOrFolder }) => {
  const Icon =
    item.__typename === 'ContentFolder'
      ? FolderIcon
      : item.__typename === 'ContentItem'
      ? item.design?.id
        ? AutoFixHigh
        : Apps
      : item.kind === 'IMAGE'
      ? Image
      : item.kind === 'PDF'
      ? PictureAsPdf
      : Movie;

  return <Icon sx={{ color: 'primary.dark' }} />;
};

const FolderMeta = ({ item }: { item: ContentCard__ContentFolder }) => {
  const count = useMemo(() => item.contentCount + item.childrenCount, [item]);
  return (
    <>
      <ContentIcon item={item} />
      FOLDER
      <div>•</div>
      {count}&nbsp;{pluralize('item', count)}
    </>
  );
};

const ContentMeta = ({ item }: { item: PartialContent }) => {
  const theme = useTheme();
  const [overlayDialogOpen, setOverlayDialogOpen] = useState(false);
  return (
    <>
      <ContentIcon item={item} />
      {item.__typename === 'ContentItem' && (item.design?.id ? 'DESIGN' : 'APP')}
      {item.__typename === 'MediaItem' && mediaExtension(item)}
      {item.overlay && (
        <>
          <div>•</div>
          <Tooltip title="Update Overlay settings" arrow>
            <span>
              <IconButton
                style={{ padding: 0, color: theme.palette.primary.dark }}
                onClick={() => {
                  setOverlayDialogOpen(true);
                }}
              >
                <Layers />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={`Overlay ID: ${item.overlay.id}`} arrow>
            <span>OVERLAY</span>
          </Tooltip>
        </>
      )}
      <OverlaySettingsDialog
        close={() => setOverlayDialogOpen(false)}
        item={item}
        open={overlayDialogOpen}
      />
    </>
  );
};

interface ContentCardMetaProps {
  item: ContentOrFolder;
}

export const ContentCardMeta = ({ item }: ContentCardMetaProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        color: 'text.secondary',
        fontSize: 13,
      }}
    >
      {item.__typename === 'ContentFolder' ? (
        <FolderMeta item={item} />
      ) : (
        <ContentMeta item={item} />
      )}
    </Box>
  );
};

// Media Card Actions Component
interface ContentCardActionsProps {
  item: ContentOrFolder;
  children?: ReactNode;
  small?: boolean;
}

export const ContentCardActions = ({ children, item, small = false }: ContentCardActionsProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 1.5,
        pb: 1,
        pr: 0,
        borderTop: '1px solid #e6e6e6',
      }}
    >
      <CardActionsTitleText title={item.name} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: 'text.secondary',
            fontSize: 13,
            ...(small && {
              '& svg': {
                fontSize: 13,
              },
              '& > div': {
                fontSize: 10,
              },
            }),
          }}
        >
          <ContentCardMeta item={item} />
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export const ContentThumbnailContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    paddingBottom: 'calc(100% / 16 * 9)',
  },
  '& > :first-of-type': {
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

export const FolderThumbnail = () => {
  return (
    <ContentThumbnailContainer>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <FolderIcon sx={{ height: '100%', width: '50%', color: 'primary.main' }} />
      </Box>
    </ContentThumbnailContainer>
  );
};
export interface CardThumbnailProps extends CardMediaProps<'img'> {
  readonly item: PartialContentItem | ContentThumbnail__MediaItem;
}

export const ContentCardThumbnail = forwardRef<HTMLImageElement, CardThumbnailProps>(
  function CardThumbnail({ children, className, item, ...props }, ref) {
    return (
      <ContentThumbnailContainer className={className}>
        {item.__typename === 'ContentItem' && !item.thumbnailUri ? (
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                backgroundColor: '#003f72',
              }}
            >
              <Apps sx={{ height: '100%', width: '35%', color: 'common.white' }} />
            </Box>
          </Box>
        ) : (
          <CardMedia
            alt={item.name}
            image={item.thumbnailUri ?? undefined}
            crossOrigin="anonymous"
            {...props}
            component="img"
            ref={ref}
          />
        )}
        {children}
      </ContentThumbnailContainer>
    );
  },
);

export const useMediaCardStyles = makeStyles((theme) => ({
  overlay: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: theme.spacing(0, 1, 0.5),
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 600,
    width: '100%',
    height: '100%',
    alignItems: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, .3) !important',
    opacity: (value: boolean) => (value ? 1 : 0),
    transition: 'opacity 0.3s',
    '&:hover': {
      opacity: 1,
    },
  },
}));

export const GridCard = styled(Card)(() => ({
  height: '100%',
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px 0px',
  },
}));
export interface ContentCardProps {
  LinkProps?: LinkProps;
  checked?: boolean | null;
  children: ReactNode;
  item: PartialContent;
}

export const ContentCard = ({ LinkProps, checked, children, item }: ContentCardProps) => {
  const classes = useMediaCardStyles(checked ?? false);

  const { dimensions, duration, extension, size } = useMemo(() => {
    if (item.__typename === 'ContentItem') return {};
    return {
      dimensions: mediaDimensions(item).join('x'),
      duration: item.kind === 'VIDEO' ? formatInterval(videoDuration(item)) : '',
      extension: mediaExtension(item),
      size: formatBytes(mediaSize(item)),
    };
  }, [item]);

  const thumbnail = (
    <ContentCardThumbnail item={item}>
      <Box
        className={classes.overlay}
        id="overlay-section"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ paddingY: 0.5 }}>
          <TagList
            abbreviate
            color="info"
            flexDirection="column"
            item={item}
            tags={item.tags || []}
          />
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {duration}
          <br />
          {dimensions}
          <br />
          {extension}
          <br />
          {size}
        </Box>
      </Box>
    </ContentCardThumbnail>
  );

  return (
    <GridCard>
      {LinkProps != null ? <Link {...LinkProps}>{thumbnail}</Link> : thumbnail}
      <ContentCardActions item={item}>{children}</ContentCardActions>
    </GridCard>
  );
};
