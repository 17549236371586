import { useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DataGridPagination } from '~/components/data-grid/pagination';
import { EmptyState } from '~/components/empty-state';
import { usePaginationParams } from '~/hooks/pagination';
import AuditLogsIllustration from '~/images/illustrations/audit-logs.svg';
import { DetailsDialog } from '../../components/details';
import type { NetworkAuditLogs__AuditLog as AuditLog } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState illustration={AuditLogsIllustration} description="No Audit Logs exist." />
);
export interface TableProps {
  auditLogs: readonly AuditLog[];
  loading: boolean;
  totalCount: number;
}

export const Table = ({ auditLogs, loading, totalCount }: TableProps) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<AuditLog | null>(null);

  const handleOpenDialog = (row: AuditLog) => {
    setSelectedRow(row);
    setDetailsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDetailsDialogOpen(false);
    setSelectedRow(null);
  };

  const columns = useColumns(handleOpenDialog);
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));

  const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      createdAt: !isSmallAndDown,
      recordType: !isMediumAndDown,
      actions: !isSmallAndDown,
    }));
  }, [isMediumAndDown, isSmallAndDown]);

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  return (
    <>
      <SeparatedDataGrid
        checkboxSelection={false}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableRowSelectionOnClick
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={(params: GridRowParams<AuditLog>) => handleOpenDialog(params.row)}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalCount}
        rows={auditLogs}
        slots={{
          columnResizeIcon: () => null,
          pagination: DataGridPagination,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
      <DetailsDialog open={detailsDialogOpen} onClose={handleCloseDialog} row={selectedRow} />
    </>
  );
};
