import {
  AccountCircle,
  AddCircle,
  AppRegistration,
  Assessment,
  AutoFixHigh,
  CalendarMonth,
  Close,
  CloudSync,
  CropOriginal,
  Dashboard,
  DeviceHub,
  Dvr,
  Event,
  ExpandLess,
  ExpandMore,
  Group,
  HelpOutline,
  Hub,
  KeyboardArrowDown,
  MovieCreation,
  OndemandVideo,
  ReceiptLong,
  Router,
  Schedule,
  Settings,
  Stadium,
  SupervisorAccount,
  VerifiedUser,
  ViewComfy,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Hidden,
  IconButton,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserAvatar } from '~/components/avatar';
import { Drawer } from '~/components/drawer/Drawer';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import logoOnly from '~/images/fc-logo-alt-only-logo.svg';
import { assert } from '~/lib/assert';
import { supportEmail } from '~/lib/constants';
import { NavDivider, NavItemButton, NavItemLink, NavSubItemLink } from './nav-items';
import { QuickActionsPopover } from './quick-actions';
import { UserActionsPopover } from './user-actions';

const drawerWidth = 240;
const slimDrawer = 60;
const mobileDrawer = 180;

//Return sidebar width according to screensize.
const calculateDrawerWidth = ({
  mobileMatches,
  matches,
}: {
  mobileMatches: boolean;
  matches: boolean;
}) => (mobileMatches ? mobileDrawer : matches ? slimDrawer : drawerWidth);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  sidebarParent: {
    height: '100%',
    overflow: 'hidden',
  },
  sidebarTop: {
    width: '100%',
  },
  sidebarTopMenu: {
    overflow: 'hidden auto',
    height: 'calc(100vh - 250px)',
  },
  sidebarBottom: {
    width: '100%',
    position: 'absolute',
    bottom: '0',
  },
  toolbar: theme.mixins.toolbar,
  button: {
    color: '#f8f8f8',
    padding: theme.spacing(1.5, 3),
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    fontWeight: 600,
    fontSize: '16px',
    borderRadius: '0px',
    '&:hover': {
      backgroundColor: 'transparent !important',
      color: '#ffffff !important',
    },
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  list: {
    height: '100%',
    padding: 0,
  },
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    '& > *': {
      marginTop: '8px',
      padding: '0 16px',
    },
  },
  subTitle: {
    color: '#ffffff',
    textTransform: 'uppercase',
    marginTop: '-3px',
    [theme.breakpoints.between('sm', 'lg')]: {
      visibility: 'hidden',
    },
  },
  drawer: {
    width: ({ mobileMatches, matches }: { mobileMatches: boolean; matches: boolean }) =>
      calculateDrawerWidth({ mobileMatches, matches }),
  },
  drawerPaper: {
    backgroundColor: '#2B8ACB',
    borderRight: '0px',
    overflow: ({ matches }: { matches: boolean }) => matches && 'hidden',
    width: ({ mobileMatches, matches }: { mobileMatches: boolean; matches: boolean }) =>
      calculateDrawerWidth({ mobileMatches, matches }),
  },
  userName: {
    visibility: ({ matches }: { matches: boolean }) => matches && 'hidden',
  },
  divider: {
    backgroundColor: '#0083DB',
  },
  userInfoButton: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      paddingLeft: theme.spacing(2.2),
      paddingBottom: theme.spacing(1.2),
      paddingTop: theme.spacing(3),
    },

    overflow: 'hidden',
  },
  userInfoIcon: {
    width: '100%',
  },
  userInfo: {
    width: '100%',
    paddingTop: theme.spacing(1),
  },
  userInfoDetail: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    '& > h4': {
      color: '#ffffff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '125px',
      maxWidth: '125px',
    },
    '& > h5': {
      color: '#ffffff',
      fontSize: '12px',
      maxWidth: 'calc(100% - 55px)',
    },
  },
  menu: {
    padding: theme.spacing(0),
  },
  menuHeading: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 600,
    color: '#000000',
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
    color: '#ffffff',
  },
  slimIcon: {
    transform: 'scale(0.5)',
    position: 'absolute',
    top: '-29px',
    left: '-67px',
    padding: 0,
  },
}));

export interface SidebarProps {
  logo: string;
  open: boolean;
  toggleSidebar: () => void;
}

export const Sidebar = ({ logo, open, toggleSidebar }: SidebarProps) => {
  // hooks

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const mobileMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ mobileMatches, matches });

  const { currentNetwork, currentUser, impersonating } = useAppContext();
  const link = useLink();

  const location = useLocation();

  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const userMenuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const [quickMenuOpen, setQuickMenuOpen] = useState(false);
  const quickMenuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [schedulesOpen, setSchedulesOpen] = useState(false);

  // end hooks

  const handleClickAway = () => {
    // Handle toggle sidebar based on screensize sm and smaller than sm.
    mobileMatches && toggleSidebar();
  };

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleSchedulesClick = () => {
    setSchedulesOpen(!schedulesOpen);
  };

  const handleUserMenuToggle = () => {
    setUserMenuOpen((prevOpen) => !prevOpen);
  };

  const handleUserMenuClose = ({
    target,
  }: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => {
    assert(target instanceof Node, 'Unexpected error');
    if (!userMenuAnchorRef.current?.contains(target)) setUserMenuOpen(false);
  };

  const handleQuickMenuToggle = () => {
    setQuickMenuOpen((prevOpen) => !prevOpen);
  };

  const handleQuickMenuClose = ({
    target,
  }: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => {
    assert(target instanceof Node, 'Unexpected error');
    if (!quickMenuAnchorRef.current?.contains(target)) setQuickMenuOpen(false);
  };

  const drawerContainer = (
    <div className={classes.sidebarParent}>
      <div className={classes.sidebarTop}>
        <Hidden smDown implementation="css">
          <div className={`${classes.toolbar} ${classes.logoContainer}`}>
            <Link to={link('/dashboard')} className={classes.logo}>
              <img
                src={matches ? logoOnly : logo}
                alt="FanConnect Content Manager"
                className={matches ? classes.slimIcon : ''}
              />
            </Link>
            <Typography className={classes.subTitle}>Content Manager</Typography>
          </div>
        </Hidden>

        <Hidden smUp implementation="css">
          <IconButton onClick={toggleSidebar} className={classes.closeMenuButton} size="large">
            <Close />
          </IconButton>
        </Hidden>

        <Hidden smDown implementation="css">
          <Button
            ref={userMenuAnchorRef}
            className={clsx(classes.button, classes.userInfoButton)}
            onClick={handleUserMenuToggle}
          >
            <div className={clsx(classes.icon, classes.userInfoIcon)}>
              <Box display="flex" className={classes.userInfo}>
                <UserAvatar user={currentUser} />
                <Box className={classes.userInfoDetail}>
                  <Typography variant="h4" noWrap className={classes.userName}>
                    {currentUser.name}
                  </Typography>
                </Box>
                <KeyboardArrowDown />
              </Box>
            </div>
          </Button>
          <UserActionsPopover
            anchorEl={userMenuAnchorRef}
            isOpen={userMenuOpen}
            onCloseMenu={handleUserMenuClose}
          />
        </Hidden>

        <List className={classes.sidebarTopMenu}>
          {/* Dashboard entrypoint */}
          <NavItemLink
            icon={Dashboard}
            text="Dashboard"
            to={link('/dashboard')}
            selected={location.pathname.includes('dashboard')}
            onClick={handleClickAway}
          />

          {/* Devices entrypoint */}
          {currentNetwork.canAccessDevices.value && (
            <NavItemLink
              icon={Router}
              text="Devices"
              to={link('/devices')}
              selected={/^\/networks\/\d+\/devices/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          {currentNetwork.canAccessContent.value && (
            <NavItemLink
              data-testid="content-nav-item"
              icon={CropOriginal}
              text="Content"
              to={link('/content')}
              selected={location.pathname.includes('content/')}
              onClick={handleClickAway}
            />
          )}

          {/* Data sources entrypoint */}
          {currentNetwork.canAccessDataSources.value && (
            <NavItemLink
              icon={CloudSync}
              text="Data Sources"
              to={link('/data-sources')}
              selected={
                location.pathname.includes('data-sources') &&
                !location.pathname.includes('settings')
              }
              onClick={handleClickAway}
            />
          )}

          {/* Playlists entrypoint */}
          {currentNetwork.canAccessPlaylists.value && (
            <NavItemLink
              icon={OndemandVideo}
              text="Playlists"
              to={link('/playlists')}
              selected={/^\/networks\/\d+\/playlists/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          {/* Shows entrypoint */}
          {currentNetwork.canAccessShows.value && (
            <NavItemLink
              icon={MovieCreation}
              text="Shows"
              to={link('/shows')}
              selected={/^\/networks\/\d+\/shows/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          {/* Studio entrypoint */}
          {currentNetwork.canAccessStudio.value && (
            <NavItemLink
              icon={AutoFixHigh}
              text="Studio"
              to={link('/studio')}
              selected={location.pathname.includes('studio')}
              onClick={handleClickAway}
            />
          )}

          {/* Schedules entrypoint */}
          {currentNetwork.canAccessScheduler.value && (
            <>
              <NavItemButton
                component="a"
                icon={Event}
                text="Schedules"
                onClick={handleSchedulesClick}
                endIcon={schedulesOpen ? <ExpandLess /> : <ExpandMore />}
              />
              <Collapse in={schedulesOpen} timeout="auto">
                <List component="div">
                  <NavSubItemLink
                    icon={CalendarMonth}
                    text="Events"
                    to={link('/events')}
                    selected={/^\/networks\/\d+\/events/.test(location.pathname)}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={Schedule}
                    text="Manual"
                    to={link('/schedules')}
                    selected={/^\/networks\/\d+\/schedules/.test(location.pathname)}
                    onClick={handleClickAway}
                  />
                </List>
              </Collapse>
            </>
          )}

          {/* Reporting entrypoint */}
          {currentNetwork.canAccessReporting.value && (
            <NavItemLink
              icon={Assessment}
              text="Reporting"
              to={link('/reporting')}
              selected={location.pathname.includes('reporting')}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          {/* Channel guide entrypoint */}
          {currentNetwork.canManage.value && (
            <NavItemLink
              icon={Dvr}
              text="Channel Guides"
              to={link('/channel-guides')}
              selected={location.pathname.includes('channel-guides')}
              onClick={handleClickAway}
            />
          )}

          {/* Layouts entrypoint */}
          {currentNetwork.canManage.value && (
            <NavItemLink
              icon={ViewComfy}
              text="Layouts"
              to={link('/layouts')}
              selected={location.pathname.includes('layouts')}
              onClick={handleClickAway}
            />
          )}

          {/* Templates entrypoint */}
          {currentNetwork.canAccessTemplates.value && (
            <NavItemLink
              icon={AppRegistration}
              text="Templates"
              to={link('/templates')}
              selected={location.pathname.includes('templates')}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          {/* Settings entrypoint */}
          <NavItemButton
            component="a"
            icon={Settings}
            text="Settings"
            onClick={handleSettingsClick}
            endIcon={settingsOpen ? <ExpandLess /> : <ExpandMore />}
          />
          <Collapse in={settingsOpen} timeout="auto">
            <List component="div">
              <NavSubItemLink
                icon={AccountCircle}
                text="My Profile"
                to={link('/settings/profile')}
                selected={location.pathname.includes('settings/profile')}
              />
              {currentNetwork.canManage.value && (
                <>
                  <NavSubItemLink
                    icon={Hub}
                    text="Network"
                    to={link('/settings/network')}
                    selected={location.pathname.includes('settings/network')}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={DeviceHub}
                    text="Device Groups"
                    to={link('/settings/device-groups')}
                    selected={location.pathname.includes('settings/device-groups')}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={Stadium}
                    text="Event Types"
                    to={link('/settings/event-types')}
                    selected={location.pathname.includes('settings/event-types')}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={VerifiedUser}
                    text="Roles"
                    to={link('/settings/roles')}
                    selected={location.pathname.includes('settings/roles')}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={Group}
                    text="Users"
                    to={link('/settings/users')}
                    selected={location.pathname.includes('settings/users')}
                    onClick={handleClickAway}
                  />
                  <NavSubItemLink
                    icon={ReceiptLong}
                    text="Audit Logs"
                    to={link('/settings/audit-logs')}
                    selected={location.pathname.includes('settings/audit-logs')}
                    onClick={handleClickAway}
                  />
                </>
              )}
            </List>
          </Collapse>
        </List>
      </div>

      <div className={classes.sidebarBottom}>
        <List>
          {currentUser.admin && (
            <NavItemButton icon={SupervisorAccount} text="Admin Site" component="a" href="/admin" />
          )}

          <NavItemButton
            component="a"
            href={`mailto:${supportEmail}`}
            icon={HelpOutline}
            text="Support"
          />

          {currentNetwork.canManage.value && (
            <NavItemButton
              onClick={handleQuickMenuToggle}
              ref={quickMenuAnchorRef}
              icon={AddCircle}
              text="Quick Actions"
            />
          )}
        </List>

        <Hidden lgDown implementation="css">
          <Divider className={classes.divider} />
        </Hidden>

        <QuickActionsPopover
          anchorEl={quickMenuAnchorRef}
          isOpen={quickMenuOpen}
          onCloseMenu={handleQuickMenuClose}
        />
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer}>
      <Drawer
        openDrawer={open}
        setOpenDrawer={toggleSidebar}
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
        }}
        sx={{
          ...(impersonating && { '.MuiDrawer-paper': { height: 'calc(100% - 50px)', top: 50 } }),
        }}
      >
        {drawerContainer}
      </Drawer>
    </nav>
  );
};
