import { Link } from '~/components/link';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { mediaDimensions, mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type { MediaItemOriginalProps__MediaItem } from './MediaItemOriginal.generated';

/* GraphQL */ `#graphql
fragment MediaItemOriginalProps__MediaItem on MediaItem {
  id
  kind
  originalFile {
    id
    originalFilename
    uri
  }
  originalMetadata
}
`;

export interface MediaItemOriginalProps {
  mediaItem: MediaItemOriginalProps__MediaItem;
}

export const MediaItemOriginal = ({ mediaItem }: MediaItemOriginalProps) => {
  return (
    <>
      <SettingsHead>Original</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          <MetaItem>
            <ListRowHeadingValue
              heading="File"
              value={
                <Link to={mediaItem.originalFile.uri} target="_blank">
                  {mediaItem.originalFile.originalFilename}
                </Link>
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Size" value={formatBytes(mediaSize(mediaItem))} />
          </MetaItem>
          <MetaItem className="last">
            <ListRowHeadingValue
              heading="Dimensions"
              value={mediaDimensions(mediaItem).join(' x ')}
            />
          </MetaItem>
        </SettingsData>
      </SettingsBody>
    </>
  );
};
