import { useQuery } from '@apollo/client';
import { Error, OfflineBolt, Unpublished } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, MenuItem, Select, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { DeviceCommandKind } from '~/generated/graphql';
import { formatDate } from '~/lib/datetime';
import { UpdateSoftwareDialogDocument } from './update-software-dialog.generated';

interface UpdateSoftwareDialogProps {
  deviceIds: readonly number[] | number;
  onClose: () => void;
  onComplete?: () => void;
}

export const UpdateSoftwareDialog = ({
  deviceIds,
  onClose,
  onComplete,
}: UpdateSoftwareDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data, loading } = useQuery(UpdateSoftwareDialogDocument, {
    variables: { deviceIds, networkId: currentNetwork.id },
  });
  const [version, setVersion] = useState('');

  const [sendCommand, { called }] = useSendDeviceCommand();

  const updateSoftware = () => {
    void sendCommand({
      variables: { command: DeviceCommandKind.Update, deviceIds, payload: { version } },
    });
    onComplete?.();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Software Update</DialogTitle>

      <DialogContent sx={{ fontSize: '1rem' }}>
        <LoadingPane in={loading && !data}>
          {data?.network && (
            <>
              {data.network.softwareUpdateInfo.currentVersion && (
                <DialogContentTitle>
                  The device's current software version is{' '}
                  <b>{data.network.softwareUpdateInfo.currentVersion}</b>.
                </DialogContentTitle>
              )}
              {data.network.softwareUpdateInfo.reason ? (
                <DialogContentTitle>{data.network.softwareUpdateInfo.reason}</DialogContentTitle>
              ) : (
                <Select<string>
                  displayEmpty
                  onChange={(event) => setVersion(event.target.value)}
                  sx={{ fontSize: 'inherit' }}
                  value={version}
                >
                  <MenuItem disabled value="">
                    Select a new version
                  </MenuItem>
                  {data.network.softwareUpdateInfo.versions.map((softwareVersion) => (
                    <MenuItem
                      disabled={
                        softwareVersion.version === data.network?.softwareUpdateInfo.currentVersion
                      }
                      key={softwareVersion.version}
                      value={softwareVersion.version}
                      sx={
                        softwareVersion.version === data.network?.softwareUpdateInfo.currentVersion
                          ? { backgroundColor: 'primary.main' }
                          : { color: 'inherit', textDecoration: 'none' }
                      }
                    >
                      {softwareVersion.version}
                      {softwareVersion.current === false && (
                        <Tooltip
                          title={`This version is End-of-life as of ${formatDate(
                            softwareVersion.eoledAt,
                          )}`}
                        >
                          <Error sx={{ color: 'error.main', marginLeft: '5px' }} />
                        </Tooltip>
                      )}
                      {softwareVersion.approved === false && (
                        <Tooltip title="This version currently is unapproved">
                          <Unpublished sx={{ color: 'grey.300', marginLeft: '5px' }} />
                        </Tooltip>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </>
          )}
        </LoadingPane>
      </DialogContent>

      <DialogActions>
        <Button disabled={called || loading} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || loading || version === ''}
          onClick={updateSoftware}
          startIcon={<OfflineBolt />}
          variant="contained"
        >
          Update Software
        </Button>
      </DialogActions>
    </Dialog>
  );
};
