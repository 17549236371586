import { Hub, KeyboardArrowDown, Settings } from '@mui/icons-material';
import { Avatar, ListItemIcon, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { ListItemText } from '~/components/list-row';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import {
  ContextButtonLink,
  ContextMenu,
  ContextMenuItem,
  ContextMenuItemAction,
  ContextMenuList,
  FilterMenuItem,
  FilterTextField,
  MenuButton,
  MenuButtonText,
} from './menu';

const NetworkAvatar = styled(Avatar)(({ theme }) => ({
  color: theme.palette.common.black,
  width: theme.spacing(3),
  height: theme.spacing(3),
  marginRight: theme.spacing(1),
}));

export interface NetworkIconProps {
  icon: string | null | undefined;
}

const NetworkIcon = ({ icon }: NetworkIconProps) =>
  icon == null ? (
    <ListItemIcon>
      <Hub />
    </ListItemIcon>
  ) : (
    <NetworkAvatar src={icon} />
  );

export const NetworkMenu = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const { currentNetwork, currentUser } = useAppContext();
  const link = useLink();

  const networks = currentUser.networks.filter((network) => {
    if (currentUser.admin) {
      return network;
    } else {
      return !network.public;
    }
  });

  const width = (buttonRef.current?.clientWidth ?? 0) + 1;

  return (
    <>
      <MenuButton
        ref={buttonRef}
        disableRipple
        onClick={() => setOpen(true)}
        disabled={networks.length === 1}
      >
        <NetworkIcon icon={currentNetwork.logoUrl} />
        <MenuButtonText>{currentNetwork.name}</MenuButtonText>
        <KeyboardArrowDown />
      </MenuButton>

      <ContextMenu
        PaperProps={{
          style: { width, maxWidth: width },
        }}
        TransitionProps={{
          onEntered: () => inputRef.current?.focus(),
          onExited: () => setFilter(''),
        }}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
        open={open}
      >
        <FilterMenuItem>
          <FilterTextField
            inputRef={inputRef}
            onChange={(event) => setFilter(event.target.value)}
            placeholder="Filter networks"
          />
        </FilterMenuItem>

        <ContextMenuList>
          {networks.map((network) => {
            if (!network.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) return null;
            return (
              <ContextMenuItem
                disableRipple
                key={network.id}
                disabled={network.id === currentNetwork.id}
              >
                <ContextButtonLink
                  onClick={() => setOpen(false)}
                  to={link('/dashboard', network.id)}
                >
                  <NetworkIcon icon={network.logoUrl} />
                  <ListItemText>{network.name}</ListItemText>
                </ContextButtonLink>

                <ContextMenuItemAction
                  onClick={() => setOpen(false)}
                  aria-label="Network Settings"
                  disableRipple
                  to={link('/settings/network/settings')}
                >
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                </ContextMenuItemAction>
              </ContextMenuItem>
            );
          })}
        </ContextMenuList>
      </ContextMenu>
    </>
  );
};
