import { useQuery } from '@apollo/client';
import {
  AddCircle,
  AppRegistration,
  CleaningServices,
  Difference,
  Edit,
  GetApp,
  MoreVert,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import { useCleanupTemplateVersions, useDeleteTemplate } from '~/api/templates';
import { AppCard } from '~/components/AppCard';
import { CountChip } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { ButtonLink } from '~/components/link';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { MoreActionLink, MoreDeleteAction } from '~/components/table';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import { AddAppDialog, useCreateTemplateDialog } from '../create-template-dialog';
import {
  TemplateListDocument,
  type TemplateList__Template as Template,
} from '../queries/list.generated';

export const TemplateList = () => {
  const [appsValue, setAppsValue] = useState<string>();

  const { currentUser } = useAppContext();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [confirmCleanup, confirmCleanupProps] = useConfirmDialog();

  const [deleteTemplate] = useDeleteTemplate();

  const [cleanup] = useCleanupTemplateVersions();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<Template>();

  const { data, loading, error } = useQuery(TemplateListDocument);

  const appCount =
    data?.templateCategories.reduce((count, { templates }) => count + templates.length, 0) ?? 0;

  const categories = data?.templateCategories.filter((category) => category.templates.length) ?? [];

  const onCleanup = async () => {
    assert(typeof moreMenuProps.context?.id === 'number', 'cleanup: no template id');
    if (!(await confirmCleanup())) return;
    await cleanup({ variables: { templateIds: moreMenuProps.context.id } });
    moreMenuProps.onClose();
  };

  const onDelete = async () => {
    assert(typeof moreMenuProps.context?.id === 'number', 'delete: no template id');
    if (!(await confirmDelete())) return;
    await deleteTemplate({ variables: { templateId: moreMenuProps.context.id } });
  };
  const [openAddApp, addAppDialogProps] = useCreateTemplateDialog();

  if (error || (!loading && !data?.templateCategories)) return <Navigate replace to="/not-found" />;

  const NewTemplateButton = (
    <Button
      variant="contained"
      color="primary"
      size="medium"
      startIcon={<AddCircle />}
      onClick={openAddApp}
    >
      New Template
    </Button>
  );

  return (
    <>
      <Helmet title="Templates" />

      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <Toolbar
          actions={currentUser.admin && NewTemplateButton}
          titleIcon={<AppRegistration />}
          titleText="Templates"
        ></Toolbar>
        <PageContainer>
          <CountChip
            label={`All ${appCount}`}
            onClick={() => setAppsValue(undefined)}
            selected={appsValue == null}
          />

          {categories.map((category) => (
            <CountChip
              key={category.id}
              label={`${category.name} ${category.templates.length}`}
              onClick={() => setAppsValue(category.name)}
              selected={appsValue === category.name}
            />
          ))}

          {categories.map(
            (category) =>
              (appsValue === category.name || appsValue == null) && (
                <Box sx={{ mt: 2 }} key={category.id}>
                  <Typography variant="h4" sx={{ fontWeight: 500, mb: 2 }}>
                    {category.name}
                  </Typography>
                  <Grid container spacing={4} mb={3}>
                    {category.templates.map((app) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={app.id}>
                        <AppCard
                          app={app}
                          onCardTo={currentUser.admin ? `${app.id}/settings` : undefined}
                        >
                          {app.latestVersion ? (
                            <Tooltip arrow title="Use this Template to create your App">
                              <ButtonLink
                                sx={{
                                  mb: 1,
                                }}
                                size="small"
                                startIcon={<GetApp />}
                                to={`${app.id}/create`}
                                variant="contained"
                              >
                                Use Template
                              </ButtonLink>
                            </Tooltip>
                          ) : (
                            <div></div>
                          )}
                          {currentUser.admin && (
                            <Tooltip arrow title="More Actions">
                              <IconButton
                                onClick={moreTableActionProps(app).onClick}
                                size="small"
                                color="secondary"
                              >
                                <MoreVert />
                              </IconButton>
                            </Tooltip>
                          )}
                        </AppCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ),
          )}

          <Menu {...moreMenuProps}>
            <MoreActionLink
              Icon={Settings}
              title="Configuration"
              to={`${moreMenuProps.context?.id}/configuration`}
            />
            <MoreActionLink Icon={Edit} title="Edit" to={`${moreMenuProps.context?.id}/settings`} />
            <MoreActionLink
              Icon={Difference}
              title="Versions"
              to={`${moreMenuProps.context?.id}/versions`}
            />
            <Divider />
            <MenuItem
              disabled={!moreMenuProps.context?.canCleanup.value}
              onClick={() => void onCleanup()}
            >
              <ListItemIcon>
                <CleaningServices />
              </ListItemIcon>
              <ListItemText>Delete Unused Versions</ListItemText>
            </MenuItem>

            <MoreDeleteAction
              {...moreActionProps}
              disabled={!moreMenuProps.context?.canDestroy.value}
              onClick={() => void onDelete()}
            />
          </Menu>

          <ConfirmDialog
            {...confirmCleanupProps}
            title="Remove Unused Versions"
            prompt="Removing unused versions will permanently remove them from the system."
            confirm="Confirm"
          />

          <ConfirmDialog
            {...confirmDeleteProps}
            confirm="Permanently Delete"
            deleteConfirm
            prompt="Deleting this Template will delete all Apps that were created from this Template."
            title="Delete Template"
          />

          <AddAppDialog {...addAppDialogProps} />
        </PageContainer>
      </LoadingPane>
    </>
  );
};
