import { useMediaQuery, useTheme } from '@mui/material';
import {
  type GridColumnVisibilityModel,
  type GridRowParams,
  type GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { memo, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { useLink } from '~/hooks/link';
import UploadMediaIllustration from '~/images/illustrations/upload-media.svg';
import { useColumns } from '../lib';
import type { ContentCardProps } from './ContentCard';
import type { ContentFolderCardProps } from './ContentFolderCard';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={UploadMediaIllustration}
    header="Upload your content"
    description="This is where you store images and videos to use for your devices."
  />
);

export interface ContentListProps {
  items: readonly ContentCardProps['item'][];
  folders: readonly ContentFolderCardProps['contentFolder'][];
  loading: boolean;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  search?: boolean;
  selectedIds: GridRowSelectionModel;
}

type ContentOrFolder = ContentCardProps['item'] | ContentFolderCardProps['contentFolder'];

export const ContentList = memo(
  ({ items, folders, loading, onCheck, search = false, selectedIds }: ContentListProps) => {
    const location = useLocation();
    const link = useLink();
    const combinedData = [...folders, ...items];

    const columns = useColumns({ search });
    const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
    const navigate = useNavigate();

    const theme = useTheme();
    const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
      setShowColumns((x) => ({
        ...x,
        thumbnail: !isSmallAndDown,
        kind: !isSmallAndDown,
        originalMetadata: !isSmallAndDown,
        createdAt: !isSmallAndDown,
      }));
    }, [isSmallAndDown]);

    return (
      <SeparatedDataGrid
        checkboxSelection={!isSmallAndDown}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableRowSelectionOnClick
        isRowSelectable={({ row }) => row.__typename !== 'ContentFolder'}
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onRowSelectionModelChange={onCheck}
        onRowClick={({ row }: GridRowParams<ContentOrFolder>) => {
          if (row.__typename === 'ContentFolder') {
            navigate({ pathname: `../${row.id}`, search: location.search });
            return;
          }
          if (row.__typename === 'ContentItem' && row.design?.id) {
            return navigate(link(`/studio/${row.design.id}`));
          }
          navigate(
            link(
              `/content/${
                row.__typename === 'ContentItem' ? row.nullableContentFolderId : row.contentFolderId
              }/${row.__typename === 'ContentItem' ? 'apps' : 'media'}/${row.id}/details`,
            ),
          );
        }}
        getRowId={(row) => `${row.__typename}-${row.id}`}
        rows={combinedData}
        rowSelectionModel={selectedIds}
        slots={{
          baseCheckbox: ListCheckbox,
          columnResizeIcon: () => null,
          noRowsOverlay: NoRowsOverlay,
        }}
      />
    );
  },
);

ContentList.displayName = 'ContentList';
