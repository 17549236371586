import { Close, ImportExport, Redo, Save, Undo } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateDesign } from '~/api/designs';
import { SelectFolderDialog } from '~/components/dialogs/SelectFolder';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useStudio } from '../../context';
import { type DesignEdit__Design as Design } from '../DesignEdit.generated';
import { usePublish, useSave } from '../lib';

export interface StudioToolbarProps {
  design: Design;
}

export const StudioToolbar = ({ design }: StudioToolbarProps) => {
  const { canRedo, canUndo, dirty, redo, undo } = useStudio();
  const navigate = useNavigate();
  const [updateDesign] = useUpdateDesign();
  const [confirmClose, confirmCloseProps] = useConfirmDialog();

  const [selectFolderOpen, setSelectFolderOpen] = useState(false);

  const [publish, publishLoading] = usePublish(design);
  const [handleSave, saveLoading] = useSave(design);

  const handlePublish = useCallback(
    async (folderId: string) => {
      await publish(+folderId);
      setSelectFolderOpen(false);
    },
    [publish],
  );

  const selectFolder = useCallback(() => {
    setSelectFolderOpen(true);
  }, []);

  return (
    <>
      <Toolbar
        actions={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <IconButton disabled={!canUndo} onClick={undo} sx={{ color: '#000000' }}>
                <Undo />
              </IconButton>
              <IconButton disabled={!canRedo} onClick={redo} sx={{ color: '#000000' }}>
                <Redo />
              </IconButton>
            </Box>
            <Divider orientation="vertical" />
            <LoadingButton
              color="primary"
              disabled={!dirty}
              loading={saveLoading}
              loadingPosition="start"
              onClick={handleSave}
              startIcon={<Save />}
              variant="contained"
            >
              Save
            </LoadingButton>
            <Tooltip title="Publish your design to the content library">
              <LoadingButton
                color="primary"
                disabled={saveLoading || selectFolderOpen}
                loading={publishLoading}
                loadingPosition="start"
                onClick={async () => {
                  await (design.publishedApp && design.publishedApp.contentFolderId
                    ? handlePublish(design.publishedApp.contentFolderId.toString())
                    : selectFolder());
                }}
                startIcon={<ImportExport />}
                variant="contained"
              >
                Publish
              </LoadingButton>
            </Tooltip>
            <Divider orientation="vertical" />
            <Button
              onClick={async () => {
                if (dirty && !(await confirmClose())) return;
                history.length > 1 ? navigate(-1) : navigate('..');
              }}
              variant="outlined"
              sx={{ minWidth: 0, padding: 1 }}
            >
              <Close />
            </Button>
          </Box>
        }
        titleEditor={
          <Box sx={{ marginLeft: '-32px' }}>
            <InlineEditTitle
              inputId="studio-item-name"
              value={design.name}
              update={(name) =>
                void updateDesign({ variables: { id: design.id, patch: { name } } })
              }
              tooltip="Edit the name of this design"
              validationSchema={object({
                name: string().required('Design name is required').trim(),
              })}
            />
          </Box>
        }
      />
      <ConfirmDialog
        {...confirmCloseProps}
        confirm="Close Without Saving"
        prompt={
          <span>
            You will lose any unsaved changes by closing without saving.{' '}
            <b>This operation may not be undone.</b>
          </span>
        }
        title="Unsaved Changes"
      />
      <SelectFolderDialog
        description="Select the folder where you want to save this design."
        open={selectFolderOpen}
        onClose={() => setSelectFolderOpen(false)}
        onSubmit={handlePublish}
      />
    </>
  );
};
