import { useQuery } from '@apollo/client';
import { LiveTv } from '@mui/icons-material';
import { Autocomplete, Box, Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { useAppContext } from '~/contexts';
import { DeviceCommandKind } from '~/generated/graphql';
import { assert } from '~/lib/assert';
import { TuneChannelDialogDocument } from '../queries/queries.generated';

interface TuneChannelDialogProps {
  deviceIds: number[];
  onClose: () => void;
  onComplete?: () => void;
  open: boolean;
}

export const TuneChannelDialog = ({
  deviceIds,
  onClose,
  onComplete,
  open,
}: TuneChannelDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(TuneChannelDialogDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [selectedChannel, setSelectedChannel] = useState<number | null>();

  const [sendCommand, { called }] = useSendDeviceCommand();

  const tune = () => {
    if (!selectedChannel) return;
    const channel = data?.network?.channels.find((channel) => channel.id === selectedChannel);
    assert(channel != null, 'TuneChannelDialog: No channel selected');
    void sendCommand({
      variables: {
        command: DeviceCommandKind.TuneChannel,
        deviceIds: deviceIds,
        payload: { id: channel.id },
      },
    });
    onComplete?.();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
      <DialogTitle onClose={onClose}>Tune Channel</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Select a channel in which to tune:</DialogContentTitle>

        <Autocomplete
          fullWidth
          onChange={(_event, newValue) => {
            setSelectedChannel(newValue?.id);
          }}
          autoHighlight
          getOptionLabel={(option) => `${option.name} (${option.number})`}
          options={data?.network?.channels ?? []}
          renderInput={(params) => <TextField {...params} label="Select a Channel" />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                srcSet={option.thumbnailFile.uri}
                src={option.thumbnailFile.uri}
                alt=""
              />
              {option.name} ({option.number})
            </Box>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={onClose} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={selectedChannel == null || called}
          onClick={tune}
          startIcon={<LiveTv />}
          variant="contained"
        >
          Tune Channel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
