import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { DetailKey } from '~/components/info-pane';
import { ViewSelect } from '~/components/shows';
import { AlertMessage } from '../../components/alert-message';
import { Layout } from '../components/layout';
import type { SimpleShowView__Show } from './SimpleShowView.generated';
import { Details, ViewZone } from './components';

interface SimpleShowViewProps {
  show: SimpleShowView__Show;
}

export const SimpleShowView = ({ show }: SimpleShowViewProps) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const setViewId = useCallback<(viewId: number | undefined) => void>(
    (id: number | undefined) => navigate(`?viewId=${id}`, { replace: true }),
    [navigate],
  );

  const viewId = Number(searchParams.get('viewId')) || undefined;

  if (viewId == null) return <Navigate to={`?viewId=${show.defaultViewId}`} replace />;

  const view = show.views.find(({ id }) => id === viewId);
  if (view == null) return <Navigate to="/not-found" replace />;

  const { layout, viewZones } = view;

  const multiZone = viewZones.length > 1;

  return (
    <Layout show={show}>
      <AlertMessage show={show} />
      {show.views.length > 1 && (
        <Box>
          <DetailKey id="show-view">
            <Typography variant="overline">VIEW</Typography>
          </DetailKey>
          <ViewSelect sx={{ mb: 2 }} fullWidth onChange={setViewId} value={viewId} show={show} />
        </Box>
      )}
      <Details show={show} view={view} />
      {viewZones.map((viewZone) => (
        <ViewZone
          id={`zone-${viewZone.zoneId}`}
          key={viewZone.id}
          layout={layout}
          multiZone={multiZone}
          show={show}
          viewZone={viewZone}
        />
      ))}
    </Layout>
  );
};
