import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, TextField } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useUpdatePlaylistTags } from '~/api/playlist-tags';
import { usePlaylist } from '../context';

export const useSettingsForm = () => {
  const { playlist, playlistTags } = usePlaylist();

  const [updateTags] = useUpdatePlaylistTags();

  const onHandleTagChange = useCallback(
    async (taggableId: number, tags: string[]) => {
      try {
        await updateTags({
          variables: {
            taggableId,
            tags,
          },
        });
      } catch (error) {
        console.error('Failed to update tags:', error);
      }
    },
    [updateTags],
  );

  const handleTagChange = useCallback(
    async (_event: React.SyntheticEvent, newValue: string[]) => {
      try {
        await onHandleTagChange(playlist.id, newValue);
      } catch (error) {
        console.error('Failed to handle tag change:', error);
      }
    },
    [onHandleTagChange, playlist],
  );

  const generalSettings = useMemo(() => {
    return [
      {
        heading: 'Tags',
        subHeading: 'Tags associated with this Playlist',
        dataField: (
          <Autocomplete
            multiple
            autoHighlight
            disableCloseOnSelect
            options={playlistTags}
            value={Array.from(playlist.tags)}
            onChange={handleTagChange}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} />}
          />
        ),
      },
    ];
  }, [playlist, playlistTags, handleTagChange]);

  return {
    generalSettings,
  };
};
