import { useQuery } from '@apollo/client';
import {
  Dashboard as DashboardIcon,
  Help,
  PersonAdd,
  QueuePlayNext,
  Router,
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { supportEmail } from '~/lib/constants';
import { DashboardDocument } from './Dashboard.generated';
import { DeviceStats } from './device-stats';
import { ShortcutCard, ShortcutCardLink } from './shortcut-card';

export const Dashboard = () => {
  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(DashboardDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const deviceStats = data?.network?.deviceStats;

  return (
    <>
      <Helmet title="Dashboard" />
      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <Toolbar titleIcon={<DashboardIcon />} titleText="Dashboard" />

        <PageContainer>
          {currentNetwork.canAccessDevices.value && (
            <DeviceStats
              activeCount={deviceStats?.activeCount ?? 0}
              healthyCount={deviceStats?.healthyCount ?? 0}
              warnCount={deviceStats?.warnCount ?? 0}
              errorCount={deviceStats?.errorCount ?? 0}
              displayOnCount={deviceStats?.displayOnCount ?? 0}
              displayOffCount={deviceStats?.displayOffCount ?? 0}
            />
          )}

          <Grid container mt={0} spacing={4}>
            {currentNetwork.canAccessDevices.value && (
              <ShortcutCardLink
                icon={<Router />}
                title="Monitor Devices"
                caption="Check on the health of your devices"
                to={link('/devices')}
              />
            )}

            {currentNetwork.canManage.value && (
              <>
                <ShortcutCardLink
                  icon={<PersonAdd />}
                  title="Create User"
                  caption="Add a new user to your account"
                  to={link('/settings/users/new')}
                />

                <ShortcutCardLink
                  icon={<QueuePlayNext />}
                  title="Activate Device"
                  caption="Add a new device to your account"
                  to={link('/devices/activate')}
                />
              </>
            )}

            <ShortcutCard
              icon={<Help />}
              title="Need Help?"
              caption="Contact our support team"
              href={`mailto:${supportEmail}`}
            />
          </Grid>
        </PageContainer>
      </LoadingPane>
    </>
  );
};
