import { useQuery } from '@apollo/client';
import { AddCircle, DeviceHub } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { NetworkDeviceGroupDevicesDocument } from '../queries/devices.generated';
import { AddDevicesDialog, BulkActionsMenu, DeviceTable, Tabs } from './components';

export const DeviceGroupDevices = () => {
  const { currentNetwork } = useAppContext();

  const link = useLink();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const params = useParams<{ deviceGroupId: string }>();

  const deviceGroupId = Number(params.deviceGroupId);

  const [addDevicesOpen, setAddDevicesOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const { data, loading } = useQuery(NetworkDeviceGroupDevicesDocument, {
    variables: { networkId: currentNetwork.id, deviceGroupId, search },
  });

  const devices = data?.network?.deviceGroup.devices ?? [];

  const addButton = (
    <Button
      color="primary"
      onClick={() => setAddDevicesOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      Add Devices
    </Button>
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network && (
        <>
          <Toolbar
            actions={addButton}
            breadcrumbsLabel={<RouterBreadcrumbs />}
            titleIcon={<DeviceHub />}
            titleText={data.network.deviceGroup.name}
            returnTo={link('/settings/device-groups')}
          />

          <Tabs current="Devices" />

          <PageContainer>
            <SearchContainer>
              <SearchBar placeholder="Search Devices" search={search} />
              <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                <BulkActionsMenu deviceGroup={data.network.deviceGroup} selectedIds={selectedIds} />
                <Count selectedCount={0} totalCount={devices.length} thing="device" />
              </Box>
            </SearchContainer>
            <DeviceTable
              checkboxSelection={devices.length > 1}
              disableRowSelectionOnClick={devices.length < 2}
              loading={loading}
              onRowSelectionModelChange={setSelectedIds}
              deviceGroup={data.network.deviceGroup}
              rows={devices}
              rowSelectionModel={selectedIds}
            />
          </PageContainer>

          {addDevicesOpen && (
            <AddDevicesDialog
              devices={data.network.ungroupedDevices.nodes}
              deviceGroup={data.network.deviceGroup}
              fullWidth
              onClose={() => setAddDevicesOpen(false)}
              open
            />
          )}
        </>
      )}
    </LoadingPane>
  );
};
