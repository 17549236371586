import { useApolloClient, useMutation } from '@apollo/client';
import { AccountCircle, ExitToApp, HelpOutline } from '@mui/icons-material';
import {
  ClickAwayListener,
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { MutableRefObject } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '~/components/link';
import { LogoutDocument } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { resetWebsocketConnection } from '~/lib/apollo';
import { supportEmail } from '~/lib/constants';

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(0),
  },
  menuHeading: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 600,
    color: '#000000',
  },
  networkMenuItem: {
    '& .MuiListItemText-root > span': {
      textOverflow: 'ellipsis',
      maxWidth: 'calc(100% - 16px)',
      overflow: 'hidden',
    },
  },
  menuItem: {},
  networkMenu: {
    maxHeight: '350px',
    overflow: 'auto',
  },
}));

export interface UserActionsPopoverProps {
  anchorEl: MutableRefObject<HTMLButtonElement | null>;
  isOpen: boolean;
  onCloseMenu: (event: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => void;
}

export const UserActionsPopover = ({ anchorEl, isOpen, onCloseMenu }: UserActionsPopoverProps) => {
  const classes = useStyles();

  const client = useApolloClient();

  const navigate = useNavigate();
  const link = useLink();

  const [logout] = useMutation(LogoutDocument, {
    async onCompleted() {
      resetWebsocketConnection();
      await client.resetStore();
    },
  });
  // end hooks

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disablePortal
      TransitionComponent={Collapse}
      PaperProps={{
        style: {
          maxWidth: 'calc(240px - 32px)',
          width: 'calc(240px - 32px)',
        },
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={onCloseMenu}>
          <div>
            <div className={classes.menuHeading}>Settings</div>
            <MenuList autoFocusItem={isOpen} className={classes.menu}>
              <MenuItem
                className={classes.menuItem}
                component={Link}
                to={link('/settings/profile')}
                onClick={onCloseMenu}
              >
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText>My Profile</ListItemText>
              </MenuItem>
            </MenuList>
            <Divider />
            <div className={classes.menuHeading}>Help</div>
            <MenuList className={classes.menu}>
              <MenuItem
                className={classes.menuItem}
                component="a"
                href={`mailto:${supportEmail}`}
                onClick={onCloseMenu}
              >
                <ListItemIcon>
                  <HelpOutline />
                </ListItemIcon>
                <ListItemText>Contact Support</ListItemText>
              </MenuItem>
            </MenuList>
            <Divider />
            <MenuList className={classes.menu}>
              <MenuItem
                className={classes.menuItem}
                onClick={(event) => {
                  onCloseMenu(event);
                  void logout().then(() => navigate('/login'));
                }}
              >
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText>Sign Out</ListItemText>
              </MenuItem>
            </MenuList>
          </div>
        </ClickAwayListener>
      </Paper>
    </Popover>
  );
};
