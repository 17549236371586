import { useQuery } from '@apollo/client';
import {
  Cancel,
  DesktopWindows,
  DeveloperBoard,
  Hub,
  RocketLaunch,
  Router,
  Sell,
  VisibilityOff,
  Wifi,
} from '@mui/icons-material';
import { MultiChip } from '~/components/inputs';
import { useAppContext } from '~/contexts';
import { DeviceDynamicFiltersDocument } from '~/generated/graphql';
import { ClearAllButton, ClearAllContainer, FilterContainer, useFilters } from '../lib';

export const Filter = () => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(DeviceDynamicFiltersDocument, {
    variables: {
      currentNetworkId: currentNetwork.id,
    },
    fetchPolicy: 'cache-first',
  });

  const deviceTags = data?.network?.deviceTags.map((x) => x.name) || [];
  const deviceGroups = data?.network?.deviceGroups || [];

  const {
    archs,
    demo,
    displayOn,
    groups,
    handleClear,
    internal,
    kinds,
    setArchs,
    setDemo,
    setDisplay,
    setGroups,
    setInternal,
    setKinds,
    setStatuses,
    setTags,
    statuses,
    tags,
    updateSearchParams,
  } = useFilters(deviceTags, deviceGroups);

  const { currentUser } = useAppContext();

  return (
    <FilterContainer>
      <MultiChip
        Icon={Router}
        items={kinds}
        label="Type"
        onClick={(_, newState) => {
          setKinds(() => newState);
          updateSearchParams('kinds', newState);
        }}
      />
      <MultiChip
        Icon={DeveloperBoard}
        items={archs}
        label="Arch"
        onClick={(_, newState) => {
          setArchs(() => newState);
          updateSearchParams('archs', newState);
        }}
      />
      <MultiChip
        Icon={Wifi}
        items={statuses}
        label="Status"
        onClick={(_, newState) => {
          setStatuses(() => newState);
          updateSearchParams('statuses', newState);
        }}
      />
      <MultiChip
        Icon={DesktopWindows}
        items={displayOn}
        label="Display"
        onClick={(_, newState) => {
          setDisplay(() => newState);
          updateSearchParams('display', newState);
        }}
      />
      {currentUser.admin && (
        <>
          <MultiChip
            Icon={VisibilityOff}
            items={internal}
            label="Internal"
            onClick={(_, newState) => {
              setInternal(() => newState);
              updateSearchParams('internal', newState);
            }}
          />
          <MultiChip
            Icon={RocketLaunch}
            items={demo}
            label="Demo"
            onClick={(_, newState) => {
              setDemo(() => newState);
              updateSearchParams('demo', newState);
            }}
          />
        </>
      )}
      {tags.length > 0 && (
        <MultiChip
          Icon={Sell}
          items={tags}
          label="Tags"
          onClick={(_, newState) => {
            setTags(() => newState);
            updateSearchParams('tags', newState);
          }}
        />
      )}
      {groups.length > 0 && (
        <MultiChip
          Icon={Hub}
          items={groups}
          label="Groups"
          onClick={(_, newState) => {
            setGroups(() => newState);
            updateSearchParams('groups', newState);
          }}
        />
      )}
      <ClearAllContainer>
        <ClearAllButton onClick={handleClear} startIcon={<Cancel />}>
          Clear All Filters
        </ClearAllButton>
      </ClearAllContainer>
    </FilterContainer>
  );
};
