import type {
  ButtonProps,
  IconButtonProps,
  ListItemProps,
  MenuItemProps,
  LinkProps as MuiLinkProps,
  TabProps,
} from '@mui/material';
import { Button, IconButton, ListItem, MenuItem, Link as MuiLink, Tab } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps<T = MuiLinkProps> = Omit<T, 'href' | 'button' | 'component'> &
  RouterLinkProps;

export const Link = forwardRef<HTMLAnchorElement, LinkProps<MuiLinkProps>>(
  //
  function Link(props, ref) {
    return <MuiLink {...props} component={RouterLink} ref={ref} />;
  },
);

export const ButtonLink = forwardRef<HTMLAnchorElement, LinkProps<ButtonProps>>(
  //
  function ButtonLink(props, ref) {
    return <Button {...props} component={RouterLink} ref={ref} />;
  },
);

export const IconButtonLink = forwardRef<HTMLAnchorElement, LinkProps<IconButtonProps<'a'>>>(
  function IconButtonLink(props, ref) {
    return <IconButton {...props} component={RouterLink} ref={ref} size="large" />;
  },
);

export const ListItemLink = forwardRef<HTMLAnchorElement, LinkProps<ListItemProps<'a'>>>(
  function ListItemLink(props, ref) {
    return <ListItem {...props} button component={RouterLink} ref={ref} />;
  },
);

export const MenuItemLink = forwardRef<HTMLAnchorElement, LinkProps<MenuItemProps<'a'>>>(
  function MenuItemLink(props, ref) {
    return <MenuItem {...props} component={RouterLink} ref={ref} />;
  },
);

export type TabLinkProps = LinkProps<TabProps<'a'>>;
export const TabLink = forwardRef<HTMLAnchorElement, TabLinkProps>(
  //
  function TabLink(props, ref) {
    return <Tab {...props} component={RouterLink} ref={ref} />;
  },
);
