import MuiImage from '@jy95/material-ui-image';
import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import type { MediaItemThumbnailsProps__MediaItem } from './MediaItemThumbnails.generated';

/* GraphQL */ `#graphql
fragment MediaItemThumbnailsProps__MediaItem on MediaItem {
  id
  thumbnails {
    boundsHeight
    boundsWidth
    uri
  }
}
`;

export interface MediaItemThumbnailsProps {
  mediaItem: MediaItemThumbnailsProps__MediaItem;
}

export const MediaItemThumbnails = ({ mediaItem }: MediaItemThumbnailsProps) => {
  return (
    <>
      <SettingsHead>Thumbnails</SettingsHead>
      <SettingsBody>
        <SettingsData sx={{ justifyContent: 'center !important' }} className="last">
          <ImageList cols={1}>
            {mediaItem.thumbnails.map((data, index) => (
              <ImageListItem key={index}>
                <a href={data.uri} target="_blank" rel="noreferrer">
                  <MuiImage
                    imageStyle={{ position: 'relative', borderRadius: 'inherit' }}
                    style={{
                      display: 'flex',
                      paddingTop: '0px',
                      borderRadius: '4px',
                    }}
                    src={data.uri}
                  />
                </a>
                <ImageListItemBar title={`${data.boundsWidth} x ${data.boundsHeight}`} />
              </ImageListItem>
            ))}
          </ImageList>
        </SettingsData>
      </SettingsBody>
    </>
  );
};
