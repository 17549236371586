import type * as Types from '~/generated/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type BulkUpdateDevicesDeviceGroupVariables = Types.Exact<{
  deviceIds: ReadonlyArray<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  deviceGroupId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type BulkUpdateDevicesDeviceGroup = { readonly __typename: 'Mutation', readonly bulkUpdateDevices?: { readonly __typename: 'BulkUpdateDevicesPayload', readonly errors: ReadonlyArray<{} | null>, readonly devices: ReadonlyArray<{ readonly __typename: 'Device', readonly id: number, readonly group?: { readonly __typename: 'DeviceGroupNew', readonly id: number } | null }> } | null };


export const BulkUpdateDevicesDeviceGroupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"BulkUpdateDevicesDeviceGroup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"deviceGroupId"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Int"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bulkUpdateDevices"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"deviceIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceIds"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"patch"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"deviceGroupId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"deviceGroupId"}}}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"devices"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"group"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"errors"}}]}}]}}]} as unknown as DocumentNode<BulkUpdateDevicesDeviceGroup, BulkUpdateDevicesDeviceGroupVariables>;