import { type ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { PageContainer } from '~/components/page-layout';
import { Actions } from './actions';
import type { ShowLayout__Show } from './layout.generated';
import { ShowToolbar } from './toolbar';

/* GraphQL */ `#graphql
fragment ShowLayout__Show on Show {
  ...ShowActions__Show
  ...ShowToolbar__Show
}
`;
export interface LayoutProps {
  children: ReactNode;
  show: ShowLayout__Show;
}

export const Layout = ({ children, show }: LayoutProps) => {
  const location = useLocation();

  return (
    <>
      <ShowToolbar
        actions={<Actions show={show} />}
        returnTo={{ pathname: '../..', search: location.search }}
        show={show}
      />
      <nav>
        <RouterTabs
          tabs={[
            { label: 'Design', to: '../design' },
            { label: 'Preview', to: '../preview' },
            { label: 'Settings', to: '../settings' },
          ]}
          variant="scrollable"
        />
      </nav>
      <PageContainer>{children}</PageContainer>
    </>
  );
};
