import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { z } from 'zod';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { StripedCard } from '~/components/striped-card';
import { TagInput } from '~/components/tag';
import { useAppContext } from '~/contexts';
import { TaggableType } from '~/generated/graphql';
import { formatDate } from '~/lib/datetime';
import { useDevice } from '../context';
import { displaySpace, displayTemperature, displayUptime } from '../lib';

const commandSchema = z.object({
  command: z.string(),
  arbitrary: z.string().optional(),
});

const FRIENDLY_TZ: Record<string, string> = {
  'America/New_York': 'Eastern',
  'America/Chicago': 'Central',
  'America/Denver': 'Mountain',
  'America/Los_Angeles': 'Pacific',
  'America/Juneau': 'Alaska',
  'Pacific/Honolulu': 'Hawaii',
};

export const Additional = () => {
  const { currentNetwork, currentUser } = useAppContext();

  const { device } = useDevice();
  const command = commandSchema.parse(device.command || { command: 'n/a' });

  const unCamelize = (str: string) => {
    return str
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .trim();
  };

  const metadataGrid = useMemo(() => {
    if (!device.metadata) return [];
    return Object.entries(device.metadata).map(([key, value]) => (
      <Grid item xs={12} md={4} key={key}>
        <StripedCard content={value} label={unCamelize(key)} />
      </Grid>
    ));
  }, [device.metadata]);

  return (
    <>
      <SettingsHead>Additional Information</SettingsHead>
      <SettingsBody>
        <SettingsData className="last">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <StripedCard content={device.id.toString()} label="ID" />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard
                content={FRIENDLY_TZ[device.timeZone] || device.timeZone}
                label="Time Zone"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard content={command.arbitrary || command.command} label="Command" />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard content={formatDate(device.activationChangedAt)} label="Activated" />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard
                content={device.statusUpdatedAt ? formatDate(device.statusUpdatedAt) : 'n/a'}
                label="Status Updated"
              />
            </Grid>
            {currentUser.admin && (
              <>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={device.show?.services.map((x) => x.name).join(', ') || 'n/a'}
                    label="Services"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={device.cpuPercentage ? `${device.cpuPercentage}%` : 'n/a'}
                    label="CPU Load"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={displayTemperature(device.cpuTemperature)}
                    label="CPU Temperature"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={displaySpace(device.diskAvailable, device.diskTotal)}
                    label="Disk"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={displaySpace(device.memoryAvailable, device.memoryTotal)}
                    label="Memory"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard
                    content={displayUptime(device.processUptime, device.osUptime)}
                    label="Uptime"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard content={String(device.eventStream)} label="Event Stream" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard content={String(device.realtime)} label="Real time" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard content={String(device.internal)} label="Internal" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StripedCard content={String(device.demo)} label="Demo" />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={4}>
              <StripedCard
                content={`${String(device.grabInterval)} seconds`}
                label="Update Interval"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard
                content={
                  device.currentChannel
                    ? `${device.currentChannel.number}: ${device.currentChannel.name}`
                    : 'n/a'
                }
                label="Current Channel"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StripedCard content={device.currentView?.name ?? 'n/a'} label="Current View" />
            </Grid>
            {currentNetwork.canManage.value && metadataGrid}
            {/* TODO: don't show for content editor, needs fix */}
            {device.canUpdate.value && (
              <Grid item xs={12}>
                <TagInput
                  taggableId={device.id}
                  taggableType={TaggableType.Device}
                  tags={device.tags.map((x) => x)}
                />
              </Grid>
            )}
          </Grid>
        </SettingsData>
      </SettingsBody>
    </>
  );
};
