import { useCallback } from 'react';
import { Link } from '~/components/link';
import { useLink } from '~/hooks/link';

interface NamedEntity {
  __typename: 'Show' | 'DataSource' | 'Device' | 'DeviceGroupNew' | 'Playlist' | 'User';
  id: number;
  name: string;
}

const pathFor = ({ __typename, id }: NamedEntity): string => {
  if (__typename === 'Show') return `/shows/${id}`;
  if (__typename === 'DataSource') return `/data-sources/${id}`;
  if (__typename === 'Device') return `/devices/${id}`;
  if (__typename === 'DeviceGroupNew') return `/settings/device-groups/${id}`;
  if (__typename === 'Playlist') return `/playlists/${id}`;
  if (__typename === 'User') return `/settings/users/${id}`;

  throw new Error(`Type ${__typename} not supported`);
};

interface AuthorizedLinkProps {
  authorized?: boolean;
  entity: NamedEntity | undefined | null;
  fallback?: string;
}

export const AuthorizedLink = ({
  authorized = false,
  entity,
  fallback = '--',
}: AuthorizedLinkProps) => {
  const link = useLink();

  const handleClick = useCallback((event: React.MouseEvent) => event.stopPropagation(), []); // Prevents table row click propagation

  return entity && authorized ? (
    <Link to={link(pathFor(entity))} onClick={handleClick}>
      {entity.name}
    </Link>
  ) : (
    <span>{entity?.name ?? fallback}</span>
  );
};
