import { ViewComfy } from '@mui/icons-material';
import { object, string } from 'yup';
import { useUpdateLayout } from '~/api/layouts/update';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useLayout } from '../context';

export const EditLayoutToolbar = () => {
  const layout = useLayout();
  const [update] = useUpdateLayout();

  return (
    <Toolbar
      returnTo=".."
      titleIcon={<ViewComfy />}
      titleText={!layout.canUpdate.value && layout.name}
      titleEditor={
        layout.canUpdate.value && (
          <InlineEditTitle
            inputId={`layout-${layout.id}`}
            value={layout.name}
            update={(value: string) =>
              void update({ variables: { layoutId: layout.id, patch: { name: value.trim() } } })
            }
            tooltip="Edit the name of this Layout"
            validationSchema={object({
              name: string().required('Layout name is required').trim(),
            })}
          />
        )
      }
    />
  );
};
