import { useQuery } from '@apollo/client';
import { Delete, Dvr, MoreHoriz } from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { array, number, object, string } from 'yup';
import { useDeleteChannelGuides, useUpdateChannelGuide } from '~/api/channel-guides';
import { IconButton, SaveButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { permissionTooltip } from '~/lib/tooltips';
import { ChannelGuideForm } from '../components';
import { EditChannelGuideDocument } from './EditChannelGuide.generated';

const validationSchema = object({
  channelIds: array(number().integer().min(1).required()).required().min(0),
  contentItemId: number().integer().min(1).required(),
});

export const EditChannelGuide = () => {
  const params = useParams<{ channelGuideId: string }>();
  const channelGuideId = parseInt(params.channelGuideId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();
  const navigate = useNavigate();

  const { data, loading } = useQuery(EditChannelGuideDocument, {
    variables: { channelGuideId, networkId: currentNetwork.id },
  });

  const [updateChannelGuide] = useUpdateChannelGuide();

  const channelGuide = data?.network?.channelGuide;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      channelIds: channelGuide?.channels.map((channel) => channel.id) ?? [],
      contentItemId: channelGuide?.contentItem.id,
    },
    onSubmit: async (values) => {
      const variables = { channelGuideId, ...validationSchema.cast(values) };
      await updateChannelGuide({ variables });
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema,
  });

  const [menuAnchor, setMenuAnchor] = useState<Element>();

  const [confirm, confirmationProps] = useConfirmDialog();
  const [deleteChannelGuides] = useDeleteChannelGuides();

  if (!loading && !data?.network?.channelGuide) return <Navigate replace to={link('/not-found')} />;

  const actions = channelGuide && (
    <Box display="flex" gap={2}>
      <SaveButton
        disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        form="edit-channel-guide-form"
        type="submit"
      />

      <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
        <MoreHoriz fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setMenuAnchor(undefined)}
        open={menuAnchor != null}
      >
        <Tooltip title={permissionTooltip('canDestroy', 'channel guide', channelGuide)}>
          <span>
            <MenuItem
              disabled={!channelGuide.canDestroy.value}
              onClick={async () => {
                setMenuAnchor(undefined);
                if (!(await confirm())) return;
                const { data } = await deleteChannelGuides({
                  variables: { channelGuideIds: channelGuide.id, networkId: currentNetwork.id },
                });
                if (data?.deleteChannelGuides?.deletedIds.length) navigate('..');
              }}
            >
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText primary="Delete" primaryTypographyProps={{ color: 'error' }} />
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>

      <ConfirmDialog
        {...confirmationProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel Guide will remove it from Devices where it is being used."
        title="Delete Channel Guide"
      />
    </Box>
  );

  const titleEditor = (
    <InlineEditTitle
      inputId="edit-channel-guide-name"
      value={channelGuide?.name ?? ''}
      update={async (name) => {
        await updateChannelGuide({ variables: { channelGuideId, name } });
      }}
      tooltip="Edit the name of this channel guide"
      validationSchema={object({ name: string().required().trim().label('Name') })}
    />
  );

  return (
    <>
      <Toolbar actions={actions} returnTo=".." titleIcon={<Dvr />} titleEditor={titleEditor} />
      <ChannelGuideForm formik={formik} id="edit-channel-guide-form" />
    </>
  );
};
