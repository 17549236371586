import { useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { SendDeviceCommandDocument } from './send-command.generated';

export const useSendDeviceCommand = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [mutationFn, mutationResult] = useMutation(SendDeviceCommandDocument);

  const wrappedFn = useCallback<typeof mutationFn>(
    (options) =>
      mutationFn({
        onCompleted: () => {
          enqueueSnackbar(<Typography color="inherit">Command sent</Typography>, {
            variant: 'success',
          });
        },

        onError: (_error: unknown) => {
          const key = uuid();
          enqueueSnackbar(
            <Typography color="inherit">Failed to send command to device</Typography>,
            {
              action: (
                <>
                  <Button color="inherit" onClick={() => window.location.reload()}>
                    Reload
                  </Button>
                  <Button color="inherit" onClick={() => closeSnackbar(key)}>
                    Dismiss
                  </Button>
                </>
              ),
              key,
              persist: true,
              variant: 'error',
            },
          );
        },

        ...options,
      }),
    [closeSnackbar, enqueueSnackbar, mutationFn],
  );

  return [wrappedFn, mutationResult] as const;
};
