import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Autocomplete, Box, Checkbox, MenuItem, TextField } from '@mui/material';
import { getIn, useFormik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { array, boolean, number, object, string } from 'yup';
import { useAssignPropertyGroup, useRemovePropertyGroup } from '~/api/property-groups';
import { useUpdateShowTags } from '~/api/show-tags';
import { useUpdateShow } from '~/api/shows';
import { DetailSelect, DetailSwitch, DetailTextField } from '~/components/forms/details';
import { ChannelGuideSelect } from '~/components/inputs/ChannelGuideSelect';
import { type FormTableData } from '~/components/settings';
import { PropertyOwnerType } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { ShowSettingsProps } from '../components/settings-form';
import { leagues } from './leagues';

const fcPropertyGroups = [{ id: -1, name: 'FanConnect' }];

const fanconnectDefaults = {
  apiKey: '',
  autoschedule: false,
  domain: 'admin.fanconnect.tv',
  leagueName: '',
  subdomain: '',
};

interface PropertyGroupFormData {
  id: number;
  name: string;
  propertyGroupId: number;
  fields: FormTableData[];
}

const stripTypename = <T extends { __typename: string }>(object: T | null | undefined) => {
  if (object == null) return null;
  const { __typename, ...rest } = object;
  return rest;
};

const validationSchema = object({
  channelGuideId: number().nullable(),
  fanconnect: object({
    apiKey: string().label('API Key').required(),
    autoschedule: boolean().label('Autoschedule').required(),
    domain: string().label('Domain').required(),
    leagueName: string()
      .label('League')
      .oneOf(leagues.map(({ value }) => value))
      .required(),
    subdomain: string().label('Subdomain').required(),
  }).nullable(),
  propertyValuesAttributes: array(
    object({
      id: number().required(),
      value: string().nullable(),
    }),
  ),
});

export const useSettingsForm = ({ propertyGroups, show, showTags }: ShowSettingsProps) => {
  const [updateTags] = useUpdateShowTags();
  const [updateShow] = useUpdateShow();

  const [assignPropertyGroup] = useAssignPropertyGroup();
  const [removePropertyGroup] = useRemovePropertyGroup();
  const [confirm, confirmDialogProps] = useConfirmDialog();
  const [showAddPropertyDialog, setShowAddPropertyDialog] = useState(false);

  const initialValues = useMemo(
    () => ({
      channelGuideId: show.channelGuide?.id || -1,
      fanconnect: stripTypename(show.fanconnect),
      propertyValues: show.propertyGroups
        .flatMap((x) => x.propertyValues)
        .map(({ id, propertyDefinition, value }) => ({
          id,
          value: value || propertyDefinition.defaultValue,
        })),
    }),
    [show],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);
      await updateShow({
        variables: {
          showId: show.id,
          patch: {
            ...newValues,
            channelGuideId: newValues.channelGuideId === -1 ? null : newValues.channelGuideId,
          },
        },
      });
    },
    validateOnBlur: true,
    validationSchema,
  });

  const onHandleTagChange = useCallback(
    async (taggableId: number, tags: string[]) => {
      try {
        await updateTags({
          variables: {
            taggableId,
            tags,
          },
        });
      } catch (error) {
        console.error('Failed to update tags:', error);
      }
    },
    [updateTags],
  );

  const handleTagChange = useCallback(
    async (_event: React.SyntheticEvent, newValue: string[]) => {
      try {
        await onHandleTagChange(show.id, newValue);
      } catch (error) {
        console.error('Failed to handle tag change:', error);
      }
    },
    [onHandleTagChange, show.id],
  );

  const generalSettings = useMemo(() => {
    return [
      {
        heading: 'Channel Guide',
        subHeading: 'Channel Guide associated with this Show',
        dataField: (
          <ChannelGuideSelect
            aria-labelledby="channel guide"
            disabled={formik.isSubmitting}
            displayEmpty
            name="channelGuideId"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.channelGuideId}
          />
        ),
      },
      {
        heading: 'Tags',
        subHeading: 'Tags associated with this Show',
        dataField: (
          <Autocomplete
            multiple
            autoHighlight
            disableCloseOnSelect
            options={showTags ?? []}
            value={Array.from(show.tags)}
            onChange={handleTagChange}
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option, { selected }) => (
              <Box component="li" {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </Box>
            )}
            renderInput={(params) => <TextField {...params} label="Select Tags" />}
          />
        ),
      },
    ];
  }, [formik, showTags, show.tags, handleTagChange]);

  const addPropertyGroup = useCallback(
    async ({ id }: { id: string }) => {
      const propertyGroupId = Number(id);

      // Handle FanConnect group
      if (propertyGroupId === -1) {
        await formik.setFieldValue('fanconnect', fanconnectDefaults, true);
        setShowAddPropertyDialog(false);
        return;
      }

      const result = await assignPropertyGroup({
        variables: {
          ownerId: show.id,
          ownerType: PropertyOwnerType.Show,
          propertyGroupId,
        },
      });

      if (!result.errors?.length) {
        setShowAddPropertyDialog(false);
      }
    },
    [assignPropertyGroup, formik, show.id],
  );

  const getPropertyGroupValue = useCallback(
    (id: number) => formik.values.propertyValues.find((x) => x.id === id)?.value,
    [formik],
  );

  const setPropertyGroupValue = useCallback(
    (id: number, value: string) => {
      const index = formik.values.propertyValues.findIndex((x) => x.id === id);
      void formik.setFieldValue(`propertyValues.${index}`, {
        id,
        value: String(value),
      });
    },
    [formik],
  );

  const propertyGroupSettings: PropertyGroupFormData[] = useMemo(
    () =>
      show.propertyGroups.map((pg) => ({
        id: pg.id,
        name: pg.name,
        propertyGroupId: pg.propertyGroupId,
        fields:
          pg.propertyValues.length === 0
            ? [{ heading: `No ${pg.name} settings` }]
            : pg.propertyValues.map(({ id, propertyDefinition }) => ({
                heading: propertyDefinition.label,
                subHeading: propertyDefinition.helpText,
                dataField:
                  propertyDefinition.kind === 'boolean' ? (
                    <DetailSwitch
                      checked={getPropertyGroupValue(id) === 'true'}
                      color="primary"
                      disabled={formik.isSubmitting}
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.checked))}
                    />
                  ) : propertyDefinition.allowedValues != null ? (
                    <DetailSelect
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    >
                      <MenuItem value="">(None)</MenuItem>
                      {propertyDefinition.allowedValues.map(({ label, value }, index) => (
                        <MenuItem key={index} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </DetailSelect>
                  ) : (
                    <DetailTextField
                      onChange={(event) => setPropertyGroupValue(id, String(event.target.value))}
                      value={getPropertyGroupValue(id)}
                    />
                  ),
              })),
      })),
    [show, formik, getPropertyGroupValue, setPropertyGroupValue],
  );

  const deletePropertyGroup = useCallback(
    async (propertyGroup: { propertyGroupId: number }) => {
      if (!(await confirm())) return;
      await removePropertyGroup({
        variables: {
          ownerId: show.id,
          ownerType: PropertyOwnerType.Show,
          propertyGroupId: propertyGroup.propertyGroupId,
        },
      });
    },
    [confirm, show, removePropertyGroup],
  );

  const fanconnectSettings: FormTableData[] = useMemo(
    () => [
      {
        heading: 'Autoschedule',
        subHeading: 'Automate game scheduling',
        dataField: (
          <DetailSwitch
            aria-label="autoselect"
            disabled={formik.isSubmitting}
            name="fanconnect.autoschedule"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            checked={formik.values.fanconnect?.autoschedule}
            color="primary"
            value={true}
          />
        ),
      },
      {
        heading: 'League',
        subHeading: 'Sports league where this Show will be used, e.g. NFL',
        dataField: (
          <DetailSelect
            aria-labelledby="show-settings-league"
            disabled={formik.isSubmitting}
            name="fanconnect.leagueName"
            value={formik.values.fanconnect?.leagueName}
            onChange={formik.handleChange}
          >
            {leagues.map(({ key, value }) => (
              <MenuItem key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </DetailSelect>
        ),
      },
      {
        heading: 'Domain',
        subHeading: 'FanConnect API domain name',
        dataField: (
          <DetailTextField
            aria-label="domain"
            name="fanconnect.domain"
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={String(getIn(formik.values, 'fanconnect.domain'))}
          />
        ),
      },
      {
        heading: 'Subdomain',
        subHeading: 'FanConnect API subdomain',
        dataField: (
          <DetailTextField
            aria-label="subdomain"
            name="fanconnect.subdomain"
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={String(getIn(formik.values, 'fanconnect.subdomain'))}
          />
        ),
      },
      {
        heading: 'API Key',
        subHeading: 'FanConnect account API key',
        dataField: (
          <DetailTextField
            aria-label="api key"
            name="fanconnect.apiKey"
            disabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={String(getIn(formik.values, 'fanconnect.apiKey'))}
          />
        ),
      },
    ],
    [formik],
  );
  const filteredPropertyGroups: ReadonlyArray<{ id: number; name: string }> = [
    ...(formik.values.fanconnect == null ? fcPropertyGroups : []),
    ...propertyGroups.filter(
      ({ id }) => !show.propertyGroups.some(({ propertyGroupId }) => id === propertyGroupId),
    ),
  ];

  return {
    addPropertyGroup,
    confirmDialogProps,
    deletePropertyGroup,
    fanconnectSettings,
    filteredPropertyGroups,
    formik,
    generalSettings,
    propertyGroupSettings,
    setShowAddPropertyDialog,
    showAddPropertyDialog,
  };
};
