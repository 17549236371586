import { DragIndicator, LinkOff, RemoveCircle } from '@mui/icons-material';
import { Box, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { type GridColDef, type GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { config, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useReorderPlaylist } from '~/api/playlists';
import { TableAction } from '~/components/table';
import { assert } from '~/lib/assert';
import { ROW_TOTAL_HEIGHT } from '../data-grid';
import { DurationCell, ExpiresCell, ExpiresHeader, ThumbnailCell } from './cells';
import { type PlaylistDesign__PlaylistItem as PlaylistItem } from './index.generated';

interface CalculateStylesProps {
  active?: boolean;
  currentIndex?: number;
  immediate?: boolean;
  order: number[];
  originalIndex?: number;
  y?: number;
}

const calculateStyles =
  ({
    active = false,
    currentIndex = 0,
    immediate = false,
    order,
    originalIndex = 0,
    y = 0,
  }: CalculateStylesProps) =>
  (index: number) =>
    active && index === originalIndex
      ? {
          y: currentIndex * ROW_TOTAL_HEIGHT + y,
          zIndex: 1,
          immediate: (key: string) => immediate || key === 'zIndex',
          config: (key: string) => (key === 'y' ? config.stiff : config.default),
        }
      : {
          y: order.indexOf(index) * ROW_TOTAL_HEIGHT,
          zIndex: 0,
          immediate,
        };

export const useReorder = (
  playlistId: number,
  items: ReadonlyArray<PlaylistItem>,
  onRemoveContent: (playlistItemId: number) => void,
  dragEnabled = true,
) => {
  const [reorder] = useReorderPlaylist();
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      reorder: !isSmallAndDown,
      thumbnail: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const order = useRef(items.map((_, index) => index));

  const [springs, api] = useSprings(items.length, calculateStyles({ order: order.current }));

  useEffect(() => {
    order.current = items.map((_, index) => index);
    api.start(calculateStyles({ immediate: true, order: order.current }));
  }, [api, items]);

  const bind = useDrag(
    ({ active, args: [originalIndex], movement: [, y] }) => {
      assert(typeof originalIndex === 'number');

      const currentIndex = order.current.indexOf(originalIndex);
      const approximateRow = Math.round((currentIndex * ROW_TOTAL_HEIGHT + y) / ROW_TOTAL_HEIGHT);

      const moveToIndex =
        approximateRow < 0
          ? 0
          : approximateRow > items.length - 1
          ? items.length - 1
          : approximateRow;

      const newOrder = [...order.current];
      newOrder.splice(moveToIndex, 0, newOrder.splice(currentIndex, 1)[0]);

      api.start(
        calculateStyles({
          active,
          currentIndex,
          order: newOrder,
          originalIndex,
          y,
        }),
      );

      if (!active) {
        order.current = newOrder;
        void reorder({
          variables: {
            playlistId,
            playlistItemIds: newOrder.map((x) => items[x].id),
          },
        });
      }
    },
    { filterTaps: true },
  );

  const columns: GridColDef<PlaylistItem>[] = useMemo(
    () => [
      {
        field: 'reorder',
        headerName: '',
        width: 25,
        renderCell: ({ api: { getRowIndexRelativeToVisibleRows }, rowNode }) =>
          dragEnabled ? (
            <Box
              {...bind(getRowIndexRelativeToVisibleRows(rowNode.id))}
              sx={{ display: 'flex', '&:hover': { cursor: 'grab' } }}
            >
              <DragIndicator color="secondary" />
            </Box>
          ) : null,
        sortable: false,
      },
      {
        field: 'thumbnail',
        renderCell: ThumbnailCell,
        sortable: false,
        maxWidth: 150,
      },
      {
        field: 'name',
        flex: 2,
        sortable: false,
        valueGetter: (_value, row) => row.contentItem.name,
      },
      {
        field: 'id',
        headerName: '',
        sortable: false,
        renderCell: ({ row }) => {
          return (
            row.contentItem.origin && (
              <Tooltip
                title={`The content is detached from its original association with the App '${row.contentItem.name}'`}
              >
                <Chip color="primary" icon={<LinkOff />} label="Detached" />
              </Tooltip>
            )
          );
        },
        minWidth: 150,
      },
      {
        align: 'center',
        display: 'flex',
        field: 'duration',
        headerAlign: 'center',
        renderCell: DurationCell,
        sortable: false,
        maxWidth: 100,
      },
      {
        field: 'expires',
        width: 240,
        renderCell: ExpiresCell,
        renderHeader: ExpiresHeader,
        sortable: false,
      },
      {
        align: 'right',
        field: 'actions',
        getActions: ({ id }) => [
          <TableAction
            color="error"
            key={id}
            title="Remove"
            Icon={RemoveCircle}
            onClick={() => onRemoveContent(Number(id))}
          />,
        ],
        sortable: false,
        type: 'actions',
      },
    ],
    [bind, dragEnabled, onRemoveContent],
  );

  return {
    columns,
    showColumns,
    springs,
  };
};
