import { Menu as Hamburger } from '@mui/icons-material';
import { AppBar, IconButton, Link, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRef, useState } from 'react';
import { UserAvatar } from '~/components/avatar';
import { useAppContext } from '~/contexts';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  logo: {
    height: 30,
    lineHeight: 1,
    '& > *': {
      height: '100%',
    },
  },
  grow: {
    flexGrow: 1,
  },
  iconButton: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  menuButton: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
  userInfo: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  userInfoDetail: {
    paddingLeft: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '10rem',
    maxWidth: '15rem',
  },
  userInfoSubtext: {
    fontSize: '11px',
    color: '#58585C',
    paddingBottom: theme.spacing(1),
  },
  menu: {
    padding: theme.spacing(0),
  },
  menuHeading: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 600,
    color: '#000000',
  },
  menuItem: {},
}));

export interface TopbarProps {
  logo: string;
  toggleSidebar: () => void;
}

export const Topbar = ({ logo, toggleSidebar }: TopbarProps) => {
  const classes = useStyles();

  const { currentUser, impersonating } = useAppContext();

  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const userMenuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const handleUserMenuToggle = () => setUserMenuOpen((prevOpen) => !prevOpen);

  return (
    <AppBar position="fixed" className={classes.appBar} sx={{ top: impersonating ? '50px' : '0' }}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleSidebar}
          className={classes.menuButton}
          size="large"
        >
          <Hamburger />
        </IconButton>

        <Link href="/dashboard" className={classes.logo}>
          <img src={logo} alt="FanConnect Content Manager" />
        </Link>

        <div className={classes.grow} />

        <IconButton
          ref={userMenuAnchorRef}
          className={classes.iconButton}
          aria-controls={userMenuOpen ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          color="inherit"
          size="small"
          onClick={handleUserMenuToggle}
        >
          <UserAvatar user={currentUser} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
