import { styled } from '@mui/styles';
import { NetworkMenu } from './network-menu';

const Root = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  alignItems: 'center',
  borderBottom: '1px solid #e8e8e8',
  display: 'flex',
  flexDirection: 'row',
  height: theme.spacing(8),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '48px',
  },
}));

export const Contextbar = () => {
  return (
    <Root>
      <NetworkMenu />
    </Root>
  );
};
