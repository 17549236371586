import { PhotoCamera } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useSendDeviceCommand } from '~/api/devices';
import { Screenshot as DeviceScreenshot } from '~/components/devices';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { DeviceCommandKind } from '~/generated/graphql';
import { useDevice } from '../context';

export const Screenshot = () => {
  const { device } = useDevice();
  const [sendCommand, { loading }] = useSendDeviceCommand();

  const handleClick = useCallback(async () => {
    await sendCommand({
      variables: {
        command: DeviceCommandKind.Screenshot,
        deviceIds: [device.id],
        payload: {},
      },
    });
  }, [device, sendCommand]);

  return (
    <>
      <SettingsHead>Screenshot</SettingsHead>
      <SettingsBody>
        <SettingsData className="last" sx={{ flexDirection: 'column', gap: 1 }}>
          <DeviceScreenshot pollInterval={60_000} screenshotUri={device.screenshotUri} />
          {/* TODO: don't show for content editor, needs fix */}
          {device.canUpdate.value && (
            <Button
              disabled={loading}
              onClick={async () => await handleClick()}
              startIcon={<PhotoCamera />}
              variant="contained"
            >
              Take Screenshot
            </Button>
          )}
        </SettingsData>
      </SettingsBody>
    </>
  );
};
