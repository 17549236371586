import { ClickAwayListener } from '@mui/base';
import { Apps, PersonAdd, QueuePlayNext } from '@mui/icons-material';
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { MutableRefObject } from 'react';
import * as React from 'react';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';

const useStyles = makeStyles((theme) => ({
  menu: {
    padding: theme.spacing(0),
  },
  menuHeading: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
    fontSize: '11px',
    fontWeight: 600,
    color: '#000000',
  },
}));

export interface QuickActionsPopoverProps {
  anchorEl: MutableRefObject<HTMLButtonElement | null>;
  isOpen: boolean;
  onCloseMenu: (
    event: MouseEvent | TouchEvent | React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => void;
}

export const QuickActionsPopover = ({
  anchorEl,
  isOpen,
  onCloseMenu,
}: QuickActionsPopoverProps) => {
  const { currentNetwork, currentUser } = useAppContext();
  const classes = useStyles();

  const link = useLink();

  return (
    <div>
      <Popover
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disablePortal
        TransitionComponent={Collapse}
        slotProps={{
          paper: {
            style: {
              maxWidth: 'calc(240px - 32px)',
              width: 'calc(240px - 32px)',
            },
          },
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={onCloseMenu}>
            <div>
              <MenuList autoFocusItem={isOpen} className={classes.menu}>
                {currentNetwork.canManage.value && (
                  <MenuItem component={Link} to={link('/devices/activate')}>
                    <ListItemIcon>
                      <QueuePlayNext />
                    </ListItemIcon>
                    <ListItemText>Activate Device</ListItemText>
                  </MenuItem>
                )}

                {currentUser.admin && (
                  <MenuItem component={Link} to={link('/templates')}>
                    <ListItemIcon>
                      <Apps />
                    </ListItemIcon>
                    <ListItemText>Create Apps</ListItemText>
                  </MenuItem>
                )}

                {currentNetwork.canManage.value && (
                  <MenuItem component={Link} to={link('/settings/users/new')}>
                    <ListItemIcon>
                      <PersonAdd />
                    </ListItemIcon>
                    <ListItemText>Create User</ListItemText>
                  </MenuItem>
                )}
              </MenuList>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </div>
  );
};
