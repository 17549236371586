import {
  useMutation,
  type MutationHookOptions,
  type MutationTuple,
  type TypedDocumentNode,
} from '@apollo/client';
import { ContentShow__ContentItem } from '~/components/app-form/gql/show.generated';
import { useNotifications, type ApiOptions } from '../helpers';
import { RenameContentItemDocument, UpdateContentItemDocument } from './update.generated';

export { RenameContentItemDocument, UpdateContentItemDocument };

const useUpdate = <T, U>(
  document: TypedDocumentNode<T, U>,
  options: MutationHookOptions<T, U>,
): MutationTuple<T, U> =>
  useMutation(document, {
    ...useNotifications(document, {
      failure: 'Failed to update app',
      success: 'Updated app',
    }),
    ...options,
  });

export const useUpdateContentItem = (options: ApiOptions<typeof UpdateContentItemDocument> = {}) =>
  useUpdate(UpdateContentItemDocument, {
    // we need to manually set the new thumbnailUri
    // because what would be returned from the server
    // would be incorrect until the thumbnail job has processed
    update: (cache, _, { variables }) => {
      const id = variables?.contentItemId;
      const patch = variables?.patch;
      if (!id || !patch?.thumbnailUri) return;

      const cachedItem = cache.readFragment({
        id: cache.identify({ __typename: 'ContentItem', id }),
        fragment: ContentShow__ContentItem,
        fragmentName: 'ContentShow__ContentItem',
      });

      if (!cachedItem) return;

      cache.writeFragment({
        id: cache.identify({ __typename: 'ContentItem', id }),
        fragment: ContentShow__ContentItem,
        fragmentName: 'ContentShow__ContentItem',
        data: {
          ...cachedItem,
          thumbnailUri: patch.thumbnailUri,
        },
      });
    },
    ...options,
  });

export const useRenameContentItem = (options: ApiOptions<typeof RenameContentItemDocument> = {}) =>
  useUpdate(RenameContentItemDocument, options);
