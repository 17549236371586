import { Box, Hidden } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ImpersonationBanner } from '~/components/ImpersonationBanner';
import { UploadDialog } from '~/components/UploadDialog';
import { UploadSnackbar } from '~/components/UploadSnackbar';
import { LoadingPane } from '~/components/loading-pane';
import { AppContextProvider, useFetchAppContext } from '~/contexts/app';
import { UploadProvider } from '~/contexts/upload';
import logo from '~/images/fc-logo-alt.svg';
import { Contextbar, Sidebar, Topbar } from './components';

export const NewLayout = () => {
  const { currentNetwork, currentUser, impersonating, trueUser } = useFetchAppContext();
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (!currentUser || !trueUser) return;

    Sentry.setUser({ email: trueUser.email });
    Sentry.setContext(
      'impersonated_user',
      currentUser.id === trueUser.id ? null : { email: currentUser.email },
    );
  }, [currentUser, trueUser]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  if (!currentUser || !currentNetwork || !trueUser) {
    return <LoadingPane in />;
  }

  return (
    <AppContextProvider
      currentUser={currentUser}
      currentNetwork={currentNetwork}
      impersonating={impersonating ?? false}
      trueUser={trueUser}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UploadProvider>
          <ImpersonationBanner />
          <Box
            sx={{
              display: 'flex',
              height: impersonating ? 'calc(100% - 50px)' : '100%',
              marginTop: impersonating ? '50px' : '0px',
            }}
          >
            <Hidden smUp implementation="css">
              <Topbar logo={logo} toggleSidebar={handleDrawerToggle} />
            </Hidden>

            <Hidden smDown implementation="css">
              <Sidebar logo={logo} open={mobileOpen} toggleSidebar={handleDrawerToggle} />
            </Hidden>

            <Box
              component="main"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              <Contextbar />
              <Outlet />
            </Box>
          </Box>

          <UploadDialog />
          <UploadSnackbar />
        </UploadProvider>
      </LocalizationProvider>
    </AppContextProvider>
  );
};
