import { useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Count } from '~/components/count';
import { FolderBreadcrumbs } from '~/components/folder-breadcrumbs';
import { SearchBar } from '~/components/forms/search-bar';
import { useAppContext, useContentFolderContext } from '~/contexts';
import { useUploadDispatch } from '~/contexts/upload';
import { type MediaKind } from '~/generated/graphql';
import { usePollInterval } from '~/hooks/polling';
import { MediaSelectDocument } from '../index.generated';
import { ActionsContainer, FolderCard, MediaItemCard, UploadButton } from './';

export interface MediaGridProps {
  aspectRatio?: number;
  kind: MediaKind | 'MEDIA';
  onClick: (id: number, kind: 'App' | 'Media') => void;
  selectedIds: number[];
}

export const MediaGrid = ({ aspectRatio, kind, onClick, selectedIds }: MediaGridProps) => {
  const { currentNetwork } = useAppContext();
  const { contentFolderTree } = useContentFolderContext();

  const [search, setSearch] = useState('');

  const uploadDispatch = useUploadDispatch();
  const { pollInterval, setFastPolling } = usePollInterval(0, 1_000);

  const [contentFolderId, setContentFolderId] = useState(contentFolderTree.id);

  const { data, startPolling, stopPolling } = useQuery(MediaSelectDocument, {
    variables: {
      aspectRatio,
      kind: kind === 'MEDIA' ? undefined : kind,
      contentFolderId,
      networkId: currentNetwork.id,
      search,
    },
  });

  useEffect(() => {
    startPolling(pollInterval);
    return () => stopPolling();
  }, [pollInterval, startPolling, stopPolling]);

  useEffect(() => {
    const listener = () => setFastPolling(30);
    uploadDispatch({ type: 'addListener', listener });
    return () => uploadDispatch({ type: 'removeListener', listener });
  }, [setFastPolling, uploadDispatch]);

  const contentFolder = data?.network?.contentFolder;

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <SearchBar onChange={setSearch} search={search} placeholder="Search" />
        <UploadButton disabled={!contentFolder?.canUpdate.value} folderId={contentFolderId} />
      </Box>

      <ActionsContainer>
        {contentFolder && (
          <FolderBreadcrumbs
            allContent={() => setContentFolderId(contentFolderTree.id)}
            changeFolder={(id) => setContentFolderId(id)}
            contentFolder={contentFolder}
          />
        )}

        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: 1,
            }}
          >
            <Count
              selectedCount={0}
              totalCount={data?.network?.contentFolder.children.length || 0}
              thing="folder"
            />
            <Typography variant="h6">,</Typography>
          </Box>
          <Count
            selectedCount={selectedIds.length}
            totalCount={data?.network?.contentFolder.mediaItems.length || 0}
            thing="item"
          />
        </Box>
      </ActionsContainer>

      <Grid
        sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 270px)' }}
        container
        spacing={3}
        mt={0}
        pb={1}
      >
        {contentFolder?.children.map((folder) => (
          <FolderCard
            key={folder.id}
            folder={folder}
            onClick={() => setContentFolderId(folder.id)}
          />
        ))}
        {contentFolder?.mediaItems.map((mediaItem) => (
          <MediaItemCard
            key={mediaItem.id}
            mediaItem={mediaItem}
            onClick={() => onClick(mediaItem.id, 'Media')}
            selected={selectedIds.includes(mediaItem.id)}
          />
        ))}
      </Grid>
    </Box>
  );
};
