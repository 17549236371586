import { Image } from '@mui/icons-material';
import { Box, Button, CardMedia, styled, TextField, Typography } from '@mui/material';
import { type FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import { TagList } from '~/components/tag';
import { formatDate, formatInterval } from '~/lib/datetime';
import { toDataUri } from '~/lib/file';
import { type EditorUserApp, type FormValues } from './form-helpers';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2),
}));

interface Context {
  editThumbnail: boolean;
  formik: FormikProps<FormValues>;
  userApp: EditorUserApp | null | undefined;
}

const DetailSection = ({
  title,
  value,
}: {
  title: string | null;
  value: string | null | undefined;
}) => (
  <Container>
    <Typography variant="h5" component="h2" fontWeight={500}>
      {title}
    </Typography>
    <Typography variant="h5" component="h5" fontWeight={500}>
      {value}
    </Typography>
  </Container>
);

export const AppDetailsPanelModal = ({ editThumbnail, formik, userApp }: Context) => {
  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1 || !userApp?.id) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  return (
    <Box>
      {userApp?.id && (
        <>
          <Container>
            <Typography variant="h5" component="h2" fontWeight={500}>
              Description
            </Typography>
            <TextField
              fullWidth
              multiline
              name="description"
              onChange={formik.handleChange}
              rows={4}
              sx={{ maxWidth: '300px', ml: 1 }}
              value={formik.values.description || ''}
            />
          </Container>
          <Container>
            <Typography variant="h5" component="h2" fontWeight={500}>
              Thumbnail
            </Typography>
            <Box sx={{ maxWidth: '200px' }}>
              {userApp.thumbnailUri || formik.values.thumbnailUri ? (
                <CardMedia
                  alt="thumbnail"
                  image={userApp.thumbnailUri || formik.values.thumbnailUri}
                  crossOrigin="anonymous"
                  component="img"
                />
              ) : (
                'No thumbnail'
              )}
            </Box>
            <input {...getInputProps()} />
          </Container>
          {editThumbnail && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, paddingX: 2.5 }}>
              <Button
                color="primary"
                onClick={selectFile}
                startIcon={<Image />}
                variant="contained"
              >
                Update
              </Button>
            </Box>
          )}
        </>
      )}
      <DetailSection
        title="Template"
        value={`${userApp?.template.name} ${userApp?.templateVersion.version}`}
      />
      <DetailSection
        title="Default Duration"
        value={`${
          userApp?.templateVersion.defaultDuration
            ? formatInterval(userApp.templateVersion.defaultDuration)
            : userApp?.defaultDuration
            ? formatInterval(userApp.defaultDuration)
            : 'N/A'
        }`}
      />

      {userApp?.id && <DetailSection title="ID" value={userApp.id.toString()} />}

      {userApp?.overlay && (
        <DetailSection title="Overlay ID" value={userApp.overlay.id.toString()} />
      )}

      {userApp?.createdAt && (
        <DetailSection title="Created" value={formatDate(userApp.createdAt)} />
      )}

      {userApp?.updatedAt && (
        <DetailSection title="Updated" value={formatDate(userApp.updatedAt)} />
      )}

      {userApp?.id && userApp.tags && userApp.tags.length > 0 && (
        <Container>
          <Typography variant="h5" component="h2" fontWeight={500}>
            Tags
          </Typography>
          <TagList item={{ __typename: 'ContentItem', id: userApp.id }} tags={userApp.tags} />
        </Container>
      )}
    </Box>
  );
};
