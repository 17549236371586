import { useQuery } from '@apollo/client';
import { AddCircle, VerifiedUser } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { type GridRowId, type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { RoleTabs } from '../components';
import { RoleDataSourceListDocument } from './RoleDataSourceList.generated';
import { AddDataSourcesDialog, BulkActionsMenu, DataSourceTable } from './components';

export const RoleDataSourceList = () => {
  const params = useParams<{ roleId: string }>();
  const roleId = parseInt(params.roleId ?? '');

  const link = useLink();

  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { data, loading, error } = useQuery(RoleDataSourceListDocument, {
    variables: { networkId: currentNetwork.id, roleId, search },
  });

  const [addOpen, setAddOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (!data?.network?.role.dataSourceRules) return;
    const ruleIds = new Set<GridRowId>(data.network.role.dataSourceRules.map((x) => x.id));
    if (selectedIds.every((id) => ruleIds.has(id))) return;
    setSelectedIds(selectedIds.filter((id) => ruleIds.has(id)));
  }, [data?.network?.role.dataSourceRules, selectedIds]);

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  const addDataSourcesButton = (
    <Button
      color="primary"
      onClick={() => setAddOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      Add Data Sources
    </Button>
  );

  const rules = data?.network?.role.dataSourceRules ?? [];

  return (
    <>
      {data?.network && (
        <>
          <Toolbar
            actions={addDataSourcesButton}
            breadcrumbsLabel={<RouterBreadcrumbs />}
            titleIcon={<VerifiedUser />}
            titleText={data.network.role.name}
            returnTo={link('/settings/roles')}
          />

          <RoleTabs current="Data Sources" />

          <PageContainer>
            <SearchContainer>
              <SearchBar placeholder="Search Data Sources" search={search} />
              <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                <BulkActionsMenu role={data.network.role} selectedIds={selectedIds} />
                <Count
                  selectedCount={selectedIds.length}
                  totalCount={rules.length}
                  thing="data source"
                />
              </Box>
            </SearchContainer>
            <DataSourceTable
              checkboxSelection={rules.length > 1}
              disableRowSelectionOnClick={rules.length < 2}
              loading={loading}
              onRowSelectionModelChange={setSelectedIds}
              role={data.network.role}
              rows={data.network.role.dataSourceRules}
              rowSelectionModel={selectedIds}
            />
          </PageContainer>

          {addOpen && (
            <AddDataSourcesDialog
              fullWidth
              onClose={() => setAddOpen(false)}
              open
              role={data.network.role}
              dataSources={data.network.dataSources.nodes}
            />
          )}
        </>
      )}
    </>
  );
};
