import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { MediaItemDetailsDocument } from './MediaItemDetails.generated';
import { MediaItemInfo } from './components/MediaItemInfo';
import { MediaItemPreview } from './components/MediaItemPreview';

export const MediaItemDetails = () => {
  const params = useParams<{ contentFolderId: string; mediaItemId: string }>();
  const contentFolderId = parseInt(params.contentFolderId ?? '');
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(MediaItemDetailsDocument, {
    fetchPolicy: 'cache-first',
    variables: { contentFolderId, mediaItemId, networkId: currentNetwork.id },
  });

  if ((!loading && !data) || error) return <Navigate to="/not-found" />;

  const contentFolder = data?.network?.contentFolder;
  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {contentFolder && mediaItem && (
        <>
          <MediaItemInfo contentFolder={contentFolder} mediaItem={mediaItem} />
          <MediaItemPreview mediaItem={mediaItem} />
        </>
      )}
    </LoadingPane>
  );
};
