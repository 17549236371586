import { useQuery } from '@apollo/client';
import { AddCircle, FilterList, OndemandVideo, Sell } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { NewPlaylistDialog } from '../components/new-dialog';
import { PlaylistListDocument } from '../queries/queries.generated';
import { BulkActions, Filter, Table, TagsDialog } from './components/';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { PlaylistActions, useFilterParams } from './lib/';

export const PlaylistList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [playlistManageTags, setManageTags] = useState(false);

  const { tagsParams: tags } = useFilterParams();
  const [playlistFilters, setPlaylistFilters] = useState(tags.length > 0);

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(PlaylistListDocument, {
    variables: { currentNetworkId: currentNetwork.id, page, perPage, search, tags },
  });

  const playlists = data?.network?.playlists.nodes ?? [];

  const [newPlaylistOpen, setNewPlaylistOpen] = useState(false);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [tags]);

  const canDestroySelectedPlaylists = selectedIds.every((selectedId) => {
    const playlist = playlists.find((playlist) => playlist.id === selectedId);
    return playlist?.canDestroy.value;
  });

  const totalCount = useTotalCount(data?.network?.playlists.pageInfo.nodesCount);

  const newPlaylistButton = (
    <Button
      color="primary"
      disabled={!currentNetwork.canCreatePlaylists.value}
      onClick={() => setNewPlaylistOpen(true)}
      startIcon={<AddCircle />}
      variant="contained"
    >
      New Playlist
    </Button>
  );

  return (
    <>
      <Helmet title="Playlists" />
      <Toolbar
        titleIcon={<OndemandVideo />}
        titleText="Playlists"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTags(!playlistManageTags)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            {currentNetwork.canManage.value && newPlaylistButton}
          </Box>
        }
      ></Toolbar>

      <PageContainer>
        <>
          <PlaylistActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar
                onChange={(value) =>
                  setSearchParams((params) => {
                    params.set('search', value);
                    params.set('page', '1');
                    return params;
                  })
                }
                placeholder="Search Playlists"
                search={search}
              />
              <Hidden smDown>
                <Button
                  onClick={() => setPlaylistFilters(() => !playlistFilters)}
                  startIcon={<FilterList />}
                  variant="outlined"
                >
                  Filter
                </Button>
              </Hidden>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIds.length > 0 ? (
                <BulkActions
                  selectedIds={selectedIds}
                  onTagContent={() => setTagContentDialogOpen(true)}
                  canDestroySelectedPlaylists={canDestroySelectedPlaylists}
                />
              ) : null}
              <Count selectedCount={selectedIds.length} totalCount={totalCount} thing="playlist" />
            </Box>
          </PlaylistActions>
          {playlistFilters && (
            <Hidden smDown>
              <Filter />
            </Hidden>
          )}
          <Table
            playlists={data?.network?.playlists.nodes ?? []}
            loading={loading}
            onCheck={setSelectedIds}
            selectedIds={selectedIds}
            totalCount={totalCount}
          />
        </>
        {playlistManageTags && <TagsDialog onClose={() => setManageTags(false)} open />}

        {tagContentDialogOpen && (
          <BulkTagDialog
            playlistIds={selectedIds as string[]}
            playlistTags={data?.network?.playlistTags.map((x) => x.name) || []}
            onClose={() => setTagContentDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}
      </PageContainer>
      {newPlaylistOpen && (
        <NewPlaylistDialog fullWidth onClose={() => setNewPlaylistOpen(false)} open />
      )}
    </>
  );
};
