import { useLazyQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/contentItem.html?url';
import {
  Apps,
  Clear,
  ContentCopy,
  MoreHoriz,
  Save,
  ScreenshotMonitor,
  Update,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  DialogContent as MuiDialogContent,
  Tab,
  Tooltip,
  styled,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useDeleteContent } from '~/api/content';
import {
  useCopyContentItem,
  useRenameContentItem,
  useUpgradeContentItem,
} from '~/api/content-items';
import { IconButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { InfoTabs } from '~/components/info-pane';
import { Preview } from '~/components/preview';
import { MoreAction, MoreDeleteAction } from '~/components/table';
import { InlineEditTitle } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { useMoreActions } from '~/hooks/table';
import { AppDetailsPanelModal } from './details-panel';
import {
  buildValidationSchema,
  initialValues,
  serializeValues,
  type AppPayload,
  type EditorUserApp,
} from './form-helpers';
import { NewAppDocument } from './gql/new.generated';
import {
  ContentShowDocument,
  type ContentShow__ContentItem as UserApp,
} from './gql/show.generated';
import { ParamGroups } from './param-group';
import { useAppPreview } from './use-app-preview';

const AppTitle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(2.4),
}));

const AppActions = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(2.4),
}));

const AppImage = styled('img')(({ theme }) => ({
  width: '50px',
  objectFit: 'cover',
  height: '50px',
  borderRadius: theme.spacing(1.5),
}));

const AppCard = styled('div')(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.primary.dark,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
}));

const DialogContent = styled(MuiDialogContent)(() => ({
  background: '#F8F8F8',
  padding: 0,
  overflow: 'hidden',
}));

const LeftSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflowY: 'hidden',
  background: theme.palette.common.white,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const AppTabContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100vh - 131px)',
}));

export interface AppFormSubmitProps {
  patch: AppPayload;
  name?: string;
  templateVersionId?: number;
}

export interface AppFormProps {
  autoSaveName?: boolean;
  displayActions?: boolean;
  editThumbnail?: boolean;
  getApp?: boolean;
  id: number;
  onCapture?: () => Promise<void>;
  onSubmit: (props: AppFormSubmitProps) => Promise<void>;
}

export const AppForm = ({
  autoSaveName = true,
  displayActions = true,
  editThumbnail = true,
  getApp = false,
  id,
  onCapture,
  onSubmit,
}: AppFormProps) => {
  // hooks
  const navigate = useNavigate();
  const link = useLink();

  const { currentNetwork, impersonating } = useAppContext();
  const [deleteConfirm, deleteConfirmDialogProps] = useConfirmDialog();
  const [upgradeConfirm, upgradeConfirmDialogProps] = useConfirmDialog();

  const [tabIndex, setTabIndex] = useState(1);
  const [screenshotting, setScreenshotting] = useState(false);

  const [getContentShow, editQuery] = useLazyQuery(ContentShowDocument);
  const [getNewApp, newQuery] = useLazyQuery(NewAppDocument);

  const [upgradeApp] = useUpgradeContentItem();
  const [deleteContent] = useDeleteContent();
  const [renameApp] = useRenameContentItem();
  const [copyApp] = useCopyContentItem();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<UserApp>();

  const contentItem: EditorUserApp | undefined =
    (getApp ? newQuery.data?.contentItem : editQuery.data?.contentItem) ?? undefined;

  const [appName, setAppName] = useState(contentItem?.name ?? '');
  useEffect(() => setAppName(contentItem?.name ?? ''), [contentItem?.name]);

  const validationSchema = useMemo(() => buildValidationSchema(contentItem), [contentItem]);

  const formik = useFormik({
    initialValues: initialValues(contentItem),
    onSubmit: async (values) => {
      const patch = serializeValues(validationSchema.cast(values));
      await onSubmit({ name: appName, patch, templateVersionId: contentItem?.templateVersion.id });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema,
  });

  const { aspectRatio, payload } = useAppPreview(formik.values, contentItem?.templateVersion.id);

  useEffect(() => {
    if (getApp) return void getNewApp({ variables: { templateId: id } });

    void getContentShow({ variables: { contentItemId: id } });
  }, [currentNetwork.id, getApp, getContentShow, getNewApp, id]);

  const saveDisabled = useMemo(() => {
    if (!autoSaveName && appName !== contentItem?.name) return false;
    return formik.isSubmitting || !formik.isValid || !(getApp || formik.dirty);
  }, [appName, autoSaveName, contentItem, formik, getApp]);

  // end hooks

  if (
    (getApp && newQuery.called && !newQuery.loading && newQuery.data?.contentItem == null) ||
    (editQuery.called && !editQuery.loading && editQuery.data?.contentItem == null)
  )
    return <Navigate replace to={link('/not-found')} />;

  const onUpgradeApp = async () => {
    if (!(await upgradeConfirm())) return;
    await upgradeApp({ variables: { contentItemId: id } });
  };

  const onCopyApp = async () => {
    const { data } = await copyApp({ variables: { contentItemId: id } });
    const contentItemId = data?.copyContentItem?.contentItem?.id;
    if (contentItemId != null) navigate(`../../${contentItemId}/details`, { relative: 'path' });
  };

  const onDeleteApp = async () => {
    if (!(await deleteConfirm())) return;
    const { data } = await deleteContent({
      variables: { items: [{ contentableId: id, contentableType: 'APP' }] },
    });
    if (data?.deleteContent?.deletedItems.length) navigate('../..');
  };

  const updateName = async (value: string) => {
    setAppName(value);
    if (getApp || !autoSaveName) return;
    await renameApp({ variables: { contentItemId: id, name: value } });
  };

  const handleClose = () => {
    history.length > 1 ? navigate(-1) : navigate('..');
  };

  const MenuSection = () => (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...moreMenuProps}
    >
      <MoreAction
        {...moreActionProps}
        Icon={Update}
        disabled={!editQuery.data?.contentItem?.canUpgrade.value}
        title="Upgrade"
        onClick={() => {
          moreMenuProps.onClose();
          void onUpgradeApp();
        }}
      />
      <MenuItem
        onClick={() => {
          void onCopyApp();
          moreMenuProps.onClose();
        }}
      >
        <ListItemIcon>
          <ContentCopy />
        </ListItemIcon>
        <ListItemText>Duplicate</ListItemText>
      </MenuItem>

      <Divider />
      <MoreDeleteAction
        {...moreActionProps}
        onClick={() => {
          void onDeleteApp();
          moreMenuProps.onClose();
        }}
      />
    </Menu>
  );

  return (
    <Dialog
      aria-labelledby="edit-dialog-title"
      fullScreen
      maxWidth="lg"
      onClose={handleClose}
      open
      sx={{ top: impersonating ? '50px' : '0' }}
    >
      <DialogTitle id="edit-dialog-title">
        <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
          <AppTitle item sx={{ flex: 1 }}>
            {editQuery.data?.contentItem?.thumbnailUri ? (
              <AppImage
                src={editQuery.data.contentItem.thumbnailUri}
                alt={editQuery.data.contentItem.name}
              />
            ) : (
              <AppCard>
                <Apps />
              </AppCard>
            )}
            <InlineEditTitle
              inputId="app-name"
              value={appName}
              update={updateName}
              tooltip="Edit the name of this App"
              validationSchema={object({
                name: string().required('App name is required').trim(),
              })}
            />
          </AppTitle>
          <AppActions item>
            <LoadingButton
              loadingPosition="start"
              startIcon={<Save />}
              color="primary"
              variant="contained"
              size="large"
              disabled={saveDisabled}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </LoadingButton>
            {onCapture && 'chrome' in window && (
              <Tooltip title="Generate thumbnail from the preview for this app">
                <LoadingButton
                  loading={screenshotting}
                  loadingPosition="start"
                  startIcon={<ScreenshotMonitor />}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={async () => {
                    setScreenshotting(() => true);
                    await onCapture();
                    setScreenshotting(() => false);
                  }}
                >
                  Thumbnail
                </LoadingButton>
              </Tooltip>
            )}
            {displayActions && (
              <Tooltip arrow title="More Actions" aria-label="more actions">
                <IconButton
                  onClick={
                    editQuery.data?.contentItem
                      ? moreTableActionProps(editQuery.data.contentItem).onClick
                      : undefined
                  }
                  size="large"
                  variant="outlined"
                >
                  <MoreHoriz />
                </IconButton>
              </Tooltip>
            )}

            <Divider orientation="vertical" flexItem />

            <Tooltip arrow title="Close" aria-label="close">
              <IconButton size="large" variant="outlined" onClick={handleClose}>
                <Clear />
              </IconButton>
            </Tooltip>
          </AppActions>
        </Grid>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LeftSection>
              <InfoTabs
                aria-label="content info panel tabs"
                onChange={(_, index: number) => setTabIndex(index)}
                value={tabIndex}
                variant="fullWidth"
              >
                <Tab label="Details"></Tab>
                <Tab label="Settings"></Tab>
              </InfoTabs>
              <AppTabContent>
                {tabIndex === 0 && contentItem && (
                  <AppDetailsPanelModal
                    editThumbnail={editThumbnail}
                    formik={formik}
                    userApp={contentItem}
                  />
                )}
                {tabIndex === 1 && (
                  <ParamGroups
                    formik={formik}
                    groupedParams={contentItem?.templateVersion.groupedParams}
                  />
                )}
              </AppTabContent>
            </LeftSection>
          </Grid>
          <Grid item xs={8} sx={{ padding: '32px !important', paddingTop: '48px !important' }}>
            <Preview aspectRatio={aspectRatio ?? undefined} payload={payload} src={previewSrc} />
          </Grid>
        </Grid>
      </DialogContent>

      <MenuSection />

      <ConfirmDialog
        {...deleteConfirmDialogProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this App will remove it from Devices where it is being used."
        title="Delete App"
      />

      <ConfirmDialog
        {...upgradeConfirmDialogProps}
        title="Upgrade App"
        prompt="Upgrading this App will upgrade it to the latest version."
        confirm="Upgrade"
      />
    </Dialog>
  );
};
