import { CheckCircleRounded, CircleRounded } from '@mui/icons-material';
import { Box, Card, Grid, styled } from '@mui/material';
import { AppCard as App, type AppCardItem } from '~/components/AppCard';
import { ContentCardActions, ContentCardThumbnail, FolderThumbnail } from '~/components/media/card';
import {
  type MediaSelect__ContentFolder as ContentFolder,
  type MediaSelect__MediaItem as MediaItem,
} from '../index.generated';

const SelectCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  '&.selected': {
    border: `5px solid ${theme.palette.divider}`,
  },
}));

const SelectedIcon = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  position: 'absolute',
  width: '40px',
  padding: theme.spacing(1),
}));

export interface SelectedIndicatorProps {
  selected: boolean;
}

const SelectedIndicator = ({ selected }: SelectedIndicatorProps) => (
  <>
    <SelectedIcon sx={{ zIndex: 1 }}>
      <CircleRounded sx={{ color: 'white', fontSize: '1.4rem' }} />
    </SelectedIcon>
    {selected && (
      <SelectedIcon sx={{ zIndex: 2 }}>
        <CheckCircleRounded color="primary" />
      </SelectedIcon>
    )}
  </>
);

export interface AppCardProps {
  app: AppCardItem & { __typename: 'ContentItem'; updatedAt: string };
  onClick: () => void;
  selected: boolean;
}

export const AppCard = ({ app, onClick, selected }: AppCardProps) => (
  <Grid item xs={12} sm={4} md={3}>
    <SelectCard className={selected ? 'selected' : ''} onClick={onClick}>
      <SelectedIndicator selected={selected} />
      <App key={app.id} app={app} content={<ContentCardActions item={app} />} />
    </SelectCard>
  </Grid>
);

export interface FolderCardProps {
  folder: ContentFolder;
  onClick: () => void;
}

export const FolderCard = ({ folder, onClick }: FolderCardProps) => (
  <Grid item xs={12} sm={4} md={3}>
    <SelectCard onClick={onClick}>
      <FolderThumbnail />
      <ContentCardActions item={folder} />
    </SelectCard>
  </Grid>
);

export const FolderCardSmall = ({ folder, onClick }: FolderCardProps) => (
  <Grid item xs={6}>
    <SelectCard onClick={onClick}>
      <FolderThumbnail />
      <ContentCardActions item={folder} small />
    </SelectCard>
  </Grid>
);

export interface MediaItemCardProps {
  mediaItem: MediaItem;
  onClick: () => void;
  selected: boolean;
}

export const MediaItemCard = ({ mediaItem, onClick, selected }: MediaItemCardProps) => (
  <Grid item xs={12} sm={4} md={3}>
    <SelectCard className={selected ? 'selected' : ''} onClick={onClick}>
      <SelectedIndicator selected={selected} />
      <ContentCardThumbnail item={mediaItem} />
      <ContentCardActions item={mediaItem} />
    </SelectCard>
  </Grid>
);

export const MediaItemCardSmall = ({
  mediaItem,
  onClick,
}: Omit<MediaItemCardProps, 'selected'>) => (
  <Grid item xs={6}>
    <SelectCard onClick={onClick}>
      <ContentCardThumbnail item={mediaItem} />
      <ContentCardActions item={mediaItem} small />
    </SelectCard>
  </Grid>
);
