import { useQuery } from '@apollo/client';
import { AddCircle, Stadium, Sync } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Tooltip } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { useSyncEvents } from '~/api/events/sync';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { Count } from '~/components/count';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { SearchContainer } from '~/components/search';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { NetworkEventTypeEventsDocument } from '../queries/events.generated';
import { Tabs } from './components';
import { BulkActionsMenu } from './components/bulk-actions.menu';
import { EventsTable } from './components/events-table';
import { NewEventDialog } from './components/new-event-dialog';

export const EventTypeEvents = () => {
  const { currentNetwork } = useAppContext();
  const link = useLink();

  const [syncing, setSyncing] = useState(false);

  const [newEventOpen, setNewEventOpen] = useState(false);

  const [confirm, confirmProps] = useConfirmDialog();

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const params = useParams<{ eventTypeId: string }>();

  const eventTypeId = Number(params.eventTypeId);

  const eventsQuery = useQuery(NetworkEventTypeEventsDocument, {
    variables: { networkId: currentNetwork.id, eventTypeId, search },
  });

  const { data, loading } = eventsQuery;

  const events = data?.network?.eventType.events ?? [];

  const [syncEvents] = useSyncEvents();

  if (!loading && !data?.network?.eventType) return <Navigate to={link('/not-found')} replace />;

  const actions = (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Tooltip
        title={
          data?.network?.eventType.canCreateEvent.value
            ? 'Create a new Event for this Event Type'
            : data?.network?.eventType.canCreateEvent.reasons?.fullMessages[0] ?? ''
        }
      >
        <span>
          <Button
            disabled={!data?.network?.eventType.canCreateEvent.value}
            color="primary"
            startIcon={<AddCircle />}
            variant="contained"
            onClick={() => setNewEventOpen(true)}
          >
            New Event
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          data?.network?.eventType.canSync.value
            ? 'Sync events from the FanConnect Sports Information Service'
            : data?.network?.eventType.canSync.reasons?.fullMessages[0] ?? ''
        }
      >
        <span>
          <Button
            disabled={!data?.network?.eventType.canSync.value}
            color="primary"
            startIcon={<Sync />}
            variant="contained"
            onClick={async () => {
              if (!(await confirm())) return;
            }}
          >
            Sync
          </Button>
        </span>
      </Tooltip>
    </Box>
  );

  return (
    <>
      {data?.network && (
        <>
          <Toolbar
            actions={actions}
            breadcrumbsLabel={<RouterBreadcrumbs />}
            titleIcon={<Stadium />}
            titleText={data.network.eventType.name}
            returnTo={link('/settings/event-types')}
          />

          <Tabs current="Events" />

          <PageContainer>
            <SearchContainer>
              <SearchBar placeholder="Search Events" search={search} />
              <Box sx={{ alignItems: 'center', display: 'flex', gap: 4 }}>
                <BulkActionsMenu eventType={data.network.eventType} selectedIds={selectedIds} />
                <Count selectedCount={0} totalCount={events.length} thing="event" />
              </Box>
            </SearchContainer>
            <EventsTable
              checkboxSelection={events.length > 1}
              disableRowSelectionOnClick={events.length < 2}
              loading={loading}
              onRowSelectionModelChange={setSelectedIds}
              eventType={data.network.eventType}
              rows={events}
              rowSelectionModel={selectedIds}
            />
          </PageContainer>
          <ConfirmDialog
            {...confirmProps}
            confirmButton={
              <LoadingButton
                loadingPosition="start"
                loading={syncing}
                color="primary"
                startIcon={<Sync />}
                variant="contained"
                onClick={async () => {
                  setSyncing(true);

                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  await syncEvents({ variables: { eventTypeId: data.network!.eventType.id } });

                  // Delay to give the que job enough time to process
                  await new Promise((resolve) => setTimeout(resolve, 3000));

                  // Refetch here after the job has had long enough to finish (we hope)
                  await eventsQuery.refetch();

                  setSyncing(false);

                  confirmProps.onConfirm();
                }}
              >
                Sync
              </LoadingButton>
            }
            prompt={
              "Syncing Events from the FanConnect Sports Information Service will create Events in the system for the associated team's games."
            }
            title={'Sync Events'}
          />
        </>
      )}

      {data?.network?.eventType && (
        <NewEventDialog
          eventType={data.network.eventType}
          close={() => setNewEventOpen(false)}
          open={newEventOpen}
        />
      )}
    </>
  );
};
