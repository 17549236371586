import { AutoFixHigh } from '@mui/icons-material';
import { Box, List } from '@mui/material';
import { useCallback, useMemo, useReducer } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ImpersonationBanner } from '~/components/ImpersonationBanner';
import { UploadDialog } from '~/components/UploadDialog';
import { UploadSnackbar } from '~/components/UploadSnackbar';
import { InfoDialog } from '~/components/dialogs/information';
import { LoadingPane } from '~/components/loading-pane';
import { AppContextProvider, UploadProvider, useFetchAppContext } from '~/contexts';
import { StudioProvider } from '../context';
import { Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText } from './styles';
import { links, getScaledTransformerPosition, reducer } from './utils';

export const StudioLayout = () => {
  const { currentNetwork, currentUser, impersonating, trueUser } = useFetchAppContext();
  const [
    {
      activeSettingsTab,
      activeSidebarItem,
      copiedItems,
      dataItemCellSelectOpen,
      dirty,
      editingId,
      history,
      historyStep,
      items,
      loaded,
      message,
      selectedIds,
      stage,
      transformer,
    },
    dispatch,
  ] = useReducer(reducer, {
    activeSettingsTab: 'Settings',
    activeSidebarItem: 'Text',
    copiedItems: [],
    dataItemCellSelectOpen: false,
    dirty: false,
    history: [[]],
    historyStep: 0,
    items: [],
    loaded: false,
    selectedIds: [],
    transformer: { dragging: false, transforming: false },
  });

  const displayLinks = useMemo(() => {
    if (selectedIds.length) return links;
    return links.filter((x) => x.name !== 'Settings');
  }, [selectedIds.length]);

  const setTransformerPosition = useCallback(() => {
    const transformerNode = transformer.node;
    if (transformerNode && stage) {
      setTimeout(() => {
        const { box } = getScaledTransformerPosition(transformerNode, stage.scale());
        dispatch({ type: 'SET_TRANSFORMER', transformer: { box } });
      });
    }
  }, [stage, transformer.node]);

  if (!currentUser || !currentNetwork || !trueUser) {
    return <LoadingPane in={true} />;
  }

  if (!currentNetwork.canAccessStudio.value)
    return <Navigate replace to={`/networks/${currentNetwork.id}/not-found`} />;

  return (
    <AppContextProvider
      currentUser={currentUser}
      currentNetwork={currentNetwork}
      impersonating={impersonating ?? false}
      trueUser={trueUser}
    >
      <UploadProvider>
        <ImpersonationBanner />
        <Box
          sx={{
            display: 'flex',
            height: impersonating ? 'calc(100% - 50px)' : '100%',
            marginTop: impersonating ? '50px' : '0px',
          }}
        >
          <nav style={{ width: '98px' }}>
            <Drawer
              variant="permanent"
              anchor="left"
              sx={{
                ...(impersonating && {
                  '.MuiDrawer-paper': { height: 'calc(100% - 50px)', top: 50, width: '98px' },
                }),
                '.MuiDrawer-paper': { width: '98px' },
              }}
            >
              <List>
                <ListItem sx={{ mb: '20px' }}>
                  <ListItemIcon sx={{ color: '#ffffff' }}>
                    <AutoFixHigh fontSize="large" />
                  </ListItemIcon>
                </ListItem>
                {displayLinks.map(({ name, Icon }) => (
                  <ListItem key={name}>
                    <ListItemButton
                      onClick={() => dispatch({ type: 'SET_SIDEBAR_ITEM', name })}
                      selected={name === activeSidebarItem}
                    >
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText sx={{ m: 0 }}>{name}</ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </nav>

          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'hidden',
            }}
          >
            <StudioProvider
              activeSettingsTab={activeSettingsTab}
              activeSidebarItem={activeSidebarItem}
              addItems={(items) => dispatch({ type: 'ADD_ITEMS', items })}
              alignItem={(id, position) => dispatch({ type: 'ALIGN_ITEM', id, position })}
              canRedo={historyStep < history.length - 1}
              canUndo={historyStep > 0}
              copiedItems={copiedItems}
              copyItems={(ids) => dispatch({ type: 'COPY_ITEMS', ids })}
              dataItemCellSelectOpen={dataItemCellSelectOpen}
              deleteItems={(ids) => dispatch({ type: 'DELETE_ITEMS', ids })}
              dirty={dirty}
              duplicateItems={(items) => {
                dispatch({
                  type: 'ADD_ITEMS',
                  items: items.map((item) => ({
                    ...item,
                    id: uuid(),
                    x: item.x + 20,
                    y: item.y + 20,
                  })),
                });
              }}
              editItem={(id) => dispatch({ type: 'EDIT_ITEM', id })}
              editingItem={items.find((x) => x.id === editingId)}
              items={items}
              loadItems={(items) => dispatch({ type: 'LOAD_ITEMS', items })}
              loaded={loaded}
              moveItem={(id, direction) => dispatch({ type: 'MOVE_ITEM', id, direction })}
              redo={() => dispatch({ type: 'REDO' })}
              resetDirty={() => dispatch({ type: 'RESET_DIRTY' })}
              selectItems={(ids) => {
                dispatch({ type: 'SELECT_ITEMS', ids });
                setTransformerPosition();
              }}
              selectedItems={items.filter((item) => selectedIds.includes(item.id))}
              setActiveSettingsTab={(tab) => dispatch({ type: 'SET_SETTINGS_TAB', tab })}
              setDataItemCellSelectOpen={(open) =>
                dispatch({ type: 'SET_DATA_ITEM_CELL_SELECT', open })
              }
              setMessage={(message) => dispatch({ type: 'SET_MESSAGE', message })}
              setSidebarItem={(name) => dispatch({ type: 'SET_SIDEBAR_ITEM', name })}
              setStage={(stage) => dispatch({ type: 'SET_STAGE', stage })}
              setTransformer={(transformer) => dispatch({ type: 'SET_TRANSFORMER', transformer })}
              stage={stage}
              transformer={transformer}
              undo={() => dispatch({ type: 'UNDO' })}
              updateItems={(items) => dispatch({ type: 'UPDATE_ITEMS', items })}
            >
              <Outlet />
              {message && (
                <InfoDialog
                  onDismiss={() => dispatch({ type: 'SET_MESSAGE', message: undefined })}
                  open
                  message={message}
                  title="Maximum Videos Reached"
                />
              )}
            </StudioProvider>
          </Box>
        </Box>
        <UploadDialog />
        <UploadSnackbar />
      </UploadProvider>
    </AppContextProvider>
  );
};
