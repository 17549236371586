import { Button, TextField } from '@mui/material';
import { Dialog, DialogForm, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { formatDate } from '~/lib/datetime';
import type { NetworkAuditLogs__AuditLog as AuditLog } from '../queries/list.generated';

export interface DetailsDialogProps {
  open: boolean;
  onClose: () => void;
  row: AuditLog | null;
}

export const DetailsDialog = ({ open, onClose, row }: DetailsDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle id="auditlog-payload-dialog-title" onClose={onClose}>
        Audit Log for {row?.recordName}
      </DialogTitle>
      <DialogContent>
        <DialogForm>
          <label>
            <span className={`label`}>Date & Time</span>
            <TextField value={formatDate(row?.createdAt || '')} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>User</span>
            <TextField value={row?.userName || ''} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Action</span>
            <TextField value={row?.actionName || ''} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Record Type</span>
            <TextField value={row?.recordType || ''} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Record</span>
            <TextField value={row?.recordName} disabled fullWidth />
          </label>
          <label>
            <span className={`label`}>Details</span>
            <pre
              style={{
                backgroundColor: '#f8f8f8',
                border: '1px solid #e6e6e6',
                borderRadius: '4px',
                color: 'rgba(0, 0, 0, 0.38)',
                fontSize: '.9em',
                width: '100%',
                padding: '8px',
              }}
            >
              {JSON.stringify(row?.payload, null, 2) || ''}
            </pre>
          </label>
        </DialogForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
