import { Box, Button, Grid, Checkbox as MuiCheckbox, styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const ClearAllContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: '19px',
  minWidth: '123px',
}));

export const ClearAllButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '12px',
}));

export const ContentContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  position: 'relative',

  width: '20%',
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 100%',
    maxWidth: '100%',
  },
  '#overlay-section, .MuiCheckbox-root': {
    opacity: selected ? 1 : 0,
  },
  '&:hover #overlay-section, &:hover .MuiCheckbox-root': {
    opacity: 1,
  },
}));

export const ButtonToggle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'view',
})<{ value: string; view: boolean }>(({ theme, value, view }) => ({
  display: 'flex',
  minWidth: '0',
  padding: 5,
  maxHeight: 30,
  color: view ? 'white' : '#BFE5FF',
  borderRadius: value === 'List' ? '4px 0px 0px 4px' : '0px 4px 4px 0px;',
  backgroundColor: view ? '#277cb7' : theme.palette.primary.main,
  '&:hover': {
    color: view ? 'white' : '#BFE5FF',
    backgroundColor: view ? '#277cb7' : theme.palette.primary.main,
  },
  '& svg': {
    fontSize: 20,
  },
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

export const FolderListThumbnail = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export const GridCheckbox = styled(MuiCheckbox)(({ theme }) => ({
  opacity: 0,
  color: theme.palette.common.white,
  position: 'absolute',
  zIndex: 1,
  right: 8,
  '&.Mui-checked': {
    color: theme.palette.common.white,
  },
}));

export const ManageDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    background: 'white',
  },
  '& .MuiDataGrid-actionsCell': {
    gridGap: 0,
  },
}));

export const ViewToggle = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: 2,
}));
