import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { MediaItemViewDocument } from './MediaItemView.generated';
import { MediaItemToolbar } from './components/MediaItemToolbar';

export const MediaItemView = () => {
  const params = useParams<{ contentFolderId: string; mediaItemId: string }>();
  const contentFolderId = parseInt(params.contentFolderId ?? '');
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();
  const link = useLink();
  const client = useApolloClient();
  const currentFileId = useRef<number>();

  const { data, loading, startPolling, stopPolling } = useQuery(MediaItemViewDocument, {
    fetchPolicy: 'cache-first',
    variables: { contentFolderId, mediaItemId, networkId: currentNetwork.id },
  });

  useEffect(() => {
    const fileId = data?.network?.mediaItem?.file?.id;
    if (!fileId || currentFileId.current === fileId) return;

    if (currentFileId.current) {
      void client.refetchQueries({ include: ['MediaItemDetails', 'MediaItemVersions'] });
    }
    currentFileId.current = fileId;
    stopPolling();
  }, [client, data, stopPolling]);

  useEffect(() => () => stopPolling(), [stopPolling]);

  const handleOnReplace = useCallback(() => {
    startPolling(1_000);
    setTimeout(stopPolling, 30_000);
  }, [startPolling, stopPolling]);

  if (!loading && !data?.network?.mediaItem) return <Navigate to={link('/not-found')} replace />;

  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {mediaItem && (
        <>
          <MediaItemToolbar mediaItem={mediaItem} onReplace={handleOnReplace} />
          <nav>
            <RouterTabs
              allowScrollButtonsMobile
              scrollButtons
              sx={{ padding: 0, paddingTop: 1 }}
              tabs={[
                { label: 'Details', to: 'details' },
                { label: 'Versions', to: 'versions' },
              ]}
              variant="scrollable"
            />
          </nav>
          <PageContainer>
            <Outlet />
          </PageContainer>
        </>
      )}
    </LoadingPane>
  );
};
