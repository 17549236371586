import { useQuery } from '@apollo/client';
import { Save, VerifiedUser } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { Navigate, useParams } from 'react-router-dom';
import { boolean, object, string } from 'yup';
import { useUpdateRole } from '~/api/roles';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { DetailSwitch, DetailTextArea, DetailTextField } from '~/components/forms/details';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import {
  SettingDescription,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { Timestamps } from '../../components/timestamps';
import { RoleTabs } from '../components';
import { RoleSettingsDocument } from './RoleSettings.generated';

const validationSchema = object({
  accessReporting: boolean().required(),
  accessScheduler: boolean().required(),
  accessStudio: boolean().required(),
  accessTemplates: boolean().required(),
  createDataSources: boolean().required(),
  createPlaylists: boolean().required(),
  createShows: boolean().required(),
  description: string().trim().label('Description'),
  name: string().required().trim().label('Name'),
});

export const RoleSettings = () => {
  const params = useParams<{ roleId: string }>();
  const roleId = parseInt(params.roleId ?? '');

  const link = useLink();

  const { currentNetwork, currentUser } = useAppContext();

  const [updateRole] = useUpdateRole();

  const { data, loading, error } = useQuery(RoleSettingsDocument, {
    variables: { networkId: currentNetwork.id, roleId },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accessReporting: data?.network?.role.accessReporting ?? false,
      accessScheduler: data?.network?.role.accessScheduler ?? false,
      accessStudio: data?.network?.role.accessStudio ?? false,
      accessTemplates: data?.network?.role.accessTemplates ?? false,
      createDataSources: data?.network?.role.createDataSources ?? false,
      createPlaylists: data?.network?.role.createPlaylists ?? false,
      createShows: data?.network?.role.createShows ?? false,
      description: data?.network?.role.description ?? '',
      name: data?.network?.role.name ?? '',
    },
    onSubmit: (values) =>
      updateRole({
        variables: { patch: validationSchema.cast(values), roleId },
      }),
    validationSchema,
  });

  if ((!loading && !data) || error) return <Navigate replace to="/not-found" />;

  const saveButton = (
    <Button
      color="primary"
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      form="update-role"
      type="submit"
      variant="contained"
      startIcon={<Save />}
    >
      Save
    </Button>
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network && (
        <>
          <Toolbar
            actions={saveButton}
            breadcrumbsLabel={<RouterBreadcrumbs />}
            titleIcon={<VerifiedUser />}
            titleText={data.network.role.name}
            returnTo={link('/settings/roles')}
          />

          <RoleTabs current="Details" />

          <PageContainer>
            <Box
              component="form"
              id="update-role"
              onSubmit={formik.handleSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <div>
                <SettingsHead>General</SettingsHead>
                <SettingsBody>
                  <SettingsData>
                    <SettingLabelWrapper>
                      <SettingName>Name</SettingName>
                      <SettingDescription>The name of the role</SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextField
                        autoFocus
                        disabled={formik.isSubmitting}
                        error={formik.touched.name && !!formik.errors.name}
                        fullWidth
                        helperText={(formik.touched.name && formik.errors.name) || ' '}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </SettingInputWrapper>
                  </SettingsData>

                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Description</SettingName>
                      <SettingDescription>A description of the role</SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailTextArea
                        error={formik.touched.description && !!formik.errors.description}
                        disabled={formik.isSubmitting}
                        fullWidth
                        helperText={
                          (formik.touched.description && formik.errors.description) || ' '
                        }
                        name="description"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              {currentUser.admin && (
                <Timestamps
                  thing="role"
                  createdAt={data.network.role.createdAt ?? ''}
                  updatedAt={data.network.role.updatedAt ?? ''}
                />
              )}

              <div>
                <SettingsHead>Data Source Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Create Data Sources</SettingName>
                      <SettingDescription>
                        Allow users in this role to create Data Sources
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.createDataSources}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="createDataSources"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Reporting Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Access Reporting</SettingName>
                      <SettingDescription>
                        Allow users in this role to generate sponsorship playback reports
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.accessReporting}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="accessReporting"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Scheduler Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Access Scheduler</SettingName>
                      <SettingDescription>
                        Allow users in this role to access the Scheduler
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.accessScheduler}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="accessScheduler"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Playlist Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Create Playlists</SettingName>
                      <SettingDescription>
                        Allow users in this role to create Playlists
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.createPlaylists}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="createPlaylists"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Show Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Create Shows</SettingName>
                      <SettingDescription>
                        Allow users in this role to create Shows
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.createShows}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="createShows"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Studio Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Access Studio</SettingName>
                      <SettingDescription>
                        Allow users in this role to access the Studio design tool
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.accessStudio}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="accessStudio"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>

              <div>
                <SettingsHead>Template Permissions</SettingsHead>
                <SettingsBody>
                  <SettingsData className="last">
                    <SettingLabelWrapper>
                      <SettingName>Access Templates</SettingName>
                      <SettingDescription>
                        Allow users in this role to access Templates
                      </SettingDescription>
                    </SettingLabelWrapper>
                    <SettingInputWrapper>
                      <DetailSwitch
                        checked={formik.values.accessTemplates}
                        color="primary"
                        disabled={formik.isSubmitting}
                        name="accessTemplates"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </SettingInputWrapper>
                  </SettingsData>
                </SettingsBody>
              </div>
            </Box>
          </PageContainer>
        </>
      )}
    </LoadingPane>
  );
};
