import { useFragment } from '@apollo/client';
import { Typography } from '@mui/material';
import { useMatch, useParams } from 'react-router-dom';
import { Link, type LinkProps } from '~/components/link';
import { useLink } from '~/hooks/link';
import { UserBreadcrumb__User } from './breadcrumbs.generated';

/* GraphQL */ `#graphql
fragment UserBreadcrumb__User on User {
  id
  name
}
`;

export const UsersBreadcrumb = (props: Omit<LinkProps, 'to'>) => {
  const link = useLink();
  const match = useMatch('/networks/:networkId/settings/users');

  return match == null ? (
    <Link color="inherit" to={link('/settings/users')} {...props}>
      Users
    </Link>
  ) : (
    <Typography>Users</Typography>
  );
};

export const UserBreadcrumb = ({ ...props }: Omit<LinkProps, 'to'>) => {
  const { userId } = useParams<{ userId: string }>();

  const { complete, data } = useFragment({
    fragment: UserBreadcrumb__User,
    fragmentName: 'UserBreadcrumb__User',
    from: { __typename: 'User', id: userId },
  });

  const link = useLink();

  const match = useMatch('/networks/:networkId/settings/users/:userId/settings');

  if (!complete) return null;

  return match == null ? (
    <Link color="inherit" to={link(`/settings/users/${userId}`)} {...props}>
      {data.name}
    </Link>
  ) : (
    <Typography>{data.name}</Typography>
  );
};
