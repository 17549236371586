import { useQuery } from '@apollo/client';
import { AddCircle, Stadium } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { array, number, object } from 'yup';
import { useUpdateEventTypeShowsDeviceGroups } from '~/api/event-types/update-shows-device-groups';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import type { ShowAutocomplete__Show as Show } from '~/components/ShowAutocomplete';
import { SaveButton } from '~/components/button';
import { Count } from '~/components/count';
import { EmptyState } from '~/components/empty-state';
import { ButtonLink } from '~/components/link';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import activateDevice from '~/images/illustrations/activate-device.svg';
import { NetworkEventTypeShowsDocument } from '../queries/shows.generated';
import { Tabs } from './components';
import { DeviceGroupTable } from './components/device-groups-table';

export const showDeviceGroupSchema = object({
  deviceGroupId: number().required(),
  showId: number().required(),
});

export const validationSchema = object({
  showsDeviceGroups: array(showDeviceGroupSchema),
});

export const EventTypeShows = () => {
  const { currentNetwork } = useAppContext();
  const link = useLink();

  const params = useParams<{ eventTypeId: string }>();

  const eventTypeId = Number(params.eventTypeId);

  const { data, loading } = useQuery(NetworkEventTypeShowsDocument, {
    variables: { networkId: currentNetwork.id, eventTypeId },
  });

  const initialValues = useMemo(
    () => ({
      showsDeviceGroups: data?.network?.deviceGroups.map((deviceGroup) => ({
        deviceGroupId: deviceGroup.id,
        showId:
          data.network?.eventType.showDeviceGroups?.find((x) => x.deviceGroup.id === deviceGroup.id)
            ?.show.id ?? -1,
      })),
    }),
    [data],
  );

  const [updateEventType] = useUpdateEventTypeShowsDeviceGroups();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const newValues = validationSchema.cast(values);

      // Get rid of entries that don't have a selected show
      const filtered = newValues.showsDeviceGroups?.filter((x) => x.showId !== -1);
      if (!filtered) return;

      // Convert to patch structure
      const patch = filtered.reduce<{
        showsDeviceGroups: Array<{
          deviceGroupId: number;
          showId: number;
        }>;
      }>(
        (acc, obj) => {
          acc.showsDeviceGroups.push({
            deviceGroupId: obj.deviceGroupId,
            showId: obj.showId,
          });
          return acc;
        },
        {
          showsDeviceGroups: [],
        },
      );

      // Do the thing
      await updateEventType({
        variables: {
          patch: patch,
          id: eventTypeId,
        },
      });
    },
  });

  const saveButton = (
    <SaveButton
      disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
      type="submit"
      form="update-mappings"
    >
      Save
    </SaveButton>
  );

  const getShow = useCallback(
    (deviceGroupId: number) => {
      const match = formik.values.showsDeviceGroups?.find((x) => x.deviceGroupId === deviceGroupId);
      return match ? match.showId : -1;
    },
    [formik],
  );

  const setShow = useCallback(
    async (deviceGroupId: number, value: Show | null) => {
      const index = formik.values.showsDeviceGroups?.findIndex(
        (x) => x.deviceGroupId === deviceGroupId,
      );
      await formik.setFieldValue(`showsDeviceGroups.${index}`, {
        deviceGroupId: deviceGroupId,
        showId: value?.id ?? -1,
      });
    },
    [formik],
  );

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <Toolbar
        actions={saveButton}
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Stadium />}
        titleText={data?.network?.eventType.name}
        returnTo={link('/settings/event-types')}
      />

      <Tabs current="Shows" />

      <PageContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Count
            selectedCount={0}
            totalCount={data?.network?.deviceGroups.length ?? 0}
            thing="device group"
          />
        </Box>
        {data?.network?.eventType ? (
          <Box component="form" id="update-mappings" onSubmit={formik.handleSubmit}>
            <DeviceGroupTable
              deviceGroups={data.network.deviceGroups}
              eventType={data.network.eventType}
              getShow={getShow}
              loading={loading}
              setShow={setShow}
            />
          </Box>
        ) : (
          <EmptyState
            description="This network currently does not have any device groups."
            header="Add Device Groups"
            illustration={activateDevice}
            primaryAction={
              <ButtonLink
                to={link('/settings/device-groups')}
                color="primary"
                startIcon={<AddCircle />}
                variant="contained"
              >
                Add Device Groups
              </ButtonLink>
            }
          />
        )}
      </PageContainer>
    </LoadingPane>
  );
};
