import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { BulkDeviceTagDocument } from './bulk.generated';

export const useBulkDeviceTag = (options: ApiOptions<typeof BulkDeviceTagDocument> = {}) =>
  useMutation(BulkDeviceTagDocument, {
    ...useNotifications(BulkDeviceTagDocument, {
      failure: 'Unable to add tag. Reload and try again or contact support.',
      success: (data) => {
        const count = data.bulkTag?.taggables.length;
        return `Successfully added tag to ${count} ${pluralize('device', count ?? 0)}`;
      },
    }),
    ...options,
  });
