import { useQuery } from '@apollo/client';
import { Delete, Image, Movie } from '@mui/icons-material';
import { Box, Button, type DialogProps } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { ContentFolderContextProvider } from '~/contexts';
import { MediaKind } from '~/generated/graphql';
import { DialogRoot, MediaGrid, MediaPreview } from './components';
import { MediaSelectDialogDocument } from './index.generated';

interface MediaSelectDialogProps extends Omit<DialogProps, 'onChange'> {
  close: () => void;
  kind: MediaKind | 'MEDIA';
  networkId: number;
  onChange: (id: number) => void;
  value: number | undefined;
}

const MediaSelectDialog = ({
  close,
  kind,
  networkId,
  onChange,
  value,
  ...props
}: MediaSelectDialogProps) => {
  const [selectedId, setSelectedId] = useState(value);

  // This extra query sucks but should be temporarry
  const { data } = useQuery(MediaSelectDialogDocument, { variables: { networkId } });

  useEffect(() => setSelectedId(value), [value]);

  const onExited = () => {
    setSelectedId(value);
  };

  if (data?.network == null) return null;

  return (
    <ContentFolderContextProvider contentFolderTree={data.network.contentFolderTree}>
      <Dialog
        PaperComponent={DialogRoot}
        TransitionProps={{ onExited }}
        aria-label="media select dialog"
        fullWidth
        maxWidth="lg"
        onClose={close}
        {...props}
      >
        <DialogTitle onClose={close}>Select {displayKind(kind)}</DialogTitle>

        <DialogContent sx={{ overflow: 'hidden' }}>
          <MediaGrid
            kind={kind}
            onClick={(id: number) => setSelectedId(id)}
            selectedIds={selectedId ? [selectedId] : []}
          />
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={selectedId === value}
            onClick={() => {
              if (selectedId === undefined) return;
              close();
              onChange(selectedId);
            }}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </ContentFolderContextProvider>
  );
};

export interface MediaSelectProps {
  disabled?: boolean;
  kind: MediaKind | 'MEDIA';
  mediaItemId: number | undefined;
  networkId: number;
  onChange: (mediaItemId: number | null) => void;
}

export const MediaSelect = ({
  disabled,
  kind,
  mediaItemId,
  networkId,
  onChange,
}: MediaSelectProps) => {
  const [value, setValue] = useState(mediaItemId);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => setValue(mediaItemId), [mediaItemId]);

  const previousValue = useRef(value);
  useEffect(() => {
    if (value === previousValue.current) return;
    previousValue.current = value;
    onChange(value ?? null);
  }, [onChange, value]);

  return (
    <Box
      sx={{
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        marginBottom: 2,
      }}
    >
      {value != null && <MediaPreview mediaItemIds={[value]} />}

      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 1 }}>
        {value != null && (
          <Button
            color="error"
            variant="contained"
            startIcon={<Delete />}
            onClick={() => setValue(undefined)}
            disabled={disabled}
          >
            Remove{'\u00A0'}
            {displayKind(kind)}
          </Button>
        )}

        <Button
          color="primary"
          variant="contained"
          startIcon={kind === 'IMAGE' ? <Image /> : <Movie />}
          onClick={() => setDialogOpen(true)}
          disabled={disabled}
        >
          Select{'\u00A0'}
          {displayKind(kind)}
        </Button>
      </Box>

      <MediaSelectDialog
        close={() => setDialogOpen(false)}
        kind={kind}
        networkId={networkId}
        onChange={setValue}
        open={dialogOpen}
        value={value}
      />
    </Box>
  );
};
function displayKind(kind: MediaKind | 'MEDIA', plural = false) {
  if (kind === 'MEDIA') return 'Media';
  const noun = kind === MediaKind.Image ? 'Image' : kind === MediaKind.Pdf ? 'PDF' : 'Video';
  return plural ? `${noun}s` : noun;
}
