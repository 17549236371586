import { CloudSync } from '@mui/icons-material';
import { Box } from '@mui/material';
import { object, string } from 'yup';
import { useUpdateDataSource } from '~/api/data-sources';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useDataSource } from '../context';
import { AlcoholButton, ExportDataSourceButton, UploadDataSourceButton } from './';

export const EditDataSourceToolbar = () => {
  const dataSource = useDataSource();
  const [update] = useUpdateDataSource();

  return (
    <Toolbar
      actions={
        <Box sx={{ display: 'flex', gap: 1 }}>
          <AlcoholButton />
          <ExportDataSourceButton />
          <UploadDataSourceButton />
        </Box>
      }
      returnTo=".."
      titleIcon={<CloudSync />}
      titleEditor={
        <InlineEditTitle
          inputId={`data-sources-${dataSource.id}`}
          value={dataSource.name}
          update={(value: string) =>
            void update({ variables: { id: dataSource.id, name: value.trim() } })
          }
          tooltip="Edit the name of this data source"
          validationSchema={object({
            name: string().required('Data source name is required').trim(),
          })}
        />
      }
    />
  );
};
