import { CropOriginal, MoreHoriz } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateMediaItem } from '~/api/media-items';
import { IconButton } from '~/components/button';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { useMoreActions } from '~/hooks/table';
import { MediaItemMenu } from './MediaItemMenu';
import { type MediaItemToolbarProps__MediaItem } from './MediaItemToolbar.generated';

/* GraphQL */ `#graphql
fragment MediaItemToolbarProps__MediaItem on MediaItem {
  contentItemCount
  file {
    id
    uri
  }
  id
  kind
  name
  originalFile {
    downloadUri
    id
    originalFilename
  }
}
`;
export interface MediaItemToolbarProps {
  mediaItem: MediaItemToolbarProps__MediaItem;
  onReplace: () => void;
}

export const MediaItemToolbar = ({ mediaItem, onReplace }: MediaItemToolbarProps) => {
  const navigate = useNavigate();
  const [updateMediaItem] = useUpdateMediaItem();

  const [moreMenuProps, moreActionProps, moreTableActions] = useMoreActions<typeof mediaItem>();

  const updateName = async (name: string) => {
    await updateMediaItem({ variables: { mediaItemId: mediaItem.id, patch: { name } } });
  };

  return (
    <>
      <Toolbar
        actions={
          <Box sx={{ display: 'flex', gap: 3 }}>
            <Tooltip arrow title="More Actions" aria-label="more-action">
              <IconButton
                onClick={moreTableActions(mediaItem).onClick}
                variant="outlined"
                size="large"
              >
                <MoreHoriz fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        }
        returnTo={() => (history.length > 1 ? navigate(-1) : navigate('..'))}
        titleIcon={<CropOriginal />}
        titleEditor={
          <InlineEditTitle
            inputId={`media-item-${mediaItem.id}-name`}
            value={mediaItem.name}
            update={updateName}
            tooltip="Edit the name of this media item"
            validationSchema={object({
              name: string().required('Media item name is required').trim(),
            })}
          />
        }
      />

      <MediaItemMenu
        item={mediaItem}
        moreActionProps={moreActionProps}
        onReplace={onReplace}
        {...moreMenuProps}
      />
    </>
  );
};
