import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { EditLayoutToolbar } from '../components';
import { LayoutProvider } from '../context';
import { LayoutGetDocument } from '../queries/get.generated';
import { LayoutDetails, LayoutZones } from './components';

export const EditLayout = () => {
  const params = useParams<{ layoutId: string }>();
  const layoutId = parseInt(params.layoutId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(LayoutGetDocument, {
    variables: { layoutId, networkId: currentNetwork.id },
  });

  const layout = useMemo(() => data?.network?.layout, [data]);

  if (!loading && !data?.network?.layout) return <Navigate to={link('/not-found')} replace />;

  if (!layout) return null;

  return (
    <LayoutProvider layout={layout}>
      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <EditLayoutToolbar />
        <PageContainer>
          <LayoutDetails />
          <LayoutZones />
        </PageContainer>
      </LoadingPane>
    </LayoutProvider>
  );
};
