import { Box, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ExpiresHeader, ThumbnailCell } from '~/components/playlists/cells';
import type {
  PlaylistDesign__Playlist as Playlist,
  PlaylistDesign__PlaylistItem as PlaylistItem,
} from '~/components/playlists/index.generated';
import { formatRuntime } from '~/lib/runtime';
import { searchRegex } from '~/lib/search';

export interface PlaylistReusableListProps {
  playlist: Playlist;
  search?: string;
}

export const PlaylistReusableList = ({ playlist, search = '' }: PlaylistReusableListProps) => {
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      thumbnail: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  const columns: GridColDef<PlaylistItem>[] = useMemo(
    () => [
      {
        field: 'thumbnail',
        renderCell: ThumbnailCell,
        sortable: false,
        maxWidth: 150,
      },
      {
        field: 'name',
        flex: 2,
        sortable: false,
        valueGetter: (_value, row) => row.contentItem.name,
      },
      {
        align: 'center',
        field: 'duration',
        flex: 1,
        headerAlign: 'center',
        renderCell: ({ row }) => {
          return formatRuntime(row.duration);
        },
        sortable: false,
        maxWidth: 100,
      },
      {
        field: 'expires',
        flex: 1,
        renderHeader: ExpiresHeader,
        sortable: false,
        valueFormatter: (value: string | null) =>
          value ? DateTime.fromSQL(value).toFormat('ccc, DD') : 'N/A',
      },
    ],
    [],
  );

  const items = useMemo(() => {
    const searchReg = searchRegex(search);
    return playlist.playlistItems.filter((item) => searchReg.test(item.contentItem.name));
  }, [playlist.playlistItems, search]);

  return (
    <ReadOnlyDataGrid
      columns={columns}
      columnVisibilityModel={showColumns}
      disableRowSelectionOnClick
      hideFooter
      pagination={false}
      rows={items}
      slots={{
        columnResizeIcon: () => null,
        noRowsOverlay: () => {
          return (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              Empty playlist
            </Box>
          );
        },
      }}
    />
  );
};
