import { useQuery } from '@apollo/client';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { ShowSettingsDocument } from './ShowSettings.generated';
import { Layout } from './components/layout';
import { SettingsForm } from './components/settings-form';

export const ShowSettings = () => {
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(ShowSettingsDocument, {
    variables: { networkId: currentNetwork.id, showId },
  });

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const showTags = data?.network?.showTags.map((tag) => tag.name) || [];

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      {data?.network?.show && (
        <Layout show={data.network.show}>
          <SettingsForm
            propertyGroups={data.propertyGroups}
            show={data.network.show}
            showTags={showTags}
          />
        </Layout>
      )}
    </LoadingPane>
  );
};
