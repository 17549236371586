import { Box, Card } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { type Dispatch, type SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardActionsTitleText } from '~/components/cards';
import { CardActions, CardActionsBottom, CardActionsTop } from '~/components/cards/styles';
import { DeviceLogo, Screenshot } from '~/components/devices';
import { DeviceStatus, activationStatus, connectionStatus } from '~/components/devices/';
import { Spacer } from '~/components/spacer';
import { ago, formatDate } from '~/lib/datetime';
import { type DeviceList__Device as Device } from '../../queries/list.generated';
import {
  DeviceContainer,
  DeviceInfo,
  GridCheckbox,
  OverlayContainer,
  ThumbnailContainer,
} from '../lib';
import { Menu } from './';

interface DeviceGridCardProps {
  device: Device;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedIds: GridRowSelectionModel;
  selected: boolean;
}

const DeviceGridCard = ({ device, onCheck, selectedIds, selected }: DeviceGridCardProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const lastConnected = device.timeSinceUpdate
    ? formatDate(ago(device.timeSinceUpdate), {
        format: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          weekday: 'short',
        },
      })
    : null;
  const { caption } = device.active ? connectionStatus(device) : activationStatus(device);

  return (
    <>
      {/* TODO: don't show for content editor, needs fix */}
      {device.canUpdate.value && (
        <GridCheckbox
          checked={selected}
          onChange={(event) => {
            if (event.target.checked) {
              return onCheck([...selectedIds, device.id]);
            }
            onCheck(selectedIds.filter((x) => x !== device.id));
          }}
        />
      )}
      <Card>
        <ThumbnailContainer
          onClick={() => navigate({ pathname: `${device.id}/details`, search: location.search })}
        >
          <Screenshot pollInterval={60_000} screenshotUri={device.screenshotUri} />
          <OverlayContainer id="overlay-section">
            <Spacer />
            <DeviceInfo>
              <DeviceLogo kind={device.kind} />
              <br />
              {device.id}
              <br />
              {device.ipAddress}
              <br />
              {device.macAddress}
              <br />
              {device.playerVersion}
            </DeviceInfo>
          </OverlayContainer>
        </ThumbnailContainer>
        <CardActions>
          <CardActionsTop>
            <Box sx={{ maxWidth: '30px', overflow: 'hidden', mr: 1 }}>
              <DeviceLogo kind={device.kind} />
            </Box>
            <CardActionsTitleText title={device.name} />
          </CardActionsTop>
          <CardActionsBottom>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <DeviceStatus device={device} />
              <div>•</div>
              <div>{lastConnected ?? caption}</div>
            </Box>
            {/* TODO: don't show for content editor, needs fix */}
            {device.canUpdate.value && <Menu device={device} />}
          </CardActionsBottom>
        </CardActions>
      </Card>
    </>
  );
};

interface GridRowProps {
  item: Device;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedIds: GridRowSelectionModel;
}

export const GridRow = ({ item, onCheck, selectedIds }: GridRowProps) => {
  const selected = selectedIds.includes(item.id);

  return (
    <DeviceContainer item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id} selected={selected}>
      <DeviceGridCard
        device={item}
        onCheck={onCheck}
        selectedIds={selectedIds}
        selected={selected}
      />
    </DeviceContainer>
  );
};
