import { useLazyQuery } from '@apollo/client';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { type useFormik } from 'formik';
import { MuiColorInput, matchIsValidColor } from 'mui-color-input';
import { useEffect, type ComponentProps } from 'react';
import { useDropzone } from 'react-dropzone';
import { boolean, number, object, ref, string } from 'yup';
import {
  DetailSelect,
  DetailSwitch,
  DetailTextArea,
  DetailTextField,
} from '~/components/forms/details';
import { DurationSlider } from '~/components/inputs';
import { DurationInput } from '~/components/inputs/DurationInput/DurationInput';
import {
  SettingDescription,
  SettingFieldRequired,
  SettingInputWrapper,
  SettingLabelWrapper,
  SettingName,
  SettingsBody,
  SettingsData,
  SettingsHead,
} from '~/components/settings';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { toDataUri } from '~/lib/file';
import { duration } from '~/lib/validators';
import { type NetworkEventTypesLeague } from '../queries/leagues.generated';
import { NetworkTeamsDocument } from '../queries/teams.generated';

export interface EventTypeFormValues {
  color: string;
  contentStartTime: string;
  contentStopTime: string;
  name: string;
  description: string | undefined;
  fcLeagueId: number;
  fcTeamId: number;
  iconInputUri: string;
  iconUploadUri: string;
  manageDisplay: boolean;
  sponsorStartTime: string;
  sponsorStopTime: string;
}

export const eventTypeSchema = object({
  color: string()
    .required()
    .test((value) => matchIsValidColor(value))
    .label('Color'),
  contentStartTime: duration({ min: { minutes: 0 } }).required(),
  contentStopTime: duration({ min: { minutes: 0 } }).required(),
  description: string().optional(),
  fcLeagueId: number().optional().label('FanConnect League'),
  fcTeamId: number()
    .label('FanConnect Team')
    .test({
      name: 'required-if-league-selected',
      exclusive: true,
      message: 'FanConnect Team is required when FanConnect League is selected',
      test: function (value) {
        const fcLeagueId = this.resolve(ref('fcLeagueId'));
        return !(fcLeagueId !== -1 && value === -1);
      },
    }),
  iconUploadUri: string().trim(),
  iconInputUri: string().trim(),
  manageDisplay: boolean().required(),
  name: string().required().label('Name'),
  sponsorStartTime: duration({ min: { minutes: 0 }, max: { hours: 5 } }).required(),
  sponsorStopTime: duration({ min: { minutes: 0 }, max: { hours: 5 } }).required(),
});

export const serializeEventType = (values: EventTypeFormValues) => {
  const { fcLeagueId, fcTeamId, iconInputUri, iconUploadUri, ...rest } =
    eventTypeSchema.cast(values);

  assert(
    !iconInputUri || !iconUploadUri,
    'one or both of iconInputUri and iconUploadUri should be empty',
  );

  return {
    ...rest,
    fcLeagueId: fcLeagueId === -1 || fcLeagueId == null ? null : fcLeagueId,
    fcTeamId: fcTeamId === -1 || fcTeamId == null ? null : fcTeamId,
    iconUri: iconInputUri || iconUploadUri || undefined,
  };
};

export interface EventTypeFormProps<T extends EventTypeFormValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<T>>;
  formId: string;
  iconFileUri?: string | undefined;
  leagues: readonly NetworkEventTypesLeague[];
  newRecord?: boolean;
}

export const EventTypeForm = <T extends EventTypeFormValues>({
  formik,
  formId,
  iconFileUri,
  leagues,
  newRecord = false,
}: EventTypeFormProps<T>) => {
  const { currentNetwork } = useAppContext();

  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const iconUploadUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('iconUploadUri', iconUploadUri, false);
      await formik.setFieldValue('iconInputUri', '', true);
    },
  });

  const imgSrc =
    formik.values.iconInputUri.trim() || formik.values.iconUploadUri.trim() || iconFileUri;

  const [teamsQuery, { data }] = useLazyQuery(NetworkTeamsDocument);

  useEffect(() => {
    void teamsQuery({
      variables: { networkId: currentNetwork.id, leagueId: String(formik.values.fcLeagueId) },
    });
  }, [currentNetwork.id, formik.values.fcLeagueId, teamsQuery]);

  const teams = data?.network?.teams;

  const handleLeagueChange = async (value: number) => {
    await formik.setFieldValue('fcLeagueId', value, true);

    // Get list of teams for selected league
    await teamsQuery({
      variables: {
        networkId: currentNetwork.id,
        leagueId: String(value),
      },
    });

    // Reset team
    await formik.setFieldValue('fcTeamId', -1, true);
  };

  const handleTeamChange = async (value: number) => {
    await formik.setFieldValue('fcTeamId', value, true);

    const selectedTeam = teams?.find((team) => team.id === value);

    if (!selectedTeam || !selectedTeam.logoUrl) return;

    await formik.setFieldValue('iconUploadUri', selectedTeam.logoUrl);

    await formik.setFieldValue('color', `#${selectedTeam.primaryColor}`);
  };

  return (
    <Box
      component="form"
      id={formId}
      onSubmit={formik.handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <div>
        <SettingsHead>General</SettingsHead>
        <SettingsBody>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>
                Name<SettingFieldRequired>*</SettingFieldRequired>
              </SettingName>
              <SettingDescription>Name of the event type</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextField
                autoFocus
                disabled={formik.isSubmitting}
                error={formik.touched.name && !!formik.errors.name}
                fullWidth
                helperText={(formik.touched.name && formik.errors.name) || ' '}
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Description</SettingName>
              <SettingDescription>Description of the event type</SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailTextArea
                disabled={formik.isSubmitting}
                error={formik.touched.description && !!formik.errors.description}
                fullWidth
                helperText={(formik.touched.description && formik.errors.description) || ' '}
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>
                Color<SettingFieldRequired>*</SettingFieldRequired>
              </SettingName>
              <SettingDescription>
                Color of the event type which will be used to color code events on the calendar
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <MuiColorInput
                error={formik.touched.color && !!formik.errors.color}
                format="hex"
                helperText={(formik.touched.color && formik.errors.color) || ' '}
                name="color"
                onBlur={formik.handleBlur}
                onChange={(color) => formik.setFieldValue('color', color, true)}
                value={formik.values.color}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>
                Icon<SettingFieldRequired>*</SettingFieldRequired>
              </SettingName>
              <SettingDescription>
                Icon of the event type which will appear on the calendar
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <Box display="flex" flexDirection="row" gap={2}>
                {imgSrc && (
                  <Box component="img" display="flex" src={imgSrc} sx={{ height: '4rem' }} />
                )}
                <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
                  <input {...getInputProps()} />
                  <div>
                    <Button onClick={selectFile} variant="outlined">
                      Upload Icon
                    </Button>
                  </div>
                  <Box display="flex" flexDirection="column">
                    <span>Or, enter an icon URL:</span>
                    <TextField
                      error={
                        (formik.touched.iconInputUri || formik.touched.iconUploadUri) &&
                        !!formik.errors.iconInputUri
                      }
                      fullWidth
                      helperText={
                        ((formik.touched.iconInputUri || formik.touched.iconUploadUri) &&
                          formik.errors.iconInputUri) ||
                        ' '
                      }
                      name="iconInputUri"
                      onBlur={formik.handleBlur}
                      onChange={(event) => {
                        if (event.target.value.trim())
                          void formik.setFieldValue('iconUploadUri', '', false);
                        formik.handleChange(event);
                      }}
                      value={formik.values.iconInputUri}
                    />
                  </Box>
                </Box>
              </Box>
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>League</SettingName>
              <SettingDescription>
                Select the league associated with this event type
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailSelect
                disabled={!newRecord}
                name="fcLeagueId"
                onBlur={formik.handleBlur}
                onChange={(event) => handleLeagueChange(parseInt(String(event.target.value)))}
                value={formik.values.fcLeagueId}
              >
                <MenuItem value={-1}>-- Select League --</MenuItem>
                {leagues.map((league, index) => (
                  <MenuItem key={index} value={league.id}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '25px',
                          width: '30px',
                        }}
                      >
                        {league.logoUrl && <img src={league.logoUrl} style={{ width: '25px' }} />}
                      </Box>
                      <Box>{league.name}</Box>
                    </Box>
                  </MenuItem>
                ))}
              </DetailSelect>
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>Team</SettingName>
              <SettingDescription>
                Select the team associated with this event type, when selected home games for this
                team will automatically be created
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailSelect
                name="fcTeamId"
                disabled={!newRecord || formik.values.fcLeagueId === -1}
                onBlur={formik.handleBlur}
                onChange={(event) => handleTeamChange(parseInt(String(event.target.value)))}
                value={formik.values.fcTeamId}
              >
                <MenuItem value={-1}>-- Select Team --</MenuItem>
                {teams?.map((team, index) => (
                  <MenuItem key={index} value={team.id}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: '25px',
                          width: '30px',
                        }}
                      >
                        {team.logoUrl && <img src={team.logoUrl} style={{ width: '25px' }} />}
                      </Box>
                      <Box>
                        {team.location} {team.name}
                      </Box>
                    </Box>
                  </MenuItem>
                ))}
              </DetailSelect>
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>

      <div>
        <SettingsHead>Content Times</SettingsHead>
        <SettingsBody>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Start Time</SettingName>
              <SettingDescription>
                Length of time to begin playing content before events start
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DurationInput
                disabled={formik.isSubmitting}
                name="contentStartTime"
                value={formik.values.contentStartTime}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Stop Time</SettingName>
              <SettingDescription>
                Length of time to stop playing content after events end
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DurationInput
                disabled={formik.isSubmitting}
                name="contentStopTime"
                value={formik.values.contentStopTime}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>Manage Display Power</SettingName>
              <SettingDescription>
                Turn display on at start time and turn it off at stop time, if supported by device
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DetailSwitch
                checked={formik.values.manageDisplay}
                color="primary"
                disabled={formik.isSubmitting}
                name="manageDisplay"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>

      <div>
        <SettingsHead>Sponsor Times</SettingsHead>
        <SettingsBody>
          <SettingsData>
            <SettingLabelWrapper>
              <SettingName>Start Time</SettingName>
              <SettingDescription>
                Minutes before events start to count as sponsor time for playout reporting
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DurationSlider
                disabled={formik.isSubmitting}
                max={{ hours: 5 }}
                min={{ minutes: 0 }}
                name="sponsorStartTime"
                required
                step={{ minutes: 15 }}
                value={formik.values.sponsorStartTime}
                onChange={formik.handleChange}
              />
            </SettingInputWrapper>
          </SettingsData>

          <SettingsData className="last">
            <SettingLabelWrapper>
              <SettingName>Stop Time</SettingName>
              <SettingDescription>
                Minutes after events end to count as sponsor time for playout reporting
              </SettingDescription>
            </SettingLabelWrapper>
            <SettingInputWrapper>
              <DurationSlider
                disabled={formik.isSubmitting}
                max={{ hours: 5 }}
                min={{ minutes: 0 }}
                name="sponsorStopTime"
                required
                step={{ minutes: 15 }}
                value={formik.values.sponsorStopTime}
                onChange={formik.handleChange}
              />
            </SettingInputWrapper>
          </SettingsData>
        </SettingsBody>
      </div>
    </Box>
  );
};
