import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { useAppContext } from '~/contexts';
import { MediaItemVersionsDocument } from './MediaItemVersions.generated';
import { MediaItemOriginal } from './components/MediaItemOriginal';
import { MediaItemRenditions } from './components/MediaItemRenditions';
import { MediaItemThumbnails } from './components/MediaItemThumbnails';

export const MediaItemVersions = () => {
  const params = useParams<{ contentFolderId: string; mediaItemId: string }>();
  const contentFolderId = parseInt(params.contentFolderId ?? '');
  const mediaItemId = parseInt(params.mediaItemId ?? '');
  const { currentNetwork } = useAppContext();

  const { data, loading, error } = useQuery(MediaItemVersionsDocument, {
    fetchPolicy: 'cache-first',
    variables: { contentFolderId, mediaItemId, networkId: currentNetwork.id },
  });

  if ((!loading && !data) || error) return <Navigate to="/not-found" />;

  const mediaItem = data?.network?.mediaItem;

  return (
    <LoadingPane in={loading && !data}>
      {mediaItem && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={9} xl={8}>
            <Grid container direction="column" spacing={2}>
              <Grid item sx={{ '&.MuiGrid-item': { maxWidth: '100%', overflow: 'hidden' } }}>
                <MediaItemOriginal mediaItem={mediaItem} />
              </Grid>
              <MediaItemRenditions mediaItem={mediaItem} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} xl={4}>
            <Grid container direction="column" flexWrap="nowrap" spacing={2}>
              <Grid item>
                <MediaItemThumbnails mediaItem={mediaItem} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </LoadingPane>
  );
};
