import { useQuery } from '@apollo/client';
import { AppRegistration } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import { Outlet, useParams } from 'react-router-dom';
import { number, object, string } from 'yup';
import { useUpdateTemplate } from '~/api/templates';
import { RouterTabs } from '~/components/RouterTabs';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import { type TemplatePatch } from '~/generated/graphql';
import { useMoreActions } from '~/hooks/table';
import { TemplateProvider } from '../../context/template';
import {
  TemplateShowDocument,
  type TemplateShow__Template as Template,
} from '../../queries/show.generated';
import { Actions, Menu } from './index';

const validationSchema = object({
  name: string().default('').trim(),
  description: string().default('').trim(),
  templateCategoryId: number().integer().positive(),
  thumbnailUri: string().nullable(),
});

export const TemplateLayout = () => {
  const params = useParams<{ templateId: string }>();
  const templateId = parseInt(params.templateId ?? '');

  const [moreMenuProps, moreActionProps, moreTableActions] = useMoreActions<Template>();

  const [updateTemplate] = useUpdateTemplate();

  const { data, loading } = useQuery(TemplateShowDocument, { variables: { templateId } });

  const template = data?.template;

  const update = async (patch: TemplatePatch) => {
    return updateTemplate({ variables: { templateId: templateId, patch } });
  };

  const initialValues = {
    name: data?.template?.name ?? '',
    description: data?.template?.description ?? '',
    thumbnailUri: data?.template?.thumbnailUri,
    templateCategoryId: data?.template?.templateCategory.id,
  };

  const onSubmit = async (values: TemplatePatch) => {
    const patch: { -readonly [K in keyof TemplatePatch]: TemplatePatch[K] } = {};
    if (values.name !== initialValues.name) patch.name = values.name;
    if (values.description !== initialValues.description) patch.description = values.description;
    if (values.templateCategoryId !== initialValues.templateCategoryId)
      patch.templateCategoryId = values.templateCategoryId;
    if (values.thumbnailUri !== initialValues.thumbnailUri)
      patch.thumbnailUri = values.thumbnailUri;

    return update(patch);
  };

  const formik = useFormik<TemplatePatch>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
  });

  if (template == null) return null;

  const onShowMenu = moreTableActions(template).onClick;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <>
        <Toolbar
          returnTo=".."
          titleIcon={<AppRegistration />}
          actions={<Actions template={template} onShowMenu={onShowMenu} formik={formik} />}
          titleEditor={
            <InlineEditTitle
              inputId={`templates-app-item-${template.id}-name`}
              value={template.name}
              update={(value) => void update({ name: value.trim() })}
              tooltip="Edit the name of this Template"
              validationSchema={object({
                name: string().required('Template name is required').trim(),
              })}
            />
          }
        ></Toolbar>

        <Menu template={template} moreMenuProps={moreMenuProps} moreActionProps={moreActionProps} />
        <nav>
          <RouterTabs
            tabs={[
              { label: 'Settings', to: 'settings' },
              { label: 'Configuration', to: 'configuration' },
              { label: 'Versions', to: 'versions' },
            ]}
            variant="scrollable"
          />
        </nav>

        <PageContainer>
          <TemplateProvider
            formik={formik}
            template={template}
            templateCategories={data?.templateCategories || []}
          >
            <Outlet />
          </TemplateProvider>
          <Box sx={{ paddingBottom: 10 }} />
        </PageContainer>
      </>
    </LoadingPane>
  );
};
