import { useQuery } from '@apollo/client';
import { AddCircle, FilterList, MovieCreation, Sell } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { CreateShowWizard, useCreateShowWizard } from '../components/create-show-wizard';
import { ShowActions } from '../lib';
import { ShowListDocument, type ShowList__Show as Show } from '../queries/queries.generated';
import { BulkActions, Filter, Table, TagsDialog } from './components/';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { useFilterParams } from './lib/';

export const ShowList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [showManageTags, setManageTags] = useState(false);
  const showWizard = useCreateShowWizard();
  const [selectedShow, setSelectedShow] = useState<Show>();

  const { tagsParams: tags } = useFilterParams();
  const [showFilters, setShowFilters] = useState(tags.length > 0);

  const { data, loading } = useQuery(ShowListDocument, {
    variables: { currentNetworkId: currentNetwork.id, page, perPage, search, tags },
  });

  const totalCount = useTotalCount(data?.network?.shows.pageInfo.nodesCount);
  const shows = data?.network?.shows.nodes ?? [];

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [tags]);

  const canDestroySelectedShows = selectedIds.every((selectedId) => {
    const show = shows.find((show) => show.id === selectedId);
    return show?.canDestroy.value;
  });

  const CreateShowButton = (
    <Button
      color="primary"
      disabled={!currentNetwork.canCreateShows.value}
      onClick={showWizard.open}
      startIcon={<AddCircle />}
      variant="contained"
    >
      New Show
    </Button>
  );

  return (
    <>
      <Helmet title="Shows" />
      <Toolbar
        titleIcon={<MovieCreation />}
        titleText="Shows"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTags(!showManageTags)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            {currentNetwork.canManage.value && CreateShowButton}
          </Box>
        }
      ></Toolbar>

      <PageContainer>
        <>
          <ShowActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar
                onChange={(value) =>
                  setSearchParams((params) => {
                    params.set('search', value);
                    params.set('page', '1');
                    return params;
                  })
                }
                placeholder="Search Shows"
                search={search}
              />
              <Hidden smDown>
                <Button
                  onClick={() => setShowFilters(() => !showFilters)}
                  startIcon={<FilterList />}
                  variant="outlined"
                >
                  Filter
                </Button>
              </Hidden>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIds.length > 0 ? (
                <BulkActions
                  selectedIds={selectedIds}
                  onTagContent={() => setTagContentDialogOpen(true)}
                  canDestroySelectedShows={canDestroySelectedShows}
                />
              ) : null}
              <Count selectedCount={selectedIds.length} totalCount={totalCount} thing="show" />
            </Box>
          </ShowActions>
          {showFilters && (
            <Hidden smDown>
              <Filter />
            </Hidden>
          )}
          <Table
            loading={loading}
            shows={data?.network?.shows.nodes ?? []}
            selectedShow={selectedShow}
            onCheck={setSelectedIds}
            onSelectedShow={setSelectedShow}
            selectedIds={selectedIds}
            totalCount={totalCount}
          />
        </>
        {showManageTags && <TagsDialog onClose={() => setManageTags(false)} open />}

        {tagContentDialogOpen && (
          <BulkTagDialog
            showIds={selectedIds as string[]}
            showTags={data?.network?.showTags.map((x) => x.name) || []}
            onClose={() => setTagContentDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}
      </PageContainer>

      <CreateShowWizard {...showWizard.props} />
    </>
  );
};
