import { AddCircle } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { DeleteButton, SaveButton } from '~/components/button';
import { AddPropertyGroupDialog } from '~/components/dialogs/add-property-group';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { FormLayout } from '~/components/settings';
import { FormHeading } from '~/pages/devices/show/lib';
import { AlertMessage } from '../../components/alert-message';
import type { ShowSettings__Show as Show } from '../ShowSettings.generated';
import { useSettingsForm } from '../lib/settings';
import { ActionBar } from '../lib/styles';
import type { ShowDetail__PropertyGroup as PropertyGroup } from '../queries.generated';

export type ShowSettingsProps = {
  propertyGroups: ReadonlyArray<PropertyGroup>;
  show: Show;
  showTags?: string[];
};

export const SettingsForm = (showSettings: ShowSettingsProps) => {
  const { show } = showSettings;

  const {
    addPropertyGroup,
    confirmDialogProps,
    deletePropertyGroup,
    fanconnectSettings,
    filteredPropertyGroups,
    formik,
    generalSettings,
    propertyGroupSettings,
    setShowAddPropertyDialog,
    showAddPropertyDialog,
  } = useSettingsForm(showSettings);

  return (
    <>
      <AlertMessage show={show} />
      <form onSubmit={formik.handleSubmit}>
        <ActionBar>
          <Button
            disabled={!filteredPropertyGroups.length}
            onClick={() => setShowAddPropertyDialog(true)}
            startIcon={<AddCircle />}
            variant="contained"
          >
            Add Property Group
          </Button>
          <SaveButton
            disabled={
              !show.canUpdate.value || !formik.isValid || formik.isSubmitting || !formik.dirty
            }
            type="submit"
          >
            Save
          </SaveButton>
        </ActionBar>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
          <FormLayout formHeading="General" formData={generalSettings} />

          {formik.values.fanconnect && (
            <FormLayout
              formHeading={
                <FormHeading>
                  <Box>FanConnect</Box>
                  <DeleteButton
                    onClick={() => formik.setFieldValue('fanconnect', null, true)}
                    size="small"
                    sx={{ fontSize: '0.7rem' }}
                  >
                    Remove
                  </DeleteButton>
                </FormHeading>
              }
              formData={fanconnectSettings}
            />
          )}

          {propertyGroupSettings.map((settings) => (
            <FormLayout
              formHeading={
                <FormHeading>
                  <Box>{settings.name}</Box>
                  <DeleteButton
                    onClick={() =>
                      deletePropertyGroup({ propertyGroupId: settings.propertyGroupId })
                    }
                    size="small"
                    sx={{ fontSize: '0.7rem' }}
                  >
                    Remove
                  </DeleteButton>
                </FormHeading>
              }
              formData={settings.fields}
              key={settings.id}
            />
          ))}
        </Box>
      </form>
      <ConfirmDialog
        {...confirmDialogProps}
        title="Delete Property Group"
        confirm="Permanently Delete"
        prompt="Deleting this property group will remove it from this show."
        deleteConfirm
      />
      <AddPropertyGroupDialog
        description="Adding a new group to this show will add all settings that belong to that group."
        onSubmit={addPropertyGroup}
        open={showAddPropertyDialog}
        onClose={() => {
          setShowAddPropertyDialog(false);
        }}
        propertyGroups={filteredPropertyGroups}
      />
    </>
  );
};
