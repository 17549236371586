import type { InputBaseProps, MenuProps } from '@mui/material';
import {
  Button,
  Collapse,
  InputBase,
  Menu as MenuBase,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';
import { forwardRef } from 'react';
import { ButtonLink, IconButtonLink } from '~/components/link';

const Menu = styled(MenuBase)({
  '& .MuiPaper-root': {
    borderRadius: 0,
    border: '1px solid #e8e8e8',
    boxShadow: 'none',
  },
});

export const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  alignContent: 'center',
  alignItems: 'center',
  border: '0',
  borderRadius: '0',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '22px',
  fontWeight: 'normal',
  height: '100%',
  justifyContent: 'space-between',
  margin: '0',
  padding: '0 24px',
  textTransform: 'none',
  width: '100%',

  '&, &:hover': {
    backgroundColor: 'white',
  },

  '&.Mui-disabled .MuiListItemIcon-root': {
    color: 'rgb(0, 0, 0, .26) !important',
  },

  '&:nth-of-type(2)': {
    border: '1px solid rgb(63, 63, 63, .15)',
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
}));

export const MenuButtonText = styled('div')({
  textAlign: 'left',
  flexGrow: 1,
  paddingLeft: 5,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ContextMenu = (props: MenuProps) => (
  <Menu
    TransitionComponent={Collapse}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    elevation={0}
    anchorEl={null}
    marginThreshold={0}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    {...props}
  />
);

export const FilterMenuItem = styled(MenuItem)({
  alignContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid rgb(63, 63, 63, .15)',
  display: 'flex',
  gap: 10,
  justifyContent: 'space-between',
  width: '100%',

  '&, &.MuiButtonBase-root, &:hover, &.MuiListItem-root.Mui-focusVisible': {
    backgroundColor: 'white',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    padding: '2px 8px',
    transition: 'none !important',
  },
});

export const FilterTextField = styled(
  forwardRef<HTMLInputElement, InputBaseProps>(function FilterTextField(props, ref) {
    return <InputBase type="text" {...props} ref={ref} />;
  }),
)({ flexGrow: 1 });

export const ContextMenuList = styled(MenuList)({ maxHeight: 500 });

const menuItemStyles = {
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid rgb(63, 63, 63, .15)',
  maxHeight: 500,

  '&:last-child': {
    borderBottom: '0',
  },

  '& .MuiIconButton-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

export const ContextMenuItem = styled(MenuItem)(menuItemStyles);

export const ContextMenuItemAction = styled(IconButtonLink)({
  '& .MuiListItemIcon-root': {
    justifyContent: 'end',
  },
});

export const ContextButtonLink = styled(ButtonLink)({
  alignContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'flex-start',
  margin: 0,
  padding: 0,
  textTransform: 'none',
  width: '100%',

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiListItemText-root': {
    textAlign: 'left',
  },
});
