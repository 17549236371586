import { useQuery } from '@apollo/client';
import previewSrc from '@fanconnect/neo-client/dist/show.html?url';
import { Box, Tooltip, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeleteShow } from '~/api/shows/delete';
import { RouterTabs } from '~/components/RouterTabs';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { DetailKey } from '~/components/info-pane';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { Preview } from '~/components/preview';
import { ViewSelect } from '~/components/shows/view-select';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useLink } from '~/hooks/link';
import { usePreviewControl } from '~/hooks/preview';
import { showPreviewProps } from '~/lib/preview';
import { ShowToolbar } from '../detail/components';
import { ShowProvider } from '../detail/context';
import { ShowPreviewDocument, type ShowPreview__Show as Show } from '../queries/preview.generated';
import { AlertMessage } from './alert-message';

export const ShowPreview = ({ show }: { show: Show }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [deleteShow] = useDeleteShow();
  const [confirm, confirmProps] = useConfirmDialog();

  const viewId = Number(searchParams.get('viewId'));
  const setViewId = useCallback(
    (viewId: number | undefined) => navigate(`?viewId=${viewId}`),
    [navigate],
  );
  useEffect(() => {
    if (viewId) return;
    navigate(`?viewId=${show.defaultViewId}`, { replace: true });
  }, [navigate, show.defaultViewId, viewId]);

  const previewProps = useMemo(() => showPreviewProps(show, viewId), [show, viewId]);

  const previewControl = usePreviewControl('ShowPreview');

  const handleDelete = useCallback(async () => {
    if (!(await confirm())) return;
    await deleteShow({ variables: { showIds: [show.id] } });
    navigate('../..');
  }, [confirm, deleteShow, navigate, show.id]);

  const views = show.views;

  return (
    <>
      <ShowToolbar
        actions={
          <Tooltip title={show.canDestroy.reasons?.fullMessages.join(', ')}>
            <span>
              <DeleteButton disabled={!show.canDestroy.value} onClick={handleDelete} />
            </span>
          </Tooltip>
        }
        returnTo={`../..?viewId=${viewId}`}
        show={show}
      />
      <nav>
        <RouterTabs
          tabs={[
            { label: 'Design', to: '../design' },
            { label: 'Preview', to: '../preview' },
            { label: 'Settings', to: '../settings' },
          ]}
          variant="scrollable"
        />
      </nav>
      <PageContainer>
        <AlertMessage show={show} />
        <Box>
          {views.length > 1 && (
            <Box>
              <DetailKey id="show-view">
                <Typography variant="overline">VIEW</Typography>
              </DetailKey>
              <ViewSelect
                sx={{ mb: 2 }}
                show={show}
                fullWidth
                onChange={setViewId}
                value={viewId}
              />
            </Box>
          )}
          <Preview
            displayControlBar
            previewControl={previewControl}
            src={previewSrc}
            {...previewProps}
          />
        </Box>
      </PageContainer>

      <ConfirmDialog
        {...confirmProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Show will remove it from Devices where it is being used."
        title="Delete Show"
      />
    </>
  );
};

export const ShowPreviewPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ showId: string }>();
  const showId = parseInt(params.showId ?? '');

  const { data, loading } = useQuery(ShowPreviewDocument, {
    fetchPolicy: 'cache-first',
    variables: { networkId: currentNetwork.id, showId },
  });

  const link = useLink();

  if (!loading && !data?.network?.show) return <Navigate to={link('/not-found')} replace />;

  const show = data?.network?.show;

  return (
    <LoadingPane in={loading && !data}>
      {show && (
        <ShowProvider show={show as never} propertyGroups={[]}>
          <ShowPreview show={show} />
        </ShowProvider>
      )}
    </LoadingPane>
  );
};
