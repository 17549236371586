import { useMediaQuery, useTheme } from '@mui/material';
import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { memo, useCallback, useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DataGridPagination } from '~/components/data-grid/pagination';
import { EmptyState } from '~/components/empty-state';
import { ListCheckbox } from '~/components/list-row';
import { useAppContext } from '~/contexts';
import { useOrderByParams } from '~/hooks/order-by';
import { usePaginationParams } from '~/hooks/pagination';
import ActivateDeviceIllustration from '~/images/illustrations/activate-device.svg';
import type { DeviceList__Device as Device } from '../../queries/list.generated';
import { useColumns } from '../lib';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={ActivateDeviceIllustration}
    header="Activate a Device"
    description="No Devices for this Network"
  />
);

export interface TableProps {
  devices: readonly Device[];
  loading: boolean;
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  onSendCommand: (device: Device[]) => void;
  selectedIds: GridRowSelectionModel;
  totalCount: number;
}

const slots = {
  baseCheckbox: ListCheckbox,
  pagination: DataGridPagination,
  noRowsOverlay: NoRowsOverlay,
};

export const Table = memo(
  ({ devices, loading, onCheck, onSendCommand, selectedIds, totalCount }: TableProps) => {
    const columns = useColumns({ onSendCommand });
    const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser } = useAppContext();

    const theme = useTheme();
    const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
    const isLargeAndDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isXLargeAndDown = useMediaQuery(theme.breakpoints.down('xl'));

    const { paginationModel, setPageParams } = usePaginationParams({ perPage: 50 });
    const { setOrderByParams, sortModel } = useOrderByParams();

    useEffect(() => {
      setShowColumns((x) => ({
        ...x,
        arch: !isSmallAndDown && currentUser.admin,
        channels: !isSmallAndDown,
        group: !isLargeAndDown,
        kind: !isLargeAndDown && currentUser.admin,
        ipAddress: !isLargeAndDown,
        playerVersion: !isLargeAndDown && currentUser.admin,
        internal: !isXLargeAndDown && currentUser.admin,
        show: !isSmallAndDown,
      }));
    }, [currentUser, isLargeAndDown, isMediumAndDown, isSmallAndDown, isXLargeAndDown]);

    const onPaginationModelChange = useCallback(
      ({ page, pageSize }: GridPaginationModel) => {
        setPageParams({ page: page + 1, perPage: pageSize });
      },
      [setPageParams],
    );

    const onRowClick = useCallback(
      ({ row }: GridRowParams<Device>) => {
        navigate({ pathname: `${row.id}/details`, search: location.search });
      },
      [location.search, navigate],
    );

    const onSortModelChange = useCallback(
      (sortModel: GridSortModel) => {
        setOrderByParams(sortModel.at(0) ?? null);
      },
      [setOrderByParams],
    );

    return (
      <SeparatedDataGrid
        checkboxSelection={!isSmallAndDown}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableRowSelectionOnClick
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onPaginationModelChange={onPaginationModelChange}
        onRowSelectionModelChange={onCheck}
        onRowClick={onRowClick}
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={totalCount}
        rows={devices}
        rowSelectionModel={selectedIds}
        slots={slots}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
      />
    );
  },
);

Table.displayName = 'Table';
