import { useUpdateMediaItem } from '~/api/media-items';
import { DetailSwitch } from '~/components/forms/details';
import { Link } from '~/components/link';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { TagList } from '~/components/tag';
import { MediaKind } from '~/generated/graphql';
import { formatDate, formatInterval } from '~/lib/datetime';
import { mediaDimensions, mediaExtension, mediaSize } from '~/lib/media';
import { capitalize } from '~/lib/string';
import { formatBytes } from '~/lib/units';
import type {
  MediaItemInfoProps__ContentFolder,
  MediaItemInfoProps__MediaItem,
} from './MediaItemInfo.generated';

/* GraphQL */ `#graphql
fragment MediaItemInfoProps__MediaItem on MediaItem {
  createdAt
  defaultDuration {
    ...Interval
  }
  id
  kind
  originalMetadata
  overlay {
    id
  }
  tags
  useOriginal
}

fragment MediaItemInfoProps__ContentFolder on ContentFolder {
  id
  name
}
`;

export interface MediaItemInfoProps {
  contentFolder: MediaItemInfoProps__ContentFolder;
  mediaItem: MediaItemInfoProps__MediaItem;
}

export const MediaItemInfo = ({ contentFolder, mediaItem }: MediaItemInfoProps) => {
  const [updateMediaItem] = useUpdateMediaItem();

  return (
    <>
      <SettingsHead>Details</SettingsHead>
      <SettingsBody>
        <SettingsData className="last scroll no-space-between">
          <MetaItem>
            <ListRowHeadingValue heading="Type" value={capitalize(mediaItem.kind)} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Format" value={mediaExtension(mediaItem)} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Size" value={formatBytes(mediaSize(mediaItem))} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Dimensions"
              value={mediaDimensions(mediaItem).join('x')}
            />
          </MetaItem>
          {mediaItem.kind === MediaKind.Video && (
            <MetaItem>
              <ListRowHeadingValue
                heading="Duration"
                value={formatInterval(mediaItem.defaultDuration)}
              />
            </MetaItem>
          )}
          <MetaItem>
            <ListRowHeadingValue
              heading="Folder"
              value={
                // There's no reason not to link to the root folder here
                // And this check is bad
                contentFolder.name === 'No Folder' ? (
                  'n/a'
                ) : (
                  <Link to="../..">{contentFolder.name}</Link>
                )
              }
            />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue heading="Created At" value={formatDate(mediaItem.createdAt)} />
          </MetaItem>
          <MetaItem>
            <ListRowHeadingValue
              heading="Use Original?"
              value={
                <DetailSwitch
                  defaultChecked={mediaItem.useOriginal}
                  disabled={mediaItem.kind === 'PDF'}
                  onChange={(event) =>
                    void updateMediaItem({
                      variables: {
                        mediaItemId: mediaItem.id,
                        patch: { useOriginal: event.target.checked },
                      },
                    })
                  }
                />
              }
            />
          </MetaItem>
          <MetaItem className={mediaItem.overlay ? '' : 'last'}>
            <ListRowHeadingValue
              heading="Tags"
              value={
                mediaItem.tags.length ? <TagList item={mediaItem} tags={mediaItem.tags} /> : '--'
              }
            />
          </MetaItem>
          {mediaItem.overlay && (
            <MetaItem className="last">
              <ListRowHeadingValue heading="Overlay ID" value={mediaItem.overlay.id} />
            </MetaItem>
          )}
        </SettingsData>
      </SettingsBody>
    </>
  );
};
