import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useMemo, type Dispatch, type SetStateAction } from 'react';
import { ContentContainer, GridCheckbox } from '../lib';
import { ContentCard, type ContentCardProps } from './ContentCard';
import { ContentFolderCard, type ContentFolderCardProps } from './ContentFolderCard';

interface ContentGridRowProps {
  item: ContentFolderCardProps['contentFolder'] | ContentCardProps['item'];
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedIds: GridRowSelectionModel;
}

export const ContentGridRow = ({ item, onCheck, selectedIds }: ContentGridRowProps) => {
  const selectionId = useMemo(() => `${item.__typename}-${item.id}`, [item]);
  const selected = selectedIds.includes(selectionId);

  return (
    <ContentContainer item xs={12} sm={6} md={4} lg={3} xl={2} key={item.id} selected={selected}>
      {item.__typename === 'ContentFolder' ? (
        <ContentFolderCard contentFolder={item} />
      ) : (
        <>
          <GridCheckbox
            checked={selected}
            onChange={(event) => {
              if (event.target.checked) {
                return onCheck([...selectedIds, selectionId]);
              }
              onCheck(selectedIds.filter((x) => x !== `${item.__typename}-${item.id}`));
            }}
          />
          <ContentCard item={item} selected={selected} />
        </>
      )}
    </ContentContainer>
  );
};
