import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useExportDataSource } from '~/api/data-sources';
import { ExportDownloadDialog } from './';
import { useDataSource } from '../context';

export const ExportDataSourceButton = () => {
  const dataSource = useDataSource();
  const [exportDataSource, { loading }] = useExportDataSource();
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  return (
    <>
      <LoadingButton
        color="primary"
        loading={loading}
        onClick={async () => {
          const result = await exportDataSource({ variables: { input: { id: dataSource.id } } });
          setDownloadUrl(() => result.data?.exportDataSource?.url ?? null);
        }}
        startIcon={<Download />}
        variant="contained"
      >
        Export
      </LoadingButton>
      <ExportDownloadDialog close={() => setDownloadUrl(() => null)} downloadUrl={downloadUrl} />
    </>
  );
};
