import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { User } from '~/generated/graphql';

interface UserAvatarProps {
  className?: string;
  user: Pick<User, 'name'>;
}

const userColor = (user: Pick<User, 'name'>) => {
  const hue =
    user.name.split('').reduce((hash, char) => char.charCodeAt(0) + (hash << 5) - hash, 0) % 360;

  return `hsl(${hue}, 55%, 45%)`;
};

const useStyles = makeStyles((_theme) => ({
  root: {
    height: 24,
    width: 24,
    border: '1px solid white',
  },
  colorDefault: ({ user }: UserAvatarProps) => ({
    backgroundColor: userColor(user),
  }),
}));

export const UserAvatar = ({ className, user }: UserAvatarProps) => {
  const classes = useStyles({ user });

  return (
    <Avatar className={className} classes={classes} alt={user.name}>
      {user.name.trim()[0].toUpperCase()}
    </Avatar>
  );
};
