import { Box } from '@mui/material';
import { FormLayout } from '~/components/settings';
import { useSettingsForm } from '../lib/settings';

export const SettingsForm = () => {
  const { generalSettings } = useSettingsForm();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
      <FormLayout formHeading="General" formData={generalSettings} />
    </Box>
  );
};
