import {
  GridCellModes,
  type GridCellModesModel,
  type GridEventListener,
  type GridRowId,
  type MuiEvent,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState, type FocusEvent } from 'react';

export interface SelectedCellParams {
  id: GridRowId;
  field: string;
}

export const cancelExistingCells = (
  model: GridCellModesModel | undefined = {},
): GridCellModesModel => {
  const cancelExistingCells: GridCellModesModel = Object.keys(model).reduce(
    (newModel, id) => ({
      ...newModel,
      [id]: Object.keys(model[id]).reduce(
        (newRow, field) => ({
          ...newRow,
          [field]: {
            mode: GridCellModes.View,
            ignoreModifications: true,
          },
        }),
        {},
      ),
    }),
    {},
  );

  return cancelExistingCells;
};

export const useDataGridEditing = () => {
  const [selectedCellParams, setSelectedCellParams] = useState<SelectedCellParams>();
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>();

  const handleCellFocus = useCallback((event: FocusEvent<HTMLDivElement>) => {
    const { currentTarget } = event;
    const row = currentTarget.parentElement;
    if (!row) return;

    const id = row.dataset.id;
    const field = currentTarget.dataset.field;
    if (!id || !field) return;

    setSelectedCellParams({ id, field });
  }, []);

  const cellMode = useMemo(() => {
    if (!selectedCellParams) return GridCellModes.View;

    const { id, field } = selectedCellParams;

    return cellModesModel?.[id]?.[field]?.mode || GridCellModes.View;
  }, [cellModesModel, selectedCellParams]);

  const handleCellKeyDown = useCallback<GridEventListener<'cellKeyDown'>>(
    (_, event) => {
      if (cellMode === 'edit') {
        // prevent calling event.preventDefault() if tab is pressed on a cell in edit mode
        event.defaultMuiPrevented = true;
        if (!['Enter', 'Escape'].includes(event.key) || !selectedCellParams) return;

        const { id, field } = selectedCellParams;
        const model = cellModesModel || {};

        setCellModesModel({
          ...model,
          [id]: {
            ...model[id],
            [field]: {
              mode: GridCellModes.View,
              ignoreModifications: event.key === 'Escape',
            },
          },
        });
      }
    },
    [cellMode, cellModesModel, selectedCellParams],
  );

  const handleCellEditStart = useCallback(
    (
      cell: { id: GridRowId; field: string },
      event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>>,
    ) => {
      event.defaultMuiPrevented = true;

      const existingCells = cancelExistingCells(cellModesModel);

      setCellModesModel({
        ...existingCells,
        [cell.id]: {
          ...existingCells[cell.id],
          [cell.field]: {
            mode: GridCellModes.Edit,
          },
        },
      });
    },
    [cellModesModel],
  );

  const handleCellEditStop = useCallback<GridEventListener<'cellEditStop'>>((_, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  return {
    cellMode,
    cellModesModel,
    handleCellEditStart,
    handleCellEditStop,
    handleCellFocus,
    handleCellKeyDown,
    selectedCellParams,
    setCellModesModel,
    setSelectedCellParams,
  };
};
