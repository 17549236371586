import {
  Box,
  Checkbox,
  ListItemText as MUIListItemText,
  Paper as MUIPaper,
  Tooltip,
  Typography,
  styled,
  type ListItemTextProps,
  type SxProps,
  type Theme,
} from '@mui/material';
import type { ReactNode } from 'react';

const Paper = styled(MUIPaper)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginBottom: '10px',
  color: theme.palette.text.secondary,
}));

const ListRowBox = styled(Box)((props) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `2px solid ${props.border ? '#008cc8' : 'transparent'}`,
  borderRadius: `${props.border ? '5' : '0'}px`,
  padding: props.theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
  transition: 'all 0.1s ease-out 0s',
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px 0px',
  },
}));

export interface ListRowProps {
  children: ReactNode;
  border?: boolean;
  paperStyleProps?: SxProps<Theme> | undefined;
  boxStyleProps?: SxProps<Theme> | undefined;
}

export const ListRow = ({
  children,
  paperStyleProps,
  boxStyleProps,
  border = false,
}: ListRowProps) => {
  return (
    <Paper elevation={1} sx={paperStyleProps}>
      <ListRowBox border={border} sx={boxStyleProps}>
        {children}
      </ListRowBox>
    </Paper>
  );
};

// List Row Content Component

const ListRowContentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  width: '75%',
});

export interface ListRowContentProps {
  children: ReactNode;
  onClick?: () => void;
}

export const ListRowContent = ({ children, onClick }: ListRowContentProps) => {
  return <ListRowContentBox onClick={onClick}>{children}</ListRowContentBox>;
};

// List Row Actions Component

const ListRowActionsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
  '& a, button': {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

export interface ListRowActionsProps {
  children: ReactNode;
}

export const ListRowActions = ({ children }: ListRowActionsProps) => {
  return <ListRowActionsBox>{children}</ListRowActionsBox>;
};

// List Row Value Component

const ListRowValueBox = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  color: theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    width: '40vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export interface ListRowValueProps {
  value: ReactNode;
  valueTooltip?: ReactNode;
}

export const ListRowValue = ({ value, valueTooltip }: ListRowValueProps) => {
  return (
    <Tooltip arrow title={valueTooltip} placement="left">
      <ListRowValueBox sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {value}
      </ListRowValueBox>
    </Tooltip>
  );
};

// List Row Heading / Value Component

const ListRowHeadingValueBox = styled(Box)({});

export interface ListRowHeadingValueProps {
  heading: ReactNode;
  value: ReactNode;
  valueTooltip?: ReactNode;
}

export const ListRowHeadingValue = ({ heading, value, valueTooltip }: ListRowHeadingValueProps) => {
  return (
    <ListRowHeadingValueBox>
      <Typography
        sx={{ fontSize: '0.7rem', fontWeight: 600, color: '#969696', textTransform: 'uppercase' }}
      >
        {heading}
      </Typography>
      <Tooltip arrow title={valueTooltip} placement="left">
        <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{value}</Box>
      </Tooltip>
    </ListRowHeadingValueBox>
  );
};

// Scrollable List Component

export const ScrollableList = styled('div')(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2, 2, 14),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(4, 4, 14),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(4, 6, 14),
  },
}));

// List Item Text Component which includes text overflow w/ellipsis

const ListItemName = styled(MUIListItemText)(() => ({
  '& span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const ListItemText = ({ children, ...props }: ListItemTextProps) => {
  return <ListItemName {...props}>{children}</ListItemName>;
};

export const ListCheckbox = styled(Checkbox)(({ theme }) => ({
  '& svg': {
    fontSize: '23px',
    color: theme.palette.secondary.dark,
  },
}));
