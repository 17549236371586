import { useQuery } from '@apollo/client';
import { FilterList, Router, Sell, ViewHeadline, ViewModule } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SendCommandDialog, useSendCommandDialog } from '~/components/devices/send-command-dialog';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import type { DevicesOrderBy } from '~/generated/graphql';
import { useOrderByParams } from '~/hooks/order-by';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import {
  PlayOverlayDialog,
  TuneChannelDialog,
  UpdateSoftwareDialog,
  VolumeDialog,
} from '../components';
import { DeviceListDocument } from '../queries/list.generated';
import { BulkActions, BulkDeviceGroupDialog, Filter, Grid, Table, TagsDialog } from './components';
import { BulkScheduleDialog } from './components/bulk-schedule-dialog';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { ActivateButton, ButtonToggle, DeviceActions, ViewToggle, useFilterParams } from './lib';
import { generateScheduledShowName } from './lib/helpers';

export const DeviceList = () => {
  const [openCommandDialog, commandDialogProps] = useSendCommandDialog();
  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [tuneChannelDialogOpen, setTuneChannelDialogOpen] = useState(false);
  const [playOverlayDialogOpen, setPlayOverlayDialogOpen] = useState(false);
  const [updateSoftwareDialogOpen, setUpdateSoftwareDialogOpen] = useState(false);
  const [volumeDialogOpen, setVolumeDialogOpen] = useState(false);
  const [showManageTags, setManageTags] = useState(false);
  const [showBulkSchedule, setBulkSchedule] = useState(false);
  const [showAddToGroup, setShowAddToGroup] = useState(false);

  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const deviceView = searchParams.get('view')?.trim() || 'List';

  const {
    archsParams: arch,
    demoParams: demo,
    displayParams: displayOn,
    groupsParams: groups,
    internalParams: internal,
    kindsParams: kind,
    statusesParams: status,
    tagsParams: tags,
  } = useFilterParams();

  const [showFilters, setShowFilters] = useState(
    arch.length +
      demo.length +
      displayOn.length +
      groups.length +
      internal.length +
      kind.length +
      status.length +
      tags.length >
      0,
  );

  const { orderBy } = useOrderByParams<DevicesOrderBy>();
  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(DeviceListDocument, {
    variables: {
      condition: {
        arch,
        demo,
        displayOn,
        groups,
        internal,
        kind,
        status,
        tags,
      },
      networkId: currentNetwork.id,
      ...(orderBy && { orderBy }),
      page,
      perPage,
      search,
    },
  });

  const totalCount = useTotalCount(data?.network?.devices.pageInfo.nodesCount);

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  // reset selections when applying filters
  useEffect(() => {
    setSelectedIds([]);
  }, [arch, demo, displayOn, kind, internal, groups, status, tags]);

  const handleAddToGroup = useCallback(() => {
    setShowAddToGroup(() => true);
  }, []);

  const handleSendCommand = useCallback(() => {
    const selectedDevices =
      data?.network?.devices.nodes.filter((x) => selectedIds.includes(x.id)) || [];
    openCommandDialog(selectedDevices);
  }, [data, openCommandDialog, selectedIds]);

  const handleScheduleShow = useCallback(() => {
    setBulkSchedule(true);
  }, []);

  return (
    <>
      <Helmet title="Devices" />
      {/* TODO: don't show for content editor, needs fix */}
      <Toolbar
        titleIcon={<Router />}
        titleText="Devices"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTags(!showManageTags)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            {currentNetwork.canManage.value && <ActivateButton />}
          </Box>
        }
      />

      <PageContainer>
        <>
          <DeviceActions>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchBar
                onChange={(value) =>
                  setSearchParams((params) => {
                    params.set('search', value);
                    params.set('page', '1');
                    return params;
                  })
                }
                placeholder="Search Devices"
                search={search}
              />
              <Hidden smDown>
                <Button
                  onClick={() => setShowFilters(() => !showFilters)}
                  startIcon={<FilterList />}
                  variant="outlined"
                >
                  Filter
                </Button>
              </Hidden>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIds.length > 0 ? (
                <BulkActions
                  onAddToGroup={handleAddToGroup}
                  onPlayOverlay={() => setPlayOverlayDialogOpen(true)}
                  onSendCommand={handleSendCommand}
                  onScheduleShow={handleScheduleShow}
                  onTagContent={() => setTagContentDialogOpen(true)}
                  onTuneChannel={() => setTuneChannelDialogOpen(true)}
                  onUpdateSoftware={() => setUpdateSoftwareDialogOpen(true)}
                  onVolume={() => setVolumeDialogOpen(true)}
                />
              ) : null}
              <Count selectedCount={selectedIds.length} totalCount={totalCount} thing="device" />
            </Box>
          </DeviceActions>
          {showFilters && (
            <Hidden smDown>
              <Filter />
            </Hidden>
          )}
          <Box sx={{ position: 'relative' }}>
            <ViewToggle>
              <ButtonToggle
                value="List"
                deviceView={deviceView === 'List'}
                onClick={() =>
                  setSearchParams((prev) => {
                    return { ...Object.fromEntries(prev), view: 'List' };
                  })
                }
              >
                <ViewHeadline />
              </ButtonToggle>
              <ButtonToggle
                value="Grid"
                deviceView={deviceView === 'Grid'}
                onClick={() =>
                  setSearchParams((prev) => ({ ...Object.fromEntries(prev), view: 'Grid' }))
                }
              >
                <ViewModule />
              </ButtonToggle>
            </ViewToggle>
          </Box>
          {/* TODO: don't show activate button for content editor, needs fix */}
          {deviceView === 'List' ? (
            <Table
              devices={data?.network?.devices.nodes ?? []}
              loading={loading}
              onCheck={setSelectedIds}
              onSendCommand={openCommandDialog}
              selectedIds={selectedIds}
              totalCount={totalCount}
            />
          ) : (
            <Grid
              devices={data?.network?.devices.nodes ?? []}
              onCheck={setSelectedIds}
              onSendCommand={openCommandDialog}
              selectedIds={selectedIds}
              totalCount={totalCount}
            />
          )}
        </>
        <BulkDeviceGroupDialog
          deviceGroups={data?.network?.deviceGroups || []}
          deviceIds={selectedIds as number[]}
          onClose={() => setShowAddToGroup(() => false)}
          onComplete={() => setSelectedIds(() => [])}
          open={showAddToGroup}
        />
        <BulkScheduleDialog
          name={generateScheduledShowName(
            data?.network?.deviceGroups.map((item) => ({
              id: item.id,
              name: item.name,
            })) ?? [],
            demo,
            displayOn,
            groups,
            internal,
            kind,
            search,
            status,
            tags,
          )}
          onClose={() => {
            setBulkSchedule(false);
          }}
          onSubmit={() => {
            setBulkSchedule(false);
            setSelectedIds([]);
          }}
          open={showBulkSchedule}
          selectedDevices={
            data?.network?.devices.nodes
              .filter((x) => selectedIds.includes(x.id))
              .map((x) => x.id) || []
          }
        />
        <SendCommandDialog {...commandDialogProps} onComplete={() => setSelectedIds([])} />
        {showManageTags && <TagsDialog onClose={() => setManageTags(false)} open />}

        {tagContentDialogOpen && (
          <BulkTagDialog
            deviceIds={selectedIds as string[]}
            deviceTags={data?.network?.deviceTags.map((x) => x.name) || []}
            onClose={() => setTagContentDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}

        {tuneChannelDialogOpen && (
          <TuneChannelDialog
            deviceIds={selectedIds.map(Number)}
            onClose={() => setTuneChannelDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}

        {playOverlayDialogOpen && (
          <PlayOverlayDialog
            deviceIds={selectedIds.map(Number)}
            onClose={() => setPlayOverlayDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}

        {updateSoftwareDialogOpen && (
          <UpdateSoftwareDialog
            deviceIds={selectedIds.map(Number)}
            onClose={() => setUpdateSoftwareDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
          />
        )}

        {volumeDialogOpen && (
          <VolumeDialog
            deviceIds={selectedIds.map(Number)}
            onClose={() => setVolumeDialogOpen(false)}
            onComplete={() => setSelectedIds(() => [])}
            open
          />
        )}
      </PageContainer>
    </>
  );
};
