import { Box, Button, styled } from '@mui/material';
import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

export const PlaylistActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const ClearAllContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: '19px',
  minWidth: '123px',
}));

export const ClearAllButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '12px',
}));

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));

export const ManageDataGrid = styled(DataGrid)(() => ({
  border: 0,
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    background: 'white',
  },
  '& .MuiDataGrid-actionsCell': {
    gridGap: 0,
  },
}));
