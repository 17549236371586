import { ArrowBackIos } from '@mui/icons-material';
import { Button, Icon, styled, Tooltip, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { type To } from 'react-router-dom';
import { formatTimeAgo } from '~/lib/datetime';
import { InlineEditTextField } from './forms/inline-edit-text-field';
import { ButtonLink } from './link';

const BackButton = styled(ButtonLink)(({ theme }) => ({
  color: theme.palette.common.black,
}));

export const InlineEditTitle = withStyles({
  root: {
    width: '70%',
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  input: {
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
})(InlineEditTextField);

const useStyles = makeStyles((theme) => ({
  backContainer: {
    background: '#ffffff',
    height: '63px',
    padding: '0px',
    justifyContent: 'flex-start',
  },
  backLinkContainer: {
    marginRight: theme.spacing(2),
    borderRight: '1px solid rgb(63, 63, 63, .15)',
    '& > a, & > button': {
      padding: '0px !important',
      width: '45px',
      minWidth: '45px !important',
      maxWidth: '45px',
      height: '64px',
      borderRadius: '0px',
      '&:hover': {
        background: '#eeeeee',
        '-webkit-box-shadow': 'none',
        '-moz-box-shadow': 'none',
        boxShadow: 'none',
      },
      '& span': {
        marginLeft: '4px !important',
        marginRight: '0px !important',
      },
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: '1px solid rgb(63, 63, 63, .15)',
    display: 'flex',
    position: 'sticky',
    top: '64px',
    width: '100%',
    zIndex: '999',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  toolbarContainerDetail: {
    paddingLeft: theme.spacing(0),
    '& $titleContainer': {},
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    order: 1,
    flexGrow: 1,
    flexShrink: 0,
    '& > h1': {
      marginLeft: theme.spacing(1),
    },
  },
  titleIcon: {
    fontSize: '35px',
    '& > span': {
      fontSize: '35px',
    },
    '& svg': {
      fontSize: '35px',
    },
  },
  titleName: {
    width: '100%',
    marginLeft: '10px',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    background: '#ffffff',
    order: 3,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      bottom: '0',
      left: '0',
      width: '100%',
      justifyContent: 'center',
      borderTop: '1px solid #cccccc',
      padding: theme.spacing(1, 2),
      marginLeft: theme.spacing(0),
      zIndex: '1000',
      '& .MuiButton-root': {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  centerContainer: {
    background: '#ffffff',
    order: 2,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  modifiedAt: {
    color: '#808080',
    fontSize: '.8rem',
  },
  customTitle: {
    fontSize: '19px',
  },
  customLabel: {},
}));

export interface ToolbarProps {
  actions?: ReactNode;
  children?: ReactNode;
  createdAt?: string;
  returnTo?: To | (() => void);
  titleIcon?: ReactNode;
  titleText?: ReactNode;
  titleEditor?: ReactNode;
  titleLabel?: string;
  titleTextCustom?: string;
  updatedAt?: string;
  breadcrumbsLabel?: ReactNode;
}

const ToolbarWrapper = styled('div')({
  width: '100%',
});

export const Toolbar = ({
  actions,
  children,
  createdAt,
  returnTo,
  titleIcon,
  titleText,
  titleEditor,
  titleLabel,
  breadcrumbsLabel,
  titleTextCustom,
  updatedAt,
}: ToolbarProps) => {
  const classes = useStyles();

  return (
    <ToolbarWrapper>
      <div
        className={clsx([classes.toolbar, { [classes.toolbarContainerDetail]: returnTo != null }])}
      >
        <div className={classes.backContainer}>
          {returnTo !== undefined && (
            <div className={classes.backLinkContainer}>
              <Tooltip arrow title="Go Back" aria-label="go-back">
                {typeof returnTo === 'function' ? (
                  <Button
                    disableRipple
                    startIcon={<ArrowBackIos />}
                    centerRipple
                    onClick={returnTo}
                    sx={{ color: 'common.black' }}
                  />
                ) : (
                  <BackButton
                    disableRipple
                    startIcon={<ArrowBackIos />}
                    to={returnTo}
                    centerRipple
                  />
                )}
              </Tooltip>
            </div>
          )}
        </div>

        <div className={classes.titleContainer}>
          {titleIcon && (
            <div className={classes.titleIcon}>
              <Icon color="primary">{titleIcon}</Icon>
            </div>
          )}

          <div className={classes.titleName}>
            {titleEditor && <div>{titleEditor}</div>}

            {titleText && (
              <Typography variant="h3" noWrap>
                {titleText}
              </Typography>
            )}

            {titleTextCustom && (
              <Typography variant="h3" noWrap className={classes.customTitle}>
                {titleTextCustom}
              </Typography>
            )}

            {titleLabel && <p className={classes.customLabel}>{titleLabel}</p>}
            {breadcrumbsLabel && <div className={classes.customLabel}>{breadcrumbsLabel}</div>}

            {(updatedAt || createdAt) && (
              <Typography className={classes.modifiedAt}>
                {updatedAt ? 'Updated' : 'Created'} {formatTimeAgo(updatedAt ?? createdAt!)}
              </Typography>
            )}
          </div>
        </div>

        <div className={classes.centerContainer}>{children}</div>

        {actions !== undefined && <div className={classes.actionContainer}>{actions}</div>}
      </div>
    </ToolbarWrapper>
  );
};
