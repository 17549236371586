import { ExpandMore, Info } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, styled } from '@mui/material';
import { type FormikProps } from 'formik';
import { type FormValues } from './form-helpers';
import { type ContentItemEditor__GroupedTemplateParams as GroupedParams } from './gql/editor.generated';
import { OptionField } from './option-field';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),

  '& .MuiAccordion-root': {
    padding: 0,
  },

  '& .MuiAccordionSummary-content': {
    fontSize: '1.1rem',
    fontWeight: 600,
  },

  '& .MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 0, 0, 4),

    '& > *': {
      width: '100%',
    },
  },
}));

interface ParamGroupsProps {
  formik: FormikProps<FormValues>;
  groupedParams: readonly GroupedParams[] | null | undefined;
}

export const ParamGroups = ({ formik, groupedParams }: ParamGroupsProps) => {
  return (
    <Root>
      {groupedParams?.map(({ paramGroup, params }, groupIndex) => {
        const details = (
          <AccordionDetails key={groupIndex}>
            {params.map((param) => (
              <OptionField formik={formik} templateParamId={param.id} key={param.id} />
            ))}
          </AccordionDetails>
        );

        if (paramGroup == null) return details;

        return (
          <Accordion disableGutters key={groupIndex}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              {paramGroup.label}
              {paramGroup.description && (
                <Tooltip title={paramGroup.description}>
                  <Info color="primary" />
                </Tooltip>
              )}
            </AccordionSummary>
            {details}
          </Accordion>
        );
      })}
    </Root>
  );
};
