import { Grid } from '@mui/material';
import { Link } from '~/components/link';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { mediaSize } from '~/lib/media';
import { formatBytes } from '~/lib/units';
import type { MediaItemRenditionsProps__MediaItem } from './MediaItemRenditions.generated';

/* GraphQL */ `#graphql
fragment MediaItemRenditionsProps__MediaItem on MediaItem {
  id
  kind
  originalMetadata
  renditions {
    filename
    height
    id
    size
    status
    uri
    width
  }
}
`;

export interface MediaItemRenditionsProps {
  mediaItem: MediaItemRenditionsProps__MediaItem;
}

export const MediaItemRenditions = ({ mediaItem }: MediaItemRenditionsProps) => {
  return (
    <>
      {mediaItem.renditions
        .filter((rendition) => rendition.size !== mediaSize(mediaItem))
        .map((item) => (
          <Grid
            item
            key={item.id}
            sx={{ '&.MuiGrid-item': { maxWidth: '100%', overflow: 'hidden' } }}
          >
            <SettingsHead>{`${item.width} x ${item.height}`}</SettingsHead>
            <SettingsBody>
              <SettingsData className="last scroll no-space-between">
                <MetaItem>
                  <ListRowHeadingValue
                    heading="File"
                    value={
                      <Link to={item.uri} target="_blank">
                        {item.filename}
                      </Link>
                    }
                  />
                </MetaItem>
                <MetaItem>
                  <ListRowHeadingValue heading="Size" value={formatBytes(item.size ?? 0)} />
                </MetaItem>
                <MetaItem>
                  <ListRowHeadingValue
                    heading="Dimensions"
                    value={`${item.width} x ${item.height}`}
                  />
                </MetaItem>
                <MetaItem className="last">
                  <ListRowHeadingValue heading="Status" value={item.status.toLowerCase()} />
                </MetaItem>
              </SettingsData>
            </SettingsBody>
          </Grid>
        ))}
    </>
  );
};
