import { MovieCreation } from '@mui/icons-material';
import { type ReactNode } from 'react';
import { type To } from 'react-router-dom';
import { object, string } from 'yup';
import { useRenameShow } from '~/api/shows';
import { InlineEditTitle, Toolbar } from '~/components/toolbar';
import type { ShowToolbar__Show } from './toolbar.generated';

/* GraphQL */ `#graphql
fragment ShowToolbar__Show on Show {
  id
  name
}
`;

export interface ShowToolbarProps {
  actions?: ReactNode;
  returnTo: To;
  show: ShowToolbar__Show;
}

export const ShowToolbar = ({ actions, returnTo, show }: ShowToolbarProps) => {
  const [rename] = useRenameShow();

  return (
    <Toolbar
      actions={actions}
      returnTo={returnTo}
      titleIcon={<MovieCreation />}
      titleEditor={
        <InlineEditTitle
          inputId={`shows-item-${show.id}-name`}
          value={show.name}
          update={(value) => void rename({ variables: { showId: show.id, name: value.trim() } })}
          tooltip="Edit the name of this show"
          validationSchema={object({
            name: string().required('Show name is required').trim(),
          })}
        />
      }
    />
  );
};
