import { Grid as MuiGrid } from '@mui/material';
import { type GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { memo, type Dispatch, type SetStateAction } from 'react';
import { EmptyState } from '~/components/empty-state';
import UploadMediaIllustration from '~/images/illustrations/upload-media.svg';
import type { ContentCardProps } from './ContentCard';
import type { ContentFolderCardProps } from './ContentFolderCard';
import { ContentGridRow } from './ContentGridRow';

export interface ContentGridProps {
  items: readonly ContentCardProps['item'][];
  folders: readonly ContentFolderCardProps['contentFolder'][];
  onCheck: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedIds: GridRowSelectionModel;
}
export const ContentGrid = memo(({ items, folders, onCheck, selectedIds }: ContentGridProps) => {
  const isEmpty = folders.length === 0 && items.length === 0;

  return (
    <MuiGrid container spacing={4} sx={{ marginTop: 2 }}>
      {isEmpty ? (
        <EmptyState
          illustration={UploadMediaIllustration}
          header="Upload your content"
          description="This is where you store images and videos to use for your devices."
        />
      ) : (
        <>
          {folders.map((folder) => (
            <ContentGridRow
              key={`ContentFolder-${folder.id}`}
              item={folder}
              onCheck={onCheck}
              selectedIds={selectedIds}
            />
          ))}
          {items.map((item) => (
            <ContentGridRow
              key={`${item.__typename}-${item.id}`}
              item={item}
              onCheck={onCheck}
              selectedIds={selectedIds}
            />
          ))}
        </>
      )}
    </MuiGrid>
  );
});

ContentGrid.displayName = 'ContentGrid';
