import { Typography } from '@mui/material';
import { toPng } from 'html-to-image';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { UpdateContentItemDocument, useUpdateContentItem } from '~/api/content-items';
import { useNotifications } from '~/api/helpers';
import { AppForm, type AppFormSubmitProps } from '~/components/app-form';
import { assert } from '~/lib/assert';

export const EditApp = () => {
  const [updateApp] = useUpdateContentItem();
  const [updateThumbnail] = useUpdateContentItem(
    useNotifications(UpdateContentItemDocument, {
      failure: 'Failed to update thumbnail',
      success: 'Updated thumbnail',
    }),
  );
  const { enqueueSnackbar } = useSnackbar();
  const { appId } = useParams<{ appId: string }>();

  const handleCapture = useCallback(async () => {
    const previewAppElement = document
      .querySelector<HTMLIFrameElement>('iframe#preview')
      ?.contentWindow?.document.body.querySelector<HTMLIFrameElement>('iframe')?.contentWindow
      ?.document.body;

    if (!previewAppElement) throw Error('Cannot find preview element');

    try {
      const thumbnailUri = await toPng(previewAppElement, {
        fetchRequestInit: { cache: 'no-cache' },
        pixelRatio: 1,
        style: { transform: 'none' },
      });

      void updateThumbnail({
        variables: { contentItemId: Number(appId), patch: { thumbnailUri } },
      });
    } catch (e) {
      enqueueSnackbar(<Typography color="inherit">Thumbnail failed</Typography>, {
        key: uuid(),
        variant: 'error',
      });
    }
  }, [appId, enqueueSnackbar, updateThumbnail]);

  const handleSubmit = useCallback(
    async ({ patch }: AppFormSubmitProps) => {
      assert(appId != null);
      await updateApp({ variables: { contentItemId: Number(appId), patch } });
    },
    [appId, updateApp],
  );

  return <AppForm id={Number(appId)} onCapture={handleCapture} onSubmit={handleSubmit} />;
};
